import {fetchAxios} from "../axios"

export const createSms = async ({data}) => {
  try {
    const res = await fetchAxios({
      url: `/notifications/sms`,
      method: "POST",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const createPush = async ({data}) => {
  try {
    const res = await fetchAxios({
      url: `/notifications/push`,
      method: "POST",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}
