import {ThreeDots} from "react-loader-spinner"

const Index = () => (
  <ThreeDots
    height="80"
    width="80"
    radius="9"
    color="#E5001C"
    ariaLabel="three-dots-loading"
    visible={true}
    wrapperStyle={{width: "100%", height: "100%", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", zIndex: "999", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(000, 000, 000, 0.3)"}}
  />
)

export default Index