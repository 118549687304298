import {makeAutoObservable} from "mobx"
import {
  createCoupon,
  deleteCouponById,
  deleteCouponTemplateById,
  getAllCouponsList,
  getCouponByType,
  getCouponTemplateById,
  getTemplatesList,
  getWheel,
  updateCouponTemplateById,
  updateWheel
} from "./api"

export class CouponStore {
  data = []
  templatesData = []
  isLoading = false
  showCouponModal = true

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }

  toggleModal = () => {
    this.showCouponModal = !this.showCouponModal
  }

  async getAllCoupons({search, type}) {
    this.isLoading = true
    this.data = await getAllCouponsList({search, type})
    this.isLoading = false
  }

  async getCouponTemplates({search}) {
    this.isLoading = true
    this.templatesData = await getTemplatesList({search})
    this.isLoading = false
  }

  get couponsUpdateList() {
    return this.data
  }

  get couponTemplatesUpdateList() {
    return this.templatesData
  }

  async deleteCoupon(id) {
    this.isLoading = true
    const res = await deleteCouponById(id)
    this.isLoading = false
    return res
  }

  async deleteCouponTemplate(id) {
    this.isLoading = true
    const res = await deleteCouponTemplateById(id)
    this.isLoading = false
    return res
  }

  async getCouponTemplate(id) {
    this.isLoading = true
    const item = await getCouponTemplateById(id)
    this.isLoading = false
    return item
  }

  async getCoupon(subtype) {
    this.isLoading = true
    const item = await getCouponByType(subtype)
    this.isLoading = false
    return item
  }

  async updateCouponTemplate({id, data}) {
    this.isLoading = true
    const item = await updateCouponTemplateById({id, data})
    this.isLoading = false
    return item
  }

  async addCoupon({subtype, data}) {
    this.isLoading = true
    const item = await createCoupon({subtype, data})
    this.isLoading = false
    return item
  }

  async getWheelOfFortune() {
    this.isLoading = true
    const item = await getWheel()
    this.isLoading = false
    return item
  }

  async updateWheelOfFortune({data}) {
    this.isLoading = true
    const item = await updateWheel({data})
    this.isLoading = false
    return item
  }
}

export default CouponStore
