import {observable, makeAutoObservable} from "mobx"

export class BarStore {
  showBar = true
  activeBlocks = ["Admin"]

  constructor() {
    makeAutoObservable(this)
  }

  setActiveBlocks = (name) => {
    this.activeBlocks = this.activeBlocks.includes(name)
      ? this.activeBlocks.filter((p) => p !== name)
      : [...this.activeBlocks, name]
  }

  toggleBar = () => {
    this.showBar = !this.showBar
  }
}

export default BarStore
