import {makeAutoObservable} from "mobx"
import {createSms, createPush} from "./api"

export class NotificationStore {
  isLoading = false

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }

  async addSms({data}) {
    this.isLoading = true
    const item = await createSms({data})
    this.isLoading = false
    return item
  }

  async addPush({data}) {
    this.isLoading = true
    const item = await createPush({data})
    this.isLoading = false
    return item
  }
}

export default NotificationStore
