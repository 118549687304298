import {observer} from "mobx-react-lite"
import {Link as ReactLink} from "react-router-dom"

import Header from "../../components/Header"
import {useStores} from "../../store"
import {ReactComponent as ArrowBack} from "../../assets/images/arrow-left.svg"
import Sidebar from "../../components/Sidebar"
import "./index.scss"

const AppLayout = observer(
  ({
    children,
    title,
    linkBack,
    onLinkBack,
    headComponent,
    Tag = "div",
    submitForm,
    isFull = false
  }) => {
    const {barStore} = useStores()
    return (
      <>
        {!isFull ? <Header /> : null}
        <main className="app_row">
          {!isFull ? <Sidebar /> : null}
          <Tag
            className={`app_content ${barStore.showBar ? "" : "w-100"} ${
              isFull ? "w-100 p-0" : ""
            }`}
            onSubmit={submitForm ? submitForm : null}
          >
            <div
              className={`content_head ${isFull ? "content_head_full" : ""}`}
            >
              <div className="head_inner">
                {linkBack && (
                  <ReactLink to={linkBack} onClick={onLinkBack}>
                    <ArrowBack />
                  </ReactLink>
                )}
                <h1 className="app_page-title">{title}</h1>
              </div>
              {headComponent ? headComponent : null}
            </div>

            {children}
          </Tag>
        </main>
      </>
    )
  }
)

export default AppLayout
