import c from './index.module.scss'
import { ReactComponent as Chevron } from "assets/images/chevron-down.svg";

const Accordion = ({children, title, isOpen, toggleAccordion}) => {

  return (
    <div className={c.filter_accordion}>
      <div className={c.accordion_head} onClick={toggleAccordion}>
        <h3 className={c.accordion_title}>{title}</h3>
        <Chevron className={`${c.chevron} ${isOpen ? c.chevron_open : ''}`} />
      </div>
      <div className={`${c.accordion_content} ${isOpen ? c.accordion_content_opened : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default Accordion