import {useState} from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import CalendarModal from "../CalendarModal"
import {useStores} from "../../store"
import Event from "./event"
import {observer} from "mobx-react"

const renderEventContent = (eventInfo) => {
  return <Event eventInfo={eventInfo} />
}

const Calendar = observer(({isOpen, toggle, dateClick}) => {
  const handleDateClick = (arg) => {
    const data = arg
    data.isCreate = true
    arg.jsEvent.preventDefault()
    arg.jsEvent.stopPropagation()
    toggle()
    dateClick(data)
  }
  const setting = {
    plugins: [
      dayGridPlugin,
      listPlugin,
      interactionPlugin
      // googleCalendarPlugin
    ],
    //Main Key
    // googleCalendarApiKey: "AIzaSyDKS2Z9aYCGH2TZ0GzCl406J15YrabaDKM",

    eventSources: [
      // {
      //     googleCalendarId:
      //         "31a7cjp8qn3pcst83gfb6t39do@group.calendar.google.com",
      //     className: styles.calEvents
      // }
    ],
    dateClick: handleDateClick,
    // eventClick: handleEventClick,
    initialView: "dayGridMonth",
    headerToolbar: {
      left: "prev,next today",
      center: "title",
      right: ""
      // right: "dayGridMonth,listYear"
    },
    eventTimeFormat: {
      hour: "numeric",
      minute: "2-digit",
      meridiem: "short"
    },
    eventContent: renderEventContent,
    eventClassNames: "event_container",
    events: [
      {title: "test", date: "2022-12-23", timeText: "123", id: "5"},
      {title: "11:00am - 5:00pm", date: "2022-12-01", timeText: "123", id: "1"},
      {title: "11:00am - 5:00pm", date: "2022-12-11", timeText: "123", id: "2"},
      {title: "11:00am - 5:00pm", date: "2022-12-25", timeText: "123", id: "3"}
    ]
  }

  return (
    <>
      {isOpen ? <CalendarModal /> : null}
      <FullCalendar {...setting} />
    </>
  )
})

export default Calendar
