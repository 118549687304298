import {observer} from "mobx-react-lite"
import {useFormik} from "formik"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"

import {invitationsSchema} from "../../libs/schemas"
import AppLayout from "../../layouts/App/AppLayout"
import InputField from "../../components/InputField"
import {useStores} from "../../store"
import SaveButton from "../../components/Buttons/SaveButton"
import CustomSelect from "../../components/Select"
import classes from "./index.module.scss"

const InvitationsCreateEdit = observer(() => {
  const {
    invitationsStore: {addInvitations}
  } = useStores()

  const navigate = useNavigate()

  const {dirty, values, handleChange, handleSubmit, errors, setFieldValue} =
    useFormik({
      initialValues: {},
      enableReinitialize: true,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: invitationsSchema,
      onSubmit: async (values) => {
        try {
          const data = await addInvitations({data: values})


          if (data) {
            toast.success(data)
            navigate("/admin/invitations")
          }
        } catch (err) {
          console.log(err.response)
        }
      }
    })

  return (
    <AppLayout
      linkBack="/admin/invitations"
      title="Invite New Member"
      Tag="form"
      submitForm={handleSubmit}
      headComponent={
        <SaveButton disabled={!dirty} text="Send Invite" type="submit" />
      }
    >
      <div className={classes.container}>
        <p className={classes.text}>
          Please enter the information about the new member and send the
          invitation.
        </p>

        <div className={classes.content}>
          <InputField
            label="First Name"
            value={values?.firstname}
            name="firstname"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter First Name (optional)"
          />

          <InputField
            label="Last Name"
            value={values?.lastname}
            name="lastname"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter Last Name (optional)"
          />

          <InputField
            label="Email*"
            value={values?.email}
            name="email"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter Email"
          />

          <CustomSelect
            label="Location*"
            name="location"
            disabled
            options={[{value: null, label: "767 5th Ave, New York"}]}
            value={{value: null, label: "767 5th Ave, New York"}}
            placeholder="Select Location"
            setFieldValue={setFieldValue}
          />

          <CustomSelect
            label="Role*"
            name="role"
            disabled
            options={[{value: null, label: "Admin"}]}
            value={{value: null, label: "Admin"}}
            placeholder="Select Role"
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
    </AppLayout>
  )
})

export default InvitationsCreateEdit
