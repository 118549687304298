import axios from "axios"
import {toast} from "react-toastify"

import {URL} from "../constants"

export const fetchAxios = (props) => {
  const token = localStorage.getItem("token")

  if (token) {
    // const btoa = JSON.parse(token)
    const resProps = {
      ...props,
      baseUrl: URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...props.headers
      }
    }

    return axios(resProps)
  }

  const resProps = {
    ...props,
    headers: {
      "Content-Type": "application/json",
      ...props.headers
    }
  }

  return axios(resProps)
}

axios.defaults.baseURL = URL

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (Array.isArray(error.response?.data?.message)) {
      toast.error(error.response?.data?.message?.[0])
    } else toast.error(error.response?.data?.message ? error.response?.data?.message : error.response?.data)

    if (error?.response?.status === 401 && window.location.pathname !== "/login") {
      localStorage.removeItem("token")
      window.location = "/login"
    }
    return Promise.reject(error)
  }
)
