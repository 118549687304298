import {observable, makeAutoObservable } from "mobx";


export class ShiftScheduleStore {
    showModal = false;
    modalData = {}
    constructor() {
        makeAutoObservable(this)
    }

    toggleModal = () => {
        this.showModal = !this.showModal
    }
    setModalData = (data) => {
        this.modalData = data
    }
}

export default ShiftScheduleStore