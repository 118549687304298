import "./index.scss"
import React from "react"
import {ReactComponent as Check} from "../../assets/images/check.svg"
import {ReactComponent as SortedDesc} from "../../assets/images/descSorted.svg"
import {ReactComponent as SortedAsc} from "../../assets/images/ascSorted.svg"
import {ReactComponent as Sorted} from "../../assets/images/sortedDefault.svg"

import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useFlexLayout
} from "react-table"
import PaginationTable from "./PaginationTable"
import Loader from "../Loader"

const IndeterminateCheckbox = React.forwardRef(
  ({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <label className="selected_checkbox">
        <Check />
        <input
          id="selected"
          className=""
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </label>
    )
  }
)

const Table = ({
  columns,
  data,
  onPageCount = 4,
  isCheckedRow,
  exportButton,
  trFunction
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    state: {pageIndex, pageSize}
  } = useTable(
    {
      // manualPagination: true,
      autoResetPage: false,
      columns,
      data,
      initialState: {pageSize: onPageCount, pageIndex: 0}
    },
    useSortBy,
    usePagination,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      isCheckedRow &&
        hooks.visibleColumns.push((columns) => {
          return [
            {
              id: "selection",
              className: "selection_td",
              // Make this column a groupByBoundary. This ensures that groupBy columns
              // are placed after it
              groupByBoundary: true,
              disableSortBy: true,
              disableResizing: true,
              minWidth: 35,
              width: 35,
              maxWidth: 35,
              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({getToggleAllRowsSelectedProps}) => (
                <div>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({row}) => {
                return (
                  <IndeterminateCheckbox
                    {...row.getToggleRowSelectedProps({})}
                  />
                )
              }
            },
            ...columns
          ]
        })
      hooks.useInstanceBeforeDimensions.push(({headerGroups}) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0]
        selectionGroupHeader.canResize = false
      })
    }
  )

  return (
    <div className="wrap">
      {exportButton && <div className="wrap_head">{exportButton}</div>}
      {!page.length ? (
        <div align="center" className="table_loader">
          <Loader />
        </div>
      ) : (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(
                        column.getSortByToggleProps({
                          style: {
                            maxWidth: column.maxWidth
                          }
                        })
                      )}
                    >
                      <div
                        className={`head-row ${
                          column.centered ? "head-row-center" : ""
                        }`}
                      >
                        <span>{column.render("Header")}</span>
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortedDesc />
                            ) : (
                              <SortedAsc />
                            )
                          ) : column.canSort ? (
                            <Sorted />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              )
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr
                  id={row.id}
                  onClick={(e) => trFunction && trFunction(e, row.id)}
                  {...row.getRowProps()}
                  className={`${row.isSelected ? "selected_row" : ""} `}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          className: cell.column.className,
                          style: {
                            width: cell.column.width,
                            maxWidth: cell.column.maxWidth
                          }
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {page.length ? (
        <div className="pagination">
          <PaginationTable
            className="pagination-bar"
            currentPage={pageIndex + 1}
            pageSize={pageSize}
            totalCount={data?.length}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            totalPageCount={pageCount}
          />
        </div>
      ) : null}
    </div>
  )
}

export default Table
