import {observer} from "mobx-react-lite"
import React, {useEffect, useState} from "react"
import {toast} from "react-toastify"
import {useFormik} from "formik"
import {Link, useLocation} from "react-router-dom"

import AppLayout from "../../layouts/App/AppLayout"
import {notificationPushSchema} from "../../libs/schemas"
import InputField from "../../components/InputField"
import CustomSelect from "../../components/Select"
import {useStores} from "../../store"
import classes from "./index.module.scss"

const NotificationsPush = observer(() => {
  const [needToValidate, setNeedToValidate] = useState(false)

  const {
    usersStore: {getUsers, usersUpdateList},
    notificationStore: {addPush}
  } = useStores()

  const {state} = useLocation()

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const {
    dirty,
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue
  } = useFormik({
    initialValues: {
      code: state?.code || ""
    },
    enableReinitialize: true,
    validateOnChange: needToValidate,
    validateOnBlur: needToValidate,
    validationSchema: notificationPushSchema,
    onSubmit: async (values) => {
      setNeedToValidate(true)

      try {
        let data = {
          userId: values.user?.value,
          code: values.code,
          pushTitle: values.pushTitle,
          pushMessage: values.pushMessage
        }

        const res = await addPush({data})

        if (res) {
          toast.success("Push successfully sent!")
        }
      } catch (err) {
        console.log(err.response)
      }
    }
  })

  return (
    <AppLayout
      title="Notifications"
      Tag="form"
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          <button
            disabled={!isValid || !dirty}
            className={classes.submit}
            type="submit"
            onClick={() => setNeedToValidate(true)}
          >
            Submit
          </button>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.links}>
          <Link to={"/notifications/sms"} state={{code: state?.code}}>
            SMS
          </Link>
          <button>Push</button>
          {/*<Link to={"/coupons/generator/personal/add"}>Personal</Link>*/}
        </div>

        <div className={classes.content}>
          <CustomSelect
            label="User"
            name="user"
            options={[
              {value: "all", label: "All Users"},
              ...usersUpdateList.map((c) => ({
                ...c,
                label: `${c.firstname || ""} ${c.lastname || ""}, ${
                  c.phone || ""
                }`,
                value: c.id
              }))
            ]}
            errors={errors}
            values={values?.user}
            placeholder="Enter phone number or username"
            setFieldValue={setFieldValue}
          />

          <InputField
            label="Coupon code"
            value={values?.code}
            name="code"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter coupon code"
          />

          <InputField
            label="Push title"
            value={values?.pushTitle}
            name="pushTitle"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter Push Title"
          />

          <InputField
            label="Push body"
            value={values?.pushMessage}
            name="pushMessage"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter Push Text"
            type="textarea"
          />
        </div>
      </div>
    </AppLayout>
  )
})

export default NotificationsPush
