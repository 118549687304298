import activeStep from "../../../assets/images/activeStep.svg"
import prevStep from "../../../assets/images/prevStep.svg"
import nextStep from "../../../assets/images/nextStep.svg"
import classes from "./index.module.scss"

const StepsLine = ({quantity, currentStep}) => {
  //need to rewrite
  if (quantity === 2) {
    return (
      <div className={classes.container}>
        <div className={classes.stepInfo}>
          <img
            src={currentStep === "General info" ? activeStep : prevStep}
            alt="step"
          />

          <div className={classes.stepInfoData}>
            <span className={classes.numberOfStep}>STEP 1</span>
            <span className={classes.nameOfStep}>General info</span>
          </div>

          <div className={classes.line}>
            <div className={classes.activePartOfLine}></div>
            <div
              className={
                currentStep === "General info"
                  ? classes.disabledPartOfLine
                  : classes.activePartOfLine
              }
            ></div>
          </div>
        </div>

        <div className={classes.stepInfo}>
          <img
            src={currentStep === "General info" ? nextStep : activeStep}
            alt="step"
          />

          <div className={classes.stepInfoData}>
            <span className={classes.numberOfStep}>STEP 2</span>
            <span className={classes.nameOfStep}>Set visibility</span>
          </div>
        </div>
      </div>
    )
  }

  if (quantity === 5) {
    return (
      <div className={classes.container}>
        <div className={classes.stepInfo}>
          <img
            src={currentStep === "General info" ? activeStep : prevStep}
            alt="step"
          />

          <div className={classes.stepInfoData}>
            <span className={classes.numberOfStep}>STEP 1</span>
            <span className={classes.nameOfStep}>General info</span>
          </div>

          <div className={classes.line}>
            <div className={classes.activePartOfLine}></div>
            <div
              className={
                currentStep === "General info"
                  ? classes.disabledPartOfLine
                  : classes.activePartOfLine
              }
            ></div>
          </div>
        </div>

        <div className={classes.stepInfo}>
          <img
            src={
              currentStep === "Add variations"
                ? activeStep
                : currentStep === "General info"
                ? nextStep
                : prevStep
            }
            alt="step"
          />

          <div className={classes.stepInfoData}>
            <span className={classes.numberOfStep}>STEP 2</span>
            <span className={classes.nameOfStep}>Add Variations</span>
          </div>

          <div className={classes.line}>
            <div
              className={
                currentStep === "General info"
                  ? classes.disabledPartOfLine
                  : classes.activePartOfLine
              }
            ></div>
            <div
              className={
                currentStep === "General info" ||
                currentStep === "Add variations"
                  ? classes.disabledPartOfLine
                  : classes.activePartOfLine
              }
            ></div>
          </div>
        </div>

        <div className={classes.stepInfo}>
          <img
            src={
              currentStep === "Add modifiers"
                ? activeStep
                : currentStep === "Addons" || currentStep === "Set visibility"
                ? prevStep
                : nextStep
            }
            alt="step"
          />

          <div className={classes.stepInfoData}>
            <span className={classes.numberOfStep}>STEP 3</span>
            <span className={classes.nameOfStep}>Add Modifiers</span>
          </div>

          <div className={classes.line}>
            <div
              className={
                currentStep === "Add modifiers" ||
                (currentStep === "Addons") | (currentStep === "Set visibility")
                  ? classes.activePartOfLine
                  : classes.disabledPartOfLine
              }
            ></div>
            <div
              className={
                currentStep === "Addons" || currentStep === "Set visibility"
                  ? classes.activePartOfLine
                  : classes.disabledPartOfLine
              }
            ></div>
          </div>
        </div>

        <div className={classes.stepInfo}>
          <img
            src={
              currentStep === "Addons"
                ? activeStep
                : currentStep === "Set visibility"
                ? prevStep
                : nextStep
            }
            alt="step"
          />

          <div className={classes.stepInfoData}>
            <span className={classes.numberOfStep}>STEP 4</span>
            <span className={classes.nameOfStep}>Addons</span>
          </div>

          <div className={classes.line}>
            <div
              className={
                currentStep === "Set visibility" || currentStep === "Addons"
                  ? classes.activePartOfLine
                  : classes.disabledPartOfLine
              }
            ></div>
            <div
              className={
                currentStep === "Set visibility"
                  ? classes.activePartOfLine
                  : classes.disabledPartOfLine
              }
            ></div>
          </div>
        </div>

        <div className={classes.stepInfo}>
          <img
            src={currentStep === "Set visibility" ? activeStep : nextStep}
            alt="step"
          />

          <div className={classes.stepInfoData}>
            <span className={classes.numberOfStep}>STEP 5</span>
            <span className={classes.nameOfStep}>Set visibility</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.stepInfo}>
        <img
          src={currentStep === "General info" ? activeStep : prevStep}
          alt="step"
        />

        <div className={classes.stepInfoData}>
          <span className={classes.numberOfStep}>STEP 1</span>
          <span className={classes.nameOfStep}>General info</span>
        </div>

        <div className={classes.line}>
          <div className={classes.activePartOfLine}></div>
          <div
            className={
              currentStep === "General info"
                ? classes.disabledPartOfLine
                : classes.activePartOfLine
            }
          ></div>
        </div>
      </div>

      <div className={classes.stepInfo}>
        <img
          src={
            currentStep === "Add variations"
              ? activeStep
              : currentStep === "General info"
              ? nextStep
              : prevStep
          }
          alt="step"
        />

        <div className={classes.stepInfoData}>
          <span className={classes.numberOfStep}>STEP 2</span>
          <span className={classes.nameOfStep}>Add Variations</span>
        </div>

        <div className={classes.line}>
          <div
            className={
              currentStep === "General info"
                ? classes.disabledPartOfLine
                : classes.activePartOfLine
            }
          ></div>
          <div
            className={
              currentStep === "General info" || currentStep === "Add variations"
                ? classes.disabledPartOfLine
                : classes.activePartOfLine
            }
          ></div>
        </div>
      </div>

      <div className={classes.stepInfo}>
        <img
          src={
            currentStep === "Add modifiers"
              ? activeStep
              : currentStep === "Set visibility"
              ? prevStep
              : nextStep
          }
          alt="step"
        />

        <div className={classes.stepInfoData}>
          <span className={classes.numberOfStep}>STEP 3</span>
          <span className={classes.nameOfStep}>Add Modifiers</span>
        </div>

        <div className={classes.line}>
          <div
            className={
              currentStep === "Set visibility" ||
              currentStep === "Add modifiers"
                ? classes.activePartOfLine
                : classes.disabledPartOfLine
            }
          ></div>
          <div
            className={
              currentStep === "Set visibility"
                ? classes.activePartOfLine
                : classes.disabledPartOfLine
            }
          ></div>
        </div>
      </div>

      <div className={classes.stepInfo}>
        <img
          src={currentStep === "Set visibility" ? activeStep : nextStep}
          alt="step"
        />

        <div className={classes.stepInfoData}>
          <span className={classes.numberOfStep}>STEP 4</span>
          <span className={classes.nameOfStep}>Set visibility</span>
        </div>
      </div>
    </div>
  )
}

export default StepsLine
