import {fetchAxios} from "../axios"

export const getCategoriesList = async () => {
  try {
    const res = await fetchAxios({
      url: "/categories"
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateCategoriesList = async (data) => {
  try {
    const res = await fetchAxios({
      url: "/categories",
      method: "PUT",
      data: {ids: data}
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getCategory = async (id) => {
  try {
    const res = await fetchAxios({
      url: `/categories/${id}`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateCategory = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/categories/${id}`,
      method: "PUT",
      data,
      headers: {
        "Content-Type": "multipart/form-data; "
      }
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const assignToCategory = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/categories/${id}/assign`,
      method: "PUT",
      data: {ids: data}
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getAssignedToCategory = async (id) => {
  try {
    const res = await fetchAxios({
      url: `/categories/${id}/assign`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}