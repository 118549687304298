import * as yup from "yup"

export const passwordRegEx =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?()\]-]{8,25}$/

const isRequired = "is required"
const isInvalid = "is invalid"
const isValidEmail = "Enter a valid email"

const integerValidation = (value) => {
  if (!Number.isInteger(value) || String(value).includes(".")) {
    return false
  }
  return true
}

export const usersSchema = yup.object({
  email: yup
    .string(`Enter member email`)
    .email(`${isValidEmail}`)
    .required(`Email ${isRequired}`)
})

export const invitationsSchema = yup.object({
  email: yup
    .string(`Enter member email`)
    .email(`${isValidEmail}`)
    .required(`Email ${isRequired}`)
})

export const menuSchema = yup.object({
  title: yup.string(`Enter item name`).required(`Item name ${isRequired}`),
  isPureItem: yup
    .object()
    .shape({
      value: yup.boolean().required(""),
      label: yup.string().required("")
    })
    .required(`Is pure item ${isRequired}`)
})

export const categoriesSchema = yup.object({
  description: yup
    .string(`Enter description`)
    .required(`Description ${isRequired}`),
  fullDescription: yup
    .string(`Enter full description`)
    .required(`Full description ${isRequired}`),
  visibility: yup
    .object()
    .shape({
      value: yup.string().required(""),
      label: yup.string().required("")
    })
    .required(`Item visibility ${isRequired}`)
})

export const modifiersSchema = yup.object({
  title: yup
    .string(`Enter modifier title`)
    .required(`Modifier title ${isRequired}`)
})

export const ingredientsSchema = yup.object({
  name: yup.string(`Enter ingredients title`).required(`Title ${isRequired}`),
  price: yup.number(`Enter price`).required(`Price ${isRequired}`),
  type: yup.object().shape().required(`Type ${isRequired}`),
  groupType: yup.object().shape().required(`Group type ${isRequired}`)
})

export const modifierSetsSchema = yup.object({})

export const notificationSmsSchema = yup.object({
  phone: yup
    .string()
    .matches(/^[+1]\d{10}$/, `Phone number ${isInvalid}`)
    .required(`Phone number ${isRequired}`),
  message: yup.string("Enter sms text").required(`Message text ${isRequired}`)
})

export const notificationPushSchema = yup.object({
  user: yup.object().required(`User ${isRequired}`),
  pushTitle: yup
    .string("Enter push title")
    .required(`Push title ${isRequired}`),
  pushMessage: yup
    .string("Enter push message")
    .required(`Push message ${isRequired}`)
})

export const wheelSchema = yup.object({
  sections: yup.array().of(yup.string().required(`Section ${isRequired}`)),
  lifespan: yup
    .number(`Enter lifespan`)
    .min(1, "Lifespan must be a positive number")
    .required(`Lifespan ${isRequired}`)
})

export const specialSchema = yup.object({
  active: yup
    .boolean(`Set active field`)
    .required(`Modifier title ${isRequired}`),
  description: yup
    .string(`Enter description`)
    .required(`Description ${isRequired}`),
  lifespan: yup
    .number(`Enter lifespan`)
    .min(1, "Lifespan must be a positive number")
    .required(`Lifespan ${isRequired}`),
  recurrence: yup
    .mixed()
    .oneOf(["regular", "repeat", "schedule"])
    .required(`Recurrence ${isRequired}`),
  discountType: yup
    .mixed()
    .oneOf(["amount", "percentage", "item"])
    .required(`Discount type ${isRequired}`),
  value: yup.number().when("discountType", {
    is: (discountType) =>
      discountType === "amount" || discountType === "percentage",
    then: yup
      .number()
      .test("integer", "Please enter an integer value", (value) =>
        integerValidation(value)
      )
      .min(0, "Must be a positive number")
      .required(`Discount amount ${isRequired}`)
  }),
  category: yup
    .object()
    .nullable()
    .when("discountType", {
      is: (discountType) => discountType === "item",
      then: yup
        .object()
        .nullable()
        .shape({
          value: yup.string().nullable().required(`Need to select one category`)
        })
        .required(`Category ${isRequired}`)
    })
})

export const personalSchema = yup.object({
  description: yup
    .string(`Enter description`)
    .required(`Description ${isRequired}`),
  pushTitle: yup
    .string(`Enter push title`)
    .required(`Push title ${isRequired}`),
  pushMessage: yup
    .string(`Enter push text`)
    .required(`Push text ${isRequired}`),
  lifespan: yup
    .number(`Enter lifespan`)
    .min(1, "Lifespan must be a positive number")
    .required(`Lifespan ${isRequired}`),
  user: yup.object().required(`User ${isRequired}`),
  discountType: yup
    .mixed()
    .oneOf(["amount", "percentage", "item"])
    .required(`Discount type ${isRequired}`),
  value: yup.number().when("discountType", {
    is: (discountType) =>
      discountType === "amount" || discountType === "percentage",
    then: yup
      .number()
      .test("integer", "Please enter an integer value", (value) =>
        integerValidation(value)
      )
      .min(0, "Must be a positive number")
      .required(`Discount amount ${isRequired}`)
  }),
  category: yup
    .object()
    .nullable()
    .when("discountType", {
      is: (discountType) => discountType === "item",
      then: yup
        .object()
        .nullable()
        .shape({
          value: yup.string().nullable().required(`Need to select one category`)
        })
        .required(`Category ${isRequired}`)
    })
})

export const regularSchema = yup.object({
  description: yup
    .string(`Enter description`)
    .required(`Description ${isRequired}`),
  lifespan: yup
    .number(`Enter lifespan`)
    .min(1, "Lifespan must be a positive number")
    .required(`Lifespan ${isRequired}`),
  discountType: yup
    .mixed()
    .oneOf(["amount", "percentage", "item"])
    .required(`Discount type ${isRequired}`),
  value: yup.number().when("discountType", {
    is: (discountType) =>
      discountType === "amount" || discountType === "percentage",
    then: yup
      .number()
      .test("integer", "Please enter an integer value", (value) =>
        integerValidation(value)
      )
      .min(0, "Must be a positive number")
      .required(`Discount amount ${isRequired}`)
  }),
  category: yup
    .object()
    .nullable()
    .when("discountType", {
      is: (discountType) => discountType === "item",
      then: yup
        .object()
        .nullable()
        .shape({
          value: yup.string().nullable().required(`Need to select one category`)
        })
        .required(`Category ${isRequired}`)
    })
})

export const birthdaySchema = yup.object({
  active: yup
    .boolean(`Set active field`)
    .required(`Modifier title ${isRequired}`),
  description: yup
    .string(`Enter description`)
    .required(`Description ${isRequired}`),
  lifespan: yup
    .number(`Enter lifespan`)
    .min(1, "Lifespan must be a positive number")
    .required(`Lifespan ${isRequired}`),
  discountType: yup
    .mixed()
    .oneOf(["amount", "percentage", "item"])
    .required(`Discount type ${isRequired}`),
  value: yup.number().when("discountType", {
    is: (discountType) =>
      discountType === "amount" || discountType === "percentage",
    then: yup
      .number()
      .test("integer", "Please enter an integer value", (value) =>
        integerValidation(value)
      )
      .min(0, "Must be a positive number")
      .required(`Discount amount ${isRequired}`)
  }),
  category: yup
    .object()
    .nullable()
    .when("discountType", {
      is: (discountType) => discountType === "item",
      then: yup
        .object()
        .nullable()
        .shape({
          value: yup.string().nullable().required(`Need to select one category`)
        })
        .required(`Category ${isRequired}`)
    })
})

export const firstRegisterSchema = yup.object({
  active: yup
    .boolean(`Set active field`)
    .required(`Modifier title ${isRequired}`),
  description: yup
    .string(`Enter description`)
    .required(`Description ${isRequired}`),
  lifespan: yup
    .number(`Enter lifespan`)
    .min(1, "Lifespan must be a positive number")
    .required(`Lifespan ${isRequired}`),
  discountType: yup
    .mixed()
    .oneOf(["amount", "percentage", "item"])
    .required(`Discount type ${isRequired}`),
  value: yup.number().when("discountType", {
    is: (discountType) =>
      discountType === "amount" || discountType === "percentage",
    then: yup
      .number()
      .test("integer", "Please enter an integer value", (value) =>
        integerValidation(value)
      )
      .min(0, "Must be a positive number")
      .required(`Discount amount ${isRequired}`)
  }),
  category: yup
    .object()
    .nullable()
    .when("discountType", {
      is: (discountType) => discountType === "item",
      then: yup
        .object()
        .nullable()
        .shape({
          value: yup.string().nullable().required(`Need to select one category`)
        })
        .required(`Category ${isRequired}`)
    })
})
