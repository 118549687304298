

import './index.scss'
const Loader = ({isFull= false}) => {

    return(
        <div className={`overlay_loader ${isFull ? 'is_full' : ''}`}>
            <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader