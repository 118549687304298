import './index.scss';
import { ReactComponent  as Arrow}  from "../../../assets/images/arrow-top.svg";
import { observer } from "mobx-react-lite"


const StatsListItem = observer(({title, count, percent}) => {
    return  (
        <li className='stats_item'>
            <div className='stats_item-details'>
                <div className="details_title">
                    {title}
                </div>
                <div className="details_count">
                    {count}
                </div>
            </div>
            <div className='item_count'>
              <Arrow />  {percent}%
            </div>
        </li>
    )
})

export default StatsListItem