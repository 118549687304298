import React, {useCallback, useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"

import AppLayout from "../../layouts/App/AppLayout"
import {ReactComponent as Pencil} from "../../assets/images/actions/pencil.svg"
import {ReactComponent as Trash} from "../../assets/images/actions/trash.svg"
import {useStores} from "../../store"
import {DraggableTable} from "../../components/DraggableTable"
import classes from "./index.module.scss"

const Invitations = observer(() => {
  const {
    invitationsStore: {
      getInvitationsList,
      invitationsUpdateList,
      deleteInvitation,
      isLoading
    }
  } = useStores()

  const [isFirst, setIsFirst] = useState(true)
  const [invitations, setInvitations] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (!isFirst) {
      return
    }
    setIsFirst(false)

    if (!isLoading && !invitationsUpdateList.length) {
      getInvitationsList()
    }
  }, [getInvitationsList, isFirst, isLoading, invitationsUpdateList])

  useEffect(() => {
    setInvitations(invitationsUpdateList)
  }, [invitationsUpdateList])

  const handleEdit = useCallback(
    (e, id) => {
      e.stopPropagation()
      navigate(`/admin/invitations/update/${id}`)
    },
    [navigate]
  )

  const handleRemove = useCallback(
    async (e, id) => {
      e.stopPropagation()
      const data = await deleteInvitation(id)

      if (data) {
        toast.success("Invitation was successfully deleted!")
      }
    },
    [deleteInvitation]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "firstname",
        Cell: ({row: {original}}) => (
          <span>
            {original.firstname} {original.lastname}
          </span>
        )
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({cell: {value}}) => (value ? value : "-")
      },
      {
        Header: "Location",
        accessor: "companyId",
        Cell: ({cell: {value}}) => (value ? value : "-")
      },
      {
        Header: "Email",
        width: 250,
        accessor: "email",
        Cell: ({cell: {value}}) => (value ? value : "-")
      },
      {
        Header: " ",
        accessor: "action",
        width: 80,
        enableSorting: false,
        disableSortBy: true,
        flex: "end",
        className: "action_wrapper_td",
        Cell: ({row: {original}}) => {
          return (
            <div className={classes.actionsRow}>
              {/*<div*/}
              {/*  onClick={(e) => handleEdit(e, original.id)}*/}
              {/*  className={`${classes.action} ${classes.actionChange}`}*/}
              {/*>*/}
              {/*  <Pencil />*/}
              {/*  /!*navigate(`/admin/users/${original.id}`)*!/*/}
              {/*</div>*/}
              <div
                onClick={(e) => handleRemove(e, original.id)}
                className={`${classes.action} ${classes.actionDelete}`}
              >
                <Trash />
              </div>
            </div>
          )
        }
      }
    ],
    []
  )

  return (
    <>
      <AppLayout
        title="Invitations"
        headComponent={
          <div className="head_component">
            <button
              onClick={() => navigate("/admin/invitations/add")}
              className="admin_btn"
            >
              Add new user
            </button>
          </div>
        }
      >
        <DraggableTable
          columns={columns}
          data={invitations}
          setData={setInvitations}
        />
      </AppLayout>
    </>
  )
})

export default Invitations
