import {DOTS, usePagination} from "hooks/usePagination"
import "./index.scss"
import { ReactComponent as ChevronRight } from "assets/images/chevron-mini-right.svg";
import { ReactComponent as ChevronLeft } from "assets/images/chevron-mini-left.svg";
import CustomSelect from "components/Select"
import React from "react"

const PaginationTable = ({
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
  gotoPage,
  previousPage,
  nextPage,
  totalPageCount,
                           changeLimit,
                           limit
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
    totalPageCount
  })
  console.log(limit, 'limit')
  let lastPage = paginationRange[paginationRange?.length - 1]
  return (
    <div className='pagination'>
      <ul className={`pagination-container ${className ? className : ""}`}>
        <li
          className={`pagination-item ${currentPage === 1 ? "disabled" : ""}`}
          onClick={previousPage}
        >
          <ChevronLeft />
        </li>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li className="pagination-item dots" key={pageNumber}>
                &#8230;
              </li>
            )
          }

          return (
            <li
              className={`pagination-item ${
                pageNumber === currentPage ? "selected" : ""
              }`}
              onClick={() => gotoPage(pageNumber)}
              key={pageNumber}
            >
              {pageNumber}
            </li>
          )
        })}
        <li
          className={`pagination-item ${
            currentPage === lastPage ? "disabled" : ""
          }`}
          onClick={nextPage}
        >
          <ChevronRight/>
        </li>
      </ul>
      {limit &&
        <>
          <div className="limit_title">Items per page</div>
          <CustomSelect
            menuPlacement='top'
            name="limit"
            options={[
              {value: '5', label: "5"},
              {value: '10', label: "10"},
              {value: '50', label: "50"},
              {value: '100', label: "100"},
              {value: '500', label: "500"},
            ]}
            value={{value: limit?.toString(), label: limit?.toString()}}
            setFieldValue={changeLimit}
          />
        </>
      }

    </div>
  )
}

export default PaginationTable
