import {observer} from "mobx-react-lite"
import React, {useCallback, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"
import AppLayout from "layouts/App/AppLayout"
import {ReactComponent as Pencil} from "assets/images/actions/pencil.svg"
import {useStores} from "store"
import {ReactComponent as Trash} from "assets/images/actions/trash.svg"
import {DraggableTable} from "components/DraggableTable"
import SearchInput from "components/Search"
import classes from "./index.module.scss"
import Accordion from "components/IngredientsAccordion"
import Checkbox from "components/Checkbox"
import {v4 as uuid} from "uuid"
import {IngredientsTypeEnums} from "libs/enums"
import CustomSelect from "components/Select"



const Ingredients = observer(() => {
  const {
    ingredientsStore: {
      ingredientsUpdateList,
      removeIngredient,
      pagination,
      getIngredientsWithFilters,
      modifiersList,
      currentPage,
      limit,
      setNewPage,
      selectedModifiers,
      setSelectedModifiers,
      setNewLimit,
      filters,
      getFilters,
      setUpdatedFilters,
      updatedFilters
    }
  } = useStores()
  const [searchValue, setSearchValue] = useState("")
  const [filtersSearchValue, setFiltersSearchValue] = useState("")
  const [openAccordions, setOpenAccordions] = useState({});
  const navigate = useNavigate()
  const [type, setType] = useState(null)


  const handleEdit = useCallback(
    (e, id) => {
      e.stopPropagation()
      navigate(`/menu/ingredients/update/${id}`)
    },
    [navigate]
  )

  const handleRemove = useCallback(
    async (id) => {
      const res = await removeIngredient(id)
      if (res) {
        toast.success("Ingredient successfully deleted!")
        getIngredientsWithFilters(
          {
            name:  searchValue,
            modifiers: modifiersList,
            page: currentPage,
            limit: limit,
          }
        )
      }
    },
    [getIngredientsWithFilters, removeIngredient, modifiersList]
  )

  const handleSearch = useCallback(
    (search) => {
      setSearchValue(search)
      getIngredientsWithFilters(
        {
            name:  search,
            modifiers: modifiersList,
            page: 1,
            limit: limit,
          ...(type?.value ? { type: type.value } : {})
        }
        )
    },
    [getIngredientsWithFilters, modifiersList]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "name",
        enableSorting: false,
        disableSortBy: true,
        width: 250,
        Cell: ({cell: {value}}) => (
          <span className={classes.description}>{value ? value : "-"}</span>
        )
      },
      {
        Header: "Group type",
        accessor: "groupType",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({cell: {value}}) => (
          <span className={classes.description}>{value ? value : "-"}</span>
        )
      },
      {
        Header: "Price",
        accessor: "price",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({cell: {value}}) => (value ? `${(value / 100).toFixed(2)}` : "-")
      },
      {
        Header: "Type",
        accessor: "type",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({cell: {value}}) => (
          <span className={classes.description}>{value ? value : "-"}</span>
        )
      },
      {
        Header: " ",
        accessor: "action",
        width: 80,
        flex: "end",
        enableSorting: false,
        disableSortBy: true,
        className: "action_wrapper_td",
        Cell: ({row: {original}}) => {
          return (
            <div className={classes.actionsRow}>
              <div
                onClick={(e) => handleEdit(e, original.id)}
                className={`${classes.action} ${classes.actionChange}`}
              >
                <Pencil />
              </div>

              <div
                onClick={() => handleRemove(original.id)}
                className={`${classes.action} ${classes.actionDelete}`}
              >
                <Trash />
              </div>
            </div>
          )
        }
      }
    ],
    [handleEdit]
  )
  useEffect(() => {
    !filters?.length && getFilters()
  }, [])


  useEffect(() => {
    getIngredientsWithFilters({
      name: searchValue,
      modifiers: selectedModifiers,
      page: currentPage,
      limit: limit,
      ...(type?.value ? { type: type.value } : {})
    })
  }, [currentPage, limit, JSON.stringify(selectedModifiers), type])

  const goToSelectedPage = (page) => {
    setNewPage(page)
  }

  const handleChangeLimit = (name, value) => {
    setNewPage(1)
    setNewLimit(Number(value.value))
  }

  const handleCheckbox = useCallback((e) => {
    const value = e.target.value;
    setSelectedModifiers(value)
    setNewPage(1)
  }, [setSelectedModifiers]);



  const toggleAccordion = useCallback((index) => {
    setOpenAccordions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  }, []);

  const searchAndMoveToFirstFilters = useCallback((name) => {
    const searchWords = name.toLowerCase().split(' ')?.filter(Boolean);
    const index = filters?.find(item => {
      const modifiersNames = item?.modifiers?.map(mod => mod?.modifier_name?.toLowerCase());
      const hasMatchingModifier = searchWords.some(word =>
        modifiersNames.some(modifierName => modifierName.includes(word))
      );
      return hasMatchingModifier;
    });
    const findedItems = updatedFilters?.filter((item) => item?.name !== index?.name)
    if (index?.name) {
      setUpdatedFilters([index, ...findedItems]);
    }else{
      setUpdatedFilters(filters)
    }
  }, [filters, filtersSearchValue]);

  const handleChangeFilter = async (name, value) => {

    value && await  getFilters(value.value)
    return setType(value)
  }

  return (
    <AppLayout
      title="Ingredients"
      headComponent={
        <div className="head_component">
         <div style={{width: '200px'}}>
           <CustomSelect
             name="type"
             options={[  {value: null, label: "All"},...IngredientsTypeEnums,]}
             value={type}
             placeholder="Filter by type"
             setParam="id"
             setFieldValue={handleChangeFilter}
             isClerable
           />
         </div>
          <div
            style={{
              marginRight: '164px'
            }}
          >
            <SearchInput
              onSearch={handleSearch}
              setValue={setSearchValue}
              value={searchValue}
              placeholder='Search by ingredient'
            />
          </div>
          <button
            onClick={() => navigate("/menu/ingredients/add")}
            className="admin_btn"
          >
            Add new
          </button>
        </div>
      }
    >
      {ingredientsUpdateList &&
        <div className={classes.ingredients_row}>

          <DraggableTable
            columns={columns}
            data={ingredientsUpdateList}
            currentPage={currentPage}
            pageSize={pagination?.itemsPerPage}
            totalCount={pagination?.totalItems}
            gotoPage={goToSelectedPage}
            previousPage={() => goToSelectedPage(currentPage - 1)}
            nextPage={() => goToSelectedPage(currentPage + 1)}
            totalPageCount={pagination?.totalPages}
            showPagination={pagination.totalItems}
            limit={limit}
            changeLimit={handleChangeLimit}
          />
          <div className={classes.ingredients_container}>
            <SearchInput
              onSearch={searchAndMoveToFirstFilters}
              setValue={setFiltersSearchValue}
              value={filtersSearchValue}
            />
            <div className={classes.container_inner}>
              {updatedFilters?.map(({name, modifiers}, index) => {
                return(
                  <Accordion
                    title={name}
                    key={uuid()}
                    isOpen={openAccordions[index]}
                    toggleAccordion={() => toggleAccordion(index)}
                  >
                    <div className={classes.modifiers_list}>
                      {modifiers?.map(({modifier_id, modifier_name}) => {
                        return(
                          <Checkbox
                            key={uuid()}
                            name={modifier_id}
                            id={modifier_id}
                            onChange={handleCheckbox}
                            selected={modifiersList.find((el) => el === modifier_id)}
                            label={modifier_name}
                          />
                        )
                      })}
                    </div>
                  </Accordion>
                )
              })}
            </div>
          </div>
        </div>
      }
    </AppLayout>
  )
})

export default Ingredients
