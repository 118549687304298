export const URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://dev.api.admin.puzzle-pizza.agilistrysoft.com/"
// 'https://staging.api.admin.pizza.agilistrysoft.com/'
export const visibilityValues = [
  {value: "coming_soon", label: "Coming Soon"},
  {value: "hidden", label: "Hidden"},
  {value: "active", label: "Active"}
]

export const isPureValues = [
  {
    value: true,
    label: "Pure item"
  },
  {
    value: false,
    label: "Not pure item"
  }
]
