export const IngredientsTypeEnums = [
    {value: "addons", label: "Addons"},
    {value: "removes", label: "Removes"},
    {value: "addons_puzzle_pizza", label: "Addons Puzzle Pizza"}
]



export const  DropEnums = [
    {
        name: 'Week',
        value: 'week'
    },
    {
        name: 'Month',
        value: 'month'
    },
    {
        name: 'Quarter',
        value: 'quarter'
    },
    {
        name: 'Year',
        value: 'year'
    },
    {
        name: 'Custom',
        value: 'custom'
    },
]
export const  UserDetailsDropEnums = [
    {
        name: 'Daily',
        value: 'daily'
    },
    {
        name: 'Weekly',
        value: 'weekly'
    },
    {
        name: 'Monthly',
        value: 'monthly'
    },
    {
        name: 'Yearly',
        value: 'yearly'
    },
]


export const CheckEnums = [
    {
        name: 'Mycoupn',
        label: 'Mycoupn',
        id:1
    },
    {
        name: 'Mycoupn',
        label: 'Mycoupn',
        id:2
    },
    {
        name: 'Mycoupn',
        label: 'Mycoupn',
        id:3
    },
    {
        name: 'Mycoupn',
        label: 'Mycoupn',
        id:4
    },
]