import React from "react"

import InputField from "../../../components/InputField"
import CustomSelect from "../../../components/Select"
import Checkbox from "../../../components/Checkbox"
import {CancelButton, SaveButton} from "../../../components/Buttons"
import CustomModal from "../../../components/Modal"
import classes from "../index.module.scss"

const ModifiersSetsModal = ({
  isModifiersModalOpen,
  modifierSetToUpdate,
  handleChangeModifiersSet,
  errors,
  modifierSetsUpdateList,
  setModSetFieldValue,
  handleChangeModifiersSetCheckbox,
  handleCancelModSet,
  handleSaveModSet
}) => (
  <CustomModal open={isModifiersModalOpen}>
    <h3>
      {modifierSetToUpdate?.id ? "Change modifiers set" : "Add modifiers set"}
    </h3>

    <InputField
      label="Title*"
      value={modifierSetToUpdate?.title}
      name="title"
      onChange={handleChangeModifiersSet}
      errors={errors}
      placeholder="Title"
    />

    {!modifierSetToUpdate?.id && (
      <CustomSelect
        label="Choose modifiers set*"
        name="modifierId"
        options={[
          {value: undefined, label: "-"},
          ...modifierSetsUpdateList.map((c) => ({
            ...c,
            label: c.name,
            value: c.id
          }))
        ]}
        value={
          modifierSetToUpdate?.modifierId
            ? modifierSetsUpdateList
                .map((c) => ({
                  ...c,
                  label: c.name,
                  value: c.id
                }))
                .find((v) => {
                  return v.value === modifierSetToUpdate.modifierId
                })
            : null
        }
        placeholder="Modifiers set selector"
        setFieldValue={setModSetFieldValue}
      />
    )}

    <hr className={classes.hr} />

    <InputField
      label="Minimum modifiers set"
      value={modifierSetToUpdate?.min}
      name="min"
      type="number"
      onChange={handleChangeModifiersSet}
      errors={errors}
      placeholder="Input number"
    />

    <InputField
      label="Maximum modifiers set"
      value={modifierSetToUpdate?.max}
      name="max"
      type="number"
      onChange={handleChangeModifiersSet}
      errors={errors}
      placeholder="Input number"
    />

    <div className={classes.checkbox}>
      <Checkbox
        selected={modifierSetToUpdate?.required}
        id="required"
        name="required"
        onChange={handleChangeModifiersSetCheckbox}
      />

      <span>Required modifiers</span>
    </div>

    <div className={classes.checkbox}>
      <Checkbox
        selected={modifierSetToUpdate?.multiselect}
        id="multiselect"
        name="multiselect"
        onChange={handleChangeModifiersSetCheckbox}
      />

      <span>Multiselect</span>
    </div>

    <div className={classes.actionButtons}>
      <CancelButton type="button" onClick={handleCancelModSet} />

      <SaveButton
        type="button"
        onClick={handleSaveModSet}
        text={modifierSetToUpdate?.id ? "Save changes" : "Add"}
      />
    </div>
  </CustomModal>
)

export default ModifiersSetsModal
