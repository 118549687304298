import AppLayout from "../../layouts/App/AppLayout"
import "./index.scss"
import StatsListItem from "../../components/DashboardPage/StatsListItem"
import BtnExport from "../../components/BtnExport"
import Tabs from "../../components/Tabs"
import SearchInput from "../../components/Search"
import DropDown from "../../components/DropDown"
import Table from "../../components/Table"
import React, {useEffect, useState} from "react"
import moment from "moment"
import axios from "axios"

const style = {
  width: "490px",
  display: "flex",
  alignItems: "center",
  gap: "14px"
}

const styleDrop = {
  height: "43px",
  background: "#FFFFFF",
  border: "1px solid #9B9B9B",
  borderRadius: "22px"
}

const ItemsSold = () => {
  const [data, setData] = useState([])
  const [data2, setData2] = useState([])

  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Name",
            accessor: "name",
            Cell: ({cell: {value}}) => (value ? value : "-")
          },
          {
            Header: "Label",
            accessor: "label1",
            Cell: ({cell: {value}}) => (value ? value : "-")
          },
          {
            Header: "Label",
            accessor: "label2",
            Cell: ({cell: {value}}) => (value ? value : "-")
          },
          {
            Header: "Label",
            accessor: "label3",
            Cell: ({cell: {value}}) => (value ? value : "-")
          }
        ]
      }
    ],
    []
  )

  const get = async () => {
    const {data} = await axios.get("https://jsonplaceholder.typicode.com/users")
    const newData = data.map(({name, id}) => {
      const status = [
        " BBQ Chicken",
        "Spicy Honey Pepperoni",
        "Classic Pepperoni"
      ]
      return {
        name: status[Math.floor(Math.random() * status.length)]
      }
    })
    const newData2 = data.map(({name, id}) => {
      const status = [
        " Pepperoni Сheese",
        "Spicy Honey Pepperoni",
        " BBQ Chicken",
        "Classic Pepperoni"
      ]
      return {
        name: status[Math.floor(Math.random() * status.length)],
        label1: "value"
      }
    })
    setData(newData)
    setData2(newData2)
  }
  useEffect(() => {
    get()
  }, [])
  return (
    <AppLayout
      title="Items sold"
      headComponent={
        <div style={style}>
          <div style={{width: "256px"}}>
            <SearchInput filterIcon />
          </div>
          <div style={{width: "211px"}}>
            <DropDown
              dropList={[
                {
                  name: "Weekly",
                  value: "week"
                }
              ]}
              style={styleDrop}
            />
          </div>
        </div>
      }
    >
      <section className="sold_items_wrapper">
        <div className="wrapper_head">
          <h3>Overview</h3>
          <BtnExport title="Excel Sheet" />
        </div>
        <ul className="sold_items_list">
          <StatsListItem title="Total Items Sold" count="2200" percent="80" />
          <StatsListItem title="Total Amount" count="$2200" percent="80" />
          <StatsListItem title="Label" count="Value" percent="80" />
        </ul>
        <Tabs>
          <div label="All">
            {data && (
              <Table columns={columns} data={data} isCheckedRow={false} />
            )}
          </div>
          <div label="Pizza">
            <Table columns={columns} data={data2} isCheckedRow={false} />
          </div>
          <div label="Wings">Nothing to see</div>
          <div label="Salads">Nothing to see</div>
          <div label="Focaccia">Nothing to see</div>
          <div label="Desserts">Nothing to see</div>
          <div label="Beverages">Nothing to see</div>
        </Tabs>
      </section>
    </AppLayout>
  )
}

export default ItemsSold
