import {useEffect, useState} from "react"
import {Navigate, useNavigate, useLocation} from "react-router-dom"
import {toast} from "react-toastify"

import CustomModal from "../../components/Modal"
import logo from "../../assets/images/logo.png"
import goBack from "../../assets/images/arrow-left.svg"
import InputField from "../../components/InputField"
import {CancelButton, SaveButton} from "../../components/Buttons"
import {useStores} from "../../store"
import classes from "./index.module.scss"

const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [type, setType] = useState("login")
  const [resent, setResent] = useState(false)

  const {
    authStore: {login, forgotPassword, getRecover, postRecover},
    invitationsStore: {postInvitation, getInvitation}
  } = useStores()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const handleRecover = async () => {
      const res = await getRecover({token: location.search.slice(7)})

      if (res) {
        setEmail(res.email)
      } else {
        setType("login")
        navigate("/login")
      }
    }

    const handleInvite = async () => {
      const data = await getInvitation({code: location.search?.slice(6)})

      if (data) {
        setType("invitation")
        setEmail(data.email)
      } else {
        setType("login")
        navigate("/login")
      }
    }

    if (location?.pathname === "/recover") {
      setType("recover")
      handleRecover()
    }

    if (location.pathname === "/invitation") {
      handleInvite()
    }
  }, [getInvitation, getRecover, location, navigate])

  const token = localStorage.getItem("token")

  if (
    token &&
    location?.pathname !== "/recover" &&
    location?.pathname !== "/invitation"
  ) {
    return <Navigate to="/menu/items" replace />
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleChangePassword = (e) => {
    setPassword(e.target.value)
  }

  const handleForgotPassword = () => {
    setType("restorePassword")
  }

  const handleLogin = async () => {
    setPassword("")
    setEmail("")
    const data = await login({email, password})

    if (data?.accessToken) {
      localStorage.setItem("token", data.accessToken)
      navigate("/menu/items")
    }
  }

  const handleRecover = async () => {
    const data = await postRecover({token: location.search?.slice(7), password})

    if (data) {
      toast.success(data)

      setType("login")
      navigate("/login")
    }
  }

  const handleInvite = async () => {
    const data = await postInvitation({
      code: location.search?.slice(6),
      password
    })

    if (data) {
      toast.success("User was successfully created")

      handleLogin()
    }
  }

  const handleRestorePassword = async () => {
    const res = await forgotPassword({email, resent})

    if (res) {
      toast.success(res.message || "Recovery message has been sent")
    }

    setResent(true)
  }

  const handleChangeType = (newType) => {
    setType(newType)
  }

  const disabledLogin = !email || !password

  if (type === "recover" || type === "invitation") {
    return (
      <CustomModal
        open={true}
        className={classes.container}
        classNameOverlay={classes.overlay}
      >
        <img src={logo} alt="logo" width={84} />

        <div className={classes.nameBlock}>
          <h2 className={classes.name}>
            {type === "invitation" ? "Create password" : "Set new password"}
          </h2>
        </div>

        <InputField
          label="Password"
          name="password"
          value={password}
          onChange={handleChangePassword}
          type="password"
          placeholder="Your password"
        />

        <SaveButton
          onClick={type === "invitation" ? handleInvite : handleRecover}
          text={type === "invitation" ? "Set password" : "Change password"}
          disabled={!password}
        />
      </CustomModal>
    )
  }

  return (
    <CustomModal
      open={true}
      className={classes.container}
      classNameOverlay={classes.overlay}
    >
      <img src={logo} alt="logo" width={84} />

      <div className={classes.nameBlock}>
        {type === "restorePassword" && (
          <img
            src={goBack}
            alt="go back"
            onClick={() => handleChangeType("login")}
          />
        )}
        <h2 className={classes.name}>
          {type === "login" ? "Admin login" : "Forgot password"}
        </h2>
      </div>

      <InputField
        label="Email"
        name="email"
        value={email}
        onChange={handleChangeEmail}
        type="text"
        placeholder="Your email"
      />

      {type === "login" && (
        <InputField
          label="Password"
          name="password"
          value={password}
          onChange={handleChangePassword}
          type="password"
          placeholder="Your password"
        />
      )}

      {type === "login" && (
        <CancelButton onClick={handleForgotPassword} text="Forgot password" />
      )}

      {type === "login" ? (
        <SaveButton
          onClick={handleLogin}
          text="LOGIN"
          disabled={disabledLogin}
        />
      ) : (
        <SaveButton
          onClick={handleRestorePassword}
          text={resent ? "RESTORE AGAIN" : "RESTORE PASSWORD"}
          disabled={!email}
        />
      )}
    </CustomModal>
  )
}

export default Login
