import {useCallback, useState} from "react"
import {debounce} from "chart.js/helpers"

import Search from "../Search"
import CustomModal from "../Modal"
import {CancelButton, SaveButton} from "../Buttons"
import Checkbox from "../Checkbox"
import classes from "./index.module.scss"

const AssignItemsModal = ({
  open,
  data,
  nameOfField = "title",
  name = "Assign items",
  subTitle = null,
  onSubmit = () => null,
  onCancel = () => null,
  setSearch = () => null,
  onSelect = () => null,
  selected,
  helperText,
  workOnAll,
  onSelectAll = () => null,
  onSelectNone = () => null
}) => {
  const [searchValue, setSearchValue] = useState("")

  return (
    <CustomModal open={open} className={classes.modal}>
      <div>
        <h3>{name}</h3>
        {workOnAll && (
          <div className={classes.headerActions}>
            <CancelButton text="Select all" onClick={onSelectAll} />
            <CancelButton text="Select none" onClick={onSelectNone} />
          </div>
        )}
        {subTitle && <p className={classes.sub_title}>{subTitle}</p>}
      </div>

      {helperText && <span>{helperText}</span>}

      <Search
        value={searchValue}
        setValue={setSearchValue}
        onSearch={setSearch}
        size="full"
      />

      {!!data?.length && (
        <div className={classes.list}>
          {data.map((el) => (
            <div className={classes.line} key={el.id}>
              <Checkbox
                selected={selected.includes(el.id.toString())}
                id={el.id}
                onChange={onSelect}
              />

              <span>{el[nameOfField]}</span>
            </div>
          ))}
        </div>
      )}

      <div className={classes.actions}>
        <CancelButton onClick={onCancel} />
        <SaveButton onClick={onSubmit} />
      </div>
    </CustomModal>
  )
}

export default AssignItemsModal
