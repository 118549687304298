import {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useFormik} from "formik"
import {useNavigate, useParams} from "react-router-dom"
import {toast} from "react-toastify"

import {usersSchema} from "../../libs/schemas"
import AppLayout from "../../layouts/App/AppLayout"
import InputField from "../../components/InputField"
import {useStores} from "../../store"
import SaveButton from "../../components/Buttons/SaveButton"
import CustomSelect from "../../components/Select"
import classes from "./index.module.scss"

const UsersCreateEdit = observer(() => {
  const [user, setUser] = useState(null)

  const {
    usersStore: {getUser, updateUser}
  } = useStores()

  const navigate = useNavigate()
  const {id} = useParams()

  useEffect(() => {
    const getUserData = async () => {
      console.log(id)
      const data = await getUser(id)
      if (data) {
        setUser(data)
      }
    }

    id && getUserData()
  }, [getUser, id])

  const {dirty, values, handleChange, handleSubmit, errors, setFieldValue} =
    useFormik({
      initialValues: {
        firstname: user?.firstname || "",
        lastname: user?.lastname || ""
      },
      enableReinitialize: true,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: id ? null : usersSchema,
      onSubmit: async (values) => {
        try {
          if (id) {
            const data = await updateUser({id, data: values})

            if (data) {
              toast.success("User data was successfully changed!")
              navigate("/admin/users")
            }

            return null
          }
        } catch (err) {
          console.log(err.response)
        }
      }
    })

  return (
    <AppLayout
      linkBack="/admin/users"
      title={id ? user?.email : "Create new member"}
      Tag="form"
      submitForm={handleSubmit}
      headComponent={
        <SaveButton
          disabled={!dirty}
          text={id ? "Save changes" : "Create"}
          type="submit"
        />
      }
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <InputField
            label="First Name"
            value={values?.firstname}
            name="firstname"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter First Name (optional)"
          />

          <InputField
            label="Last Name"
            value={values?.lastname}
            name="lastname"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter Last Name (optional)"
          />

          {!id && (
            <InputField
              label="Email*"
              value={values?.email}
              name="email"
              onChange={handleChange}
              errors={errors}
              placeholder="Enter Email"
            />
          )}

          <CustomSelect
            label="Location*"
            name="location"
            disabled
            options={[{value: null, label: "767 5th Ave, New York"}]}
            value={{value: null, label: "767 5th Ave, New York"}}
            placeholder="Select Location"
            setFieldValue={setFieldValue}
          />

          <CustomSelect
            label="Role*"
            name="role"
            disabled
            options={[{value: null, label: "Admin"}]}
            value={{value: null, label: "Admin"}}
            placeholder="Select Role"
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
    </AppLayout>
  )
})

export default UsersCreateEdit
