import SearchInput from "../Search"
import Scrollbar from "../ScrollBar"
import {v4 as uuid} from "uuid"
import {useState} from "react"
import "./index.scss"

const UsersListWithSearch = ({usersList, filterIcon}) => {
  const [selected, setSelected] = useState("")

  return (
    <div className="users_list_wrapper">
      <div className="users_selected_list">
        <div className="list_item selected">Employees</div>
      </div>
      <div className="users_list">
        <div className="list_head">
          <SearchInput filterIcon={filterIcon} />
        </div>
        {usersList.length ? (
          <ul className="list_colum">
            {usersList.map(({name, customerImage}) => {
              return (
                <li
                  key={uuid()}
                  onClick={() => setSelected(name)}
                  className={`${selected === name ? "selected_user" : ""}`}
                >
                  <img src={customerImage} alt="" />
                  <h5>{name}</h5>
                </li>
              )
            })}
          </ul>
        ) : null}
      </div>
    </div>
  )
}

export default UsersListWithSearch
