import percent from "../../assets/images/percent.svg"
import dollar from "../../assets/images/dollar.svg"
import error from "../../assets/images/error.svg"
import classes from "./index.module.scss"

const InputField = ({
  label,
  value,
  name,
  onChange,
  type = "text",
  className,
  placeholder = "",
  errors,
  withIcon,
  afterText,
  disabled,
  inLine,
  max,
  min,
  size = "full"
}) => {
  return (
    <label
      className={`${classes.container} ${className ? className : ""} ${
        errors?.[name] ? classes.field_error : ""
      } ${classes[size]} ${inLine ? classes.inLine : ""}`}
    >
      {label}
      {type === "textarea" ? (
        <textarea
          value={value}
          onChange={onChange}
          name={name}
          id={name}
          placeholder={placeholder}
          rows="5"
          className={`${withIcon ? classes.withIcon : ""}`}
        />
      ) : (
        <input
          type={
            type === "price" || type === "discountAbs" || type === "discountPer"
              ? "number"
              : type
          }
          value={value}
          onChange={onChange}
          name={name}
          id={name}
          disabled={disabled}
          min={min}
          max={type === "discountPer" ? 90 : max}
          placeholder={placeholder}
          className={`${withIcon ? classes.withIcon : ""}`}
        />
      )}
      {(type === "price" || type === "discountAbs") && (
        <img
          src={dollar}
          alt="$"
          className={inLine ? classes.beforeIconInLine : classes.beforeIcon}
        />
      )}
      {type === "discountPer" && (
        <img
          src={percent}
          alt="%"
          className={inLine ? classes.beforeIconInLine : classes.beforeIcon}
        />
      )}

      {afterText && <span className={classes.afterText}>{afterText}</span>}
      {errors?.[name] && (
        <div className={classes.field_error_text}>
          <img src={error} alt=" " />
          {errors[name]}
        </div>
      )}
    </label>
  )
}

export default InputField
