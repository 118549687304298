import AppLayout from "../../layouts/App/AppLayout";
import './index.scss'
import CouponModal from "components/CouponModal";
import {useStores} from "store";
import {observer} from "mobx-react-lite";


const CustomersDetails = observer(() => {
    const {couponStore} = useStores()

    return (
        <>
            {couponStore.showCouponModal &&
                <CouponModal />
            }
            <AppLayout
                title='Customers details'
                linkBack={'/customers'}
            >
                <div className="customer_details">
                    <div className="details_row">
                        <div className="row_customer_desc">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsUmE6FMTWUnXwdX_vkq9XMe_6AIFuuBY_lQ&usqp=CAU" alt=""/>
                            <div className="desc_details">
                                <h3>Johnny Cook</h3>
                                <span>(072) 452-3365 | johny@email.com | 27/06/1997</span>
                            </div>
                        </div>
                        <div className="row_action">
                            <input onClick={() => couponStore.toggleModal()} type="button" value='SEND COUPON' />
                        </div>
                    </div>
                    <ul className="details_row count_row">
                        <li>
                            <h2 className="count">300</h2>
                            <div className="title_count">Total Orders</div>
                        </li>
                        <li>
                            <h2 className="count">$90</h2>
                            <div className="title_count">Order Value</div>
                        </li>
                        <li>
                            <h2 className="count">2</h2>
                            <div className="title_count">Referred</div>
                        </li>
                        <li>
                            <h2 className="count">1</h2>
                            <div className="title_count">Review</div>
                        </li>
                        <li>
                            <h2 className="count">5</h2>
                            <div className="title_count">Points</div>
                        </li>
                        <li>
                            <h2 className="count">2</h2>
                            <div className="title_count">Coupons</div>
                        </li>
                    </ul>
                    <div className="details_row column_row">
                        <h4 className='row_title'>ADDRESS</h4>
                        <ul className='address_row'>
                            <li>
                                <h3 className="address_head">BILLING ADDRESS</h3>
                                <div className="address_body">
                                    <h3>Johnny Cook</h3>
                                    <p>1623 E Updahl Ct, Harrison, Id, 83833</p>
                                    <p>Abc@Gmail.Com</p>
                                    <p>8193-198472</p>
                                </div>
                            </li>
                            <li>
                                <h3 className="address_head">DELIVERY ADDRESS</h3>
                                <div className="address_body">
                                    <h3>Johnny Cook</h3>
                                    <p>1623 E Updahl Ct, Harrison, Id, 83833</p>
                                    <p>Abc@Gmail.Com</p>
                                    <p>8193-198472</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="details_row column_row">
                        <h4 className='row_title'>PAST ORDERS</h4>
                        <div className="past_orders">
                            <table>
                                <thead>
                                <tr>
                                    <th>
                                        Order Id
                                    </th>
                                    <th>
                                        Date
                                    </th>
                                    <th>Qty</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>#1701</td>
                                    <td>02/03/2021</td>
                                    <td>Qty</td>
                                    <td>$30.00</td>
                                    <td className='status_td'>
                                        <div className='status'>
                                            Delivered
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>#1701</td>
                                    <td>02/03/2021</td>
                                    <td>Qty</td>
                                    <td>$30.00</td>
                                    <td className='status_td'>
                                        <div className='status'>
                                            Delivered
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>#1701</td>
                                    <td>02/03/2021</td>
                                    <td>Qty</td>
                                    <td>$30.00</td>
                                    <td className='status_td'>
                                        <div className='status'>
                                            Delivered
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>#1701</td>
                                    <td>02/03/2021</td>
                                    <td>Qty</td>
                                    <td>$30.00</td>
                                    <td className='status_td'>
                                        <div className='status'>
                                            Delivered
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>#1701</td>
                                    <td>02/03/2021</td>
                                    <td>Qty</td>
                                    <td>$30.00</td>
                                    <td className='status_td'>
                                        <div className='status'>
                                            Delivered
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="details_row column_row">
                        <h4 className='row_title'>REFERRED MEMBERS</h4>
                        {/*<NoDataFound />*/}
                        <ul className="referred_members_row">
                            <li>
                                <figure>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsUmE6FMTWUnXwdX_vkq9XMe_6AIFuuBY_lQ&usqp=CAU" alt=""/>
                                </figure>
                                <h4>James Jani</h4>
                                <p>1623 E Updahl Ct, Harrison, Id, 83833</p>
                                <p>(072) 452-3365 | Johny@Email.Com | 27/06/1997</p>
                            </li>
                            <li>
                                <figure>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsUmE6FMTWUnXwdX_vkq9XMe_6AIFuuBY_lQ&usqp=CAU" alt=""/>
                                </figure>
                                <h4>James Jani</h4>
                                <p>1623 E Updahl Ct, Harrison, Id, 83833</p>
                                <p>(072) 452-3365 | Johny@Email.Com | 27/06/1997</p>
                            </li>
                            <li>
                                <figure>
                                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsUmE6FMTWUnXwdX_vkq9XMe_6AIFuuBY_lQ&usqp=CAU" alt=""/>
                                </figure>
                                <h4>James Jani</h4>
                                <p>1623 E Updahl Ct, Harrison, Id, 83833</p>
                                <p>(072) 452-3365 | Johny@Email.Com | 27/06/1997</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </AppLayout>
        </>
    )
})


export default CustomersDetails