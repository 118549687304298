import Excel from '../../assets/images/export-excel.svg'
import './index.scss'

const BtnExport = ({ title, href, filename }) => {
    const handleDownload = () => {
        // fetchAx( {
        //     url: `${DOMAIN_API}${href}`,
        //     responseType: 'blob',
        // })
        //     .then((response) => {
        //         const url = window.URL.createObjectURL(new Blob([response.data]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', filename);
        //         document.body.appendChild(link);
        //         link.click();
        //         link.parentNode.removeChild(link);
        //     })
    }

    return(
        <button
            className="btn_export"
            onClick={handleDownload}
            type='button'
        >
            <img src={Excel} alt=""/>
            {title}
        </button>

    )}

export default BtnExport;
