import {ReactComponent as EditImage} from "../../assets/images/editImage.svg"
import {ReactComponent as UploadImage} from "../../assets/images/uploadImage.svg"
import {ReactComponent as Trash} from "../../assets/images/trash.svg"
import emptyImage from "../../assets/images/emptyImage.png"
import classes from "./index.module.scss"
import {useRef} from "react"

const UploadInput = ({
  label,
  name,
  typeFile = "image/png",
  preview,
  imageSrc,
  errors,
  size = "full",
  fileInputHandler
}) => {
  const fileInputRef = useRef(null);

  // Function to handle div click
  const handleImage = () => {
    fileInputRef.current.click();
  };

  return (
    <div className={`${classes.container} ${classes[size]}`}>
      {label}
      <img
        src={imageSrc ? imageSrc : emptyImage}
        className={classes.image}
        alt={label}
      />
      {imageSrc ? (
        <div className={classes.imageUpload}>
          <div className={classes.imageUploadRow} onClick={handleImage}>
            <EditImage />
            <span>Edit photo</span>
          </div>
          <Trash onClick={(e) => fileInputHandler(e, 'delete', preview)} />
        </div>
      ) : (
        <div className={classes.imageUpload} onClick={handleImage}>
          <div className={classes.imageUploadRow}>
            <UploadImage />
            <span>Upload</span>
          </div>
        </div>
      )}
      <input
        type="file"
        accept={`${typeFile}`}
        onChange={(e) => fileInputHandler(e, name, preview)}
        name={name}
        id={name}
        className={classes.input}
        ref={fileInputRef}
      />
      {errors[name] && <div className="field_error-text">{errors[name]}</div>}
    </div>
  )
}

export default UploadInput
