import {ReactComponent as Edit} from "../../assets/images/actions/pencil.svg";
import {ReactComponent as Trash} from "../../assets/images/actions/trash.svg";
import {useStores} from "../../store";


const Event = ({eventInfo}) => {
    const {shiftStore: {showModal, toggleModal, setModalData}} = useStores()

    const handleEditEvent = (e, info) => {
        e.stopPropagation()
        setModalData(info)
        toggleModal()
    }
    const handleRemoveEvent = (e, info) => {
        e.stopPropagation()
        if(window.confirm("Would you like to remove from " + info.event.startStr + " ?")){
            console.log(info, 'eventInfo.event.id')
        }
    }

    return (
        <>
            <div className='calendar_event'>
                <div className={'event_title'}>{eventInfo.event.title}</div>
                <div className='time_count'>
                    (6 hrs)
                </div>
                <div className='event_action'>
                    <div className="action edit"
                         onClick={e => handleEditEvent(e, eventInfo)}
                    >
                        <Edit  />
                    </div>
                    <div className="action remove"
                         onClick={e => handleRemoveEvent(e, eventInfo)}
                    >
                        <Trash   />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Event