import Modal from "react-modal"
import classes from "./index.module.scss"

Modal.setAppElement("#admin")

const CustomModal = ({children, open, className, classNameOverlay}) => {
  const afterOpenModal = () => {
    document.getElementById("admin").style.overflow = "hidden"
    document.body.style.overflow = "hidden"
  }

  return (
    <Modal
      onAfterOpen={afterOpenModal}
      className={`${classes.container} ${className}`}
      overlayClassName={`${classes.overlay} ${classNameOverlay}`}
      isOpen={open}
      contentLabel="modal"
      closeTimeoutMS={400}
    >
      {children}
    </Modal>
  )
}

export default CustomModal
