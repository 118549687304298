import {makeAutoObservable} from "mobx"
import {
  assignToCategory,
  getAssignedToCategory,
  getCategoriesList,
  getCategory,
  updateCategoriesList,
  updateCategory
} from "./api"

export class CategoriesStore {
  data = []
  isLoading = false

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }

  async getCategory(id) {
    this.isLoading = true
    const item = await getCategory(id)
    this.isLoading = false
    return item
  }

  async updateCategory({id, data}) {
    this.isLoading = true
    const item = await updateCategory({id, data})
    if (item) {
      await getCategoriesList()
    }
    this.isLoading = false
    return item
  }

  async getCategories() {
    this.isLoading = true
    this.data = await getCategoriesList()
    this.isLoading = false
  }

  async updateCategories(data) {
    this.isLoading = true
    this.data = await updateCategoriesList(data)
    this.isLoading = false
  }

  async getAssignedItemsToCategory(id) {
    this.isLoading = true
    const assignedData = await getAssignedToCategory(id)
    this.isLoading = false
    return assignedData
  }

  async assignToCategory({id, data}) {
    this.isLoading = true
    await assignToCategory({id, data})
    this.isLoading = false
  }

  get categoriesUpdateList() {
    const update = this.data?.map((props) => ({
      ...props,
      categoryImage:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsUmE6FMTWUnXwdX_vkq9XMe_6AIFuuBY_lQ&usqp=CAU"
    }))
    return update
  }
}

export default CategoriesStore
