import React from "react"
import AuthStore from "./auth/auth"
import BarStore from "./bar/bar"
import CouponStore from "./coupon/coupon"
import UsersStore from "./users/users"
import MenuStore from "./menu/menu"
import ShiftScheduleStore from "./shift_schedule/shift_schedule"
import CategoriesStore from "./categories/categories"
import ModifiersStore from "./modifiers/modifiers"
import InvitationsStore from "./invitations/invitations"
import NotificationStore from "./notifications/notification"
import IngredientsStore from "./ingredients/ingredients"
import KitchensSettingsStore from "store/kitchens/kitchens"

class RootStore {
  constructor() {
    this.authStore = new AuthStore(this)
    this.barStore = new BarStore(this)
    this.couponStore = new CouponStore(this)
    this.usersStore = new UsersStore(this)
    this.shiftStore = new ShiftScheduleStore(this)
    this.menuStore = new MenuStore(this)
    this.categoriesStore = new CategoriesStore(this)
    this.modifiersStore = new ModifiersStore(this)
    this.invitationsStore = new InvitationsStore(this)
    this.kitchensStore = new KitchensSettingsStore(this)
    this.notificationStore = new NotificationStore(this)
    this.ingredientsStore = new IngredientsStore(this)
  }
}

export const rootStore = new RootStore()

const StoreContext = React.createContext(new RootStore())

export const useStores = () => React.useContext(StoreContext)
