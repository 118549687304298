import {ReactComponent as Dashboard} from "../assets/images/bar/dashboard.svg"
import {ReactComponent as Orders} from "../assets/images/bar/orders.svg"
import {ReactComponent as Reports} from "../assets/images/bar/reports.svg"
import {ReactComponent as Admin} from "../assets/images/bar/admin.svg"
import {ReactComponent as Menu} from "../assets/images/bar/menu.svg"
import {ReactComponent as Customers} from "../assets/images/bar/customers.svg"
import {ReactComponent as Cupon} from "../assets/images/bar/cupon.svg"
import {ReactComponent as Settings} from "../assets/images/bar/settings.svg"
import {ReactComponent as Notifications} from "../assets/images/bar/notifications.svg"
import {ReactComponent as Arrow} from "../assets/images/down-arrow.svg"

export const RouteEnums = [
  {
    name: "Dashboard",
    link: "/dashboard",
    Icon: Dashboard
  },
  {
    name: "Orders",
    link: "/orders",
    Icon: Orders
  },
  {
    name: "Customers",
    link: "/customers",
    Icon: Customers
  },
  {
    name: "Reports",
    link: "/reports",
    Icon: Reports,
    SubIcon: Arrow,
    subLinks: [
      {
        name: "Items sold",
        link: "/reports/items-sold"
      },
      {
        name: "Employee Hours",
        link: "/reports/employee-hours"
      }
    ]
  },
  {
    name: "Menu",
    link: "/menu",
    Icon: Menu,
    SubIcon: Arrow,
    subLinks: [
      {
        name: "Items",
        link: "/menu/items"
      },
      {
        name: "Categories",
        link: "/menu/categories"
      },
      {
        name: "Modifier sets",
        link: "/menu/modifier-sets"
      },
      {
        name: "Modifiers",
        link: "/menu/modifiers"
      },
      {
        name: "Ingredients",
        link: "/menu/ingredients"
      }
    ]
  },
  {
    name: "Coupons",
    link: "/coupons",
    Icon: Cupon,
    SubIcon: Arrow,
    subLinks: [
      {
        name: "All coupons",
        link: "/coupons/all"
      },
      {
        name: "Templates",
        link: "/coupons/templates"
      },
      {
        name: "Generator",
        link: "/coupons/generator"
      },
      {
        name: "Wheel of Fortune",
        link: "/coupons/wheel"
      }
    ]
  },
  {name: "Notifications", link: "/notifications", Icon: Notifications},
  {
    name: "Admin",
    link: "/admin",
    Icon: Settings,
    SubIcon: Arrow,
    subLinks: [
      {
        name: "Staff Members",
        link: "/admin/users"
      },
      {
        name: "Invitations",
        link: "/admin/invitations",
        accessRoles: ["SUPER_ADMIN"]
      },
      {
        name: "Timesheet",
        link: "/admin/timesheet"
      },
      {
        name: "Shift & Schedule",
        link: "/admin/shift-schedule"
      },
      {
        name: "Working Hours",
        link: "/admin/working-hours"
      }
    ]
  }
  // {
  //     name: 'Settings',
  //     link: '/settings',
  //     Icon: Settings,
  // },
]
