import React, {useEffect, useRef, useState} from 'react'
import AppLayout from "../../layouts/App/AppLayout";
import Table from "../../components/Table";
import axios from "axios";
import {observer} from "mobx-react-lite";
import moment from "moment";

const Orders = observer(() => {

    const columns = React.useMemo(() => [
            {
                Header: " ",
                disableSortBy: true,
                columns: [
                    {
                        Header: "Order ID",
                        accessor: "id",
                        Cell: ({cell: {value}}) => value ? value : "-"
                    },
                    {
                        Header: "Customer Name",
                        accessor: "customerName",
                        Cell: ({cell: {value}}) => value ? value : "-"
                    },
                    {
                        Header: "Date",
                        accessor: "date",
                        Cell: ({cell: {value}}) => value ? value : "-"
                    },
                    {
                        Header: "Price",
                        accessor: "price",
                        Cell: ({cell: {value}}) => value ? value : "-"
                    },
                ]
            },
        ],
        []
    );


    const [data, setData] = useState([])

    const get = async () => {
        const {data} = await axios.get('https://jsonplaceholder.typicode.com/users')
        const newData = data?.map(({name, id,}) => {
            let currDate = moment().format("l")
            return {
                customerName: name,
                id: id,
                date: currDate,
                price: Math.floor(Math.random() * 3000)
            }
        })
        setData(newData)
    }
    useEffect(() => {
        get()
    }, [])

    return  (
        <AppLayout title='Orders'>
            <Table
                columns={columns}
                data={data}
                isCheckedRow={false}
            />
        </AppLayout>
    )
})

export default Orders