import React from "react";

const  Tab = ({label, onClick, activeTab}) => {
    const onChangeTab = () => {
        // const { label, onClick } = this.props;
        onClick(label);
    };

        // const {
        //     onClick,
        //     props: { activeTab, label },
        // } = this;

        let className = "tab-list-item";

        if (activeTab === label) {
            className += " tab-list-active";
        }

        return (
            <li className={className} onClick={onChangeTab}>
                {label}
            </li>
        );
}

export default Tab;