import DatePicker from "react-datepicker"
import {forwardRef, useRef} from "react"

import Calendar from "assets/images/calendar.svg"
import classes from "./index.module.scss"

const CustomDatePicker = ({
  range = false,
  setEndDate,
  setStartDate,
  startDate,
  endDate,
  placeholder,
}) => {
  const inputRef = useRef(null)
  const ExampleCustomInput = forwardRef((props, ref) => (
    <div className={classes.admin_picker}>
      <img onClick={props.onClick} src={Calendar} alt="" />
      <input type="text" {...props} ref={ref} />
    </div>
  ))

  return (
    <>
      {range ? (
        <div className={classes.container}>
          <div className={classes.block}>
            <span className={classes.label}>Start date</span>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate("startDate", date)}
              selectsStart
              startDate={startDate}
              placeholderText="Set start date"
              endDate={endDate}
              maxDate={endDate}
              customInput={<ExampleCustomInput ref={inputRef} />}

            />
          </div>

          <div className={classes.block}>
            <span className={classes.label}>End date</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate("endDate", date)}
              selectsEnd
              placeholderText="Set end date"
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              customInput={<ExampleCustomInput ref={inputRef} />}

            />
          </div>
        </div>
      ) : (
        <DatePicker
          selected={startDate && new Date(startDate)}
          placeholderText={placeholder}
          onChange={(date) => setStartDate(date)}
          customInput={<ExampleCustomInput ref={inputRef} />}
        />
      )}
    </>
  )
}

export default CustomDatePicker
