import './index.scss'
import {ReactComponent as Close}  from "../../assets/images/close_red.svg";
import {ReactComponent as Search}  from "../../assets/images/search.svg";
import {useStores} from "../../store";
import {CheckEnums} from "../../libs/enums";
import Checkbox from "../Checkbox";
import {v4 as uuid} from "uuid";
import {useState} from "react";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import { observer } from "mobx-react-lite"

const CouponModal = observer(()=> {
    const {couponStore} = useStores()
    const [selected, setSelected] = useState('')

     const handleCheckCoupon = (id) => {
         setSelected(id)
     }
     const outsideClick = () => {
         couponStore.toggleModal()
     }
    const handleSendCoupon = (e) => {
        e.preventDefault()
    }
    const ref = useOutsideClick(outsideClick)

    return (
        <div className='coupon_overlay'>
            <div className="coupon_wrapper" ref={ref}>
                <div className="wrapper_head">
                    <h2>Select Coupon</h2>
                    <Close onClick={() => couponStore.toggleModal()}/>
                </div>
                <div className="wrapper_content">
                    <div className="wrapper_search_field">
                        <Search />
                        <input
                            className='search_field'
                            placeholder='Search'
                            type="text"
                        />
                    </div>
                    <form className='coupon_form' onSubmit={handleSendCoupon}>
                        <ul className='coupon_list'>
                            {CheckEnums.map(({name, id, label}) => {
                                return(
                                    <li  key={uuid()}>
                                        <Checkbox

                                            name={name}
                                            id={id}
                                            onChange={handleCheckCoupon}
                                            selected={selected}
                                            label={label}
                                        />
                                    </li>
                                )
                            })}
                        </ul>
                        <input type="submit" value='SEND'/>
                    </form>
                </div>
            </div>
        </div>
    )
})

export default CouponModal