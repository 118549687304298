import {useEffect} from "react";
import AppLayout from "layouts/App/AppLayout";
import './index.scss'
import UsersListWithSearch from "components/UsersListWithSearch";
import {useStores} from "store";
import Calendar from "components/Calendar";
import {observer} from "mobx-react";

const ShiftSchedule = observer(() => {

    const {usersStore: {getUsers, usersUpdateList, isLoading}} = useStores()
    const {shiftStore: {showModal, toggleModal, setModalData}} = useStores()
    useEffect( () => {
        getUsers()
    }, [])

    return (
        <AppLayout title='Shift & Schedule'>
            <div className="shift_schedule_wrapper">
                <UsersListWithSearch filterIcon={true} usersList={usersUpdateList.slice(0, 10)}/>
                <div className='shift_schedule_calendar'>
                    <Calendar isOpen={showModal} toggle={toggleModal} dateClick={setModalData}  />
                </div>
            </div>
        </AppLayout>
    )
})

export default ShiftSchedule