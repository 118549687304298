import {observer} from "mobx-react-lite"
import React, {useCallback, useEffect, useState, useMemo} from "react"
import {Link, useNavigate} from "react-router-dom"

import AppLayout from "../../layouts/App/AppLayout"
import {ReactComponent as Pencil} from "../../assets/images/actions/pencil.svg"
import {useStores} from "../../store"
import AssignItemsModal from "../../components/AssignItemsModal"
import {DraggableTable} from "../../components/DraggableTable"
import {CancelButton, SaveButton} from "../../components/Buttons"
import greenCircle from "../../assets/images/greenCircle.svg"
import grayCircle from "../../assets/images/grayCircle.svg"
import yellowCircle from "../../assets/images/yellowCircle.svg"
import classes from "./index.module.scss"

const Categories = observer(() => {
  const [openDrug, setOpenDrug] = useState(false)
  const [categories, setCategories] = useState([])

  const [assignItemsOpen, setAssignItemsOpen] = useState(false)
  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const [categoryId, setCategoryId] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const {
    categoriesStore: {
      getCategories,
      categoriesUpdateList,
      updateCategories,
      assignToCategory,
      getAssignedItemsToCategory
    }
  } = useStores()

  const navigate = useNavigate()

  useEffect(() => {
    if (!categoriesUpdateList?.length) {
      getCategories()
    }
  }, [categoriesUpdateList, getCategories])

  useEffect(() => {
    setCategories(categoriesUpdateList)
  }, [categoriesUpdateList])

  const handleEdit = useCallback(
    (e, id) => {
      e.stopPropagation()
      navigate(`/menu/categories/update/${id}`)
    },
    [navigate]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "Description",
        accessor: "description",
        disableSortBy: true,
        Cell: ({cell: {value}}) => (value ? value : "-")
      },
      {
        Header: "Full description",
        accessor: "fullDescription",
        disableSortBy: true,
        width: 200,
        Cell: ({cell: {value}}) => (value ? value : "-")
      },
      {
        Header: "Visibility",
        accessor: "visibility",
        disableSortBy: true,
        Cell: ({cell: {value}}) => (
          <div className={classes.availability}>
            <img
              src={
                value === "active"
                  ? greenCircle
                  : value === "hidden"
                  ? grayCircle
                  : yellowCircle
              }
              alt={value}
            />
            {value}
          </div>
        )
      },
      {
        Header: " ",
        accessor: "action",
        width: 150,
        flex: "end",
        Cell: ({row: {original}}) => {
          return (
            <div className={classes.actions}>
              <Link
                to="/menu/items"
                state={{
                  categoryName: original.description,
                  categoryNameBack: original.name,
                  categoryId: original.id
                }}
                className={classes.assign}
              >
                View category
              </Link>
              <div
                onClick={(e) => handleOpenAssignItems(e, original.id)}
                className={classes.assign}
              >
                Assign items
              </div>
              <div
                onClick={(e) => handleEdit(e, original.id)}
                className={classes.change}
              >
                <Pencil />
                {/*navigate(`/admin/users/${original.id}`)*/}
              </div>
            </div>
          )
        }
      }
    ],
    [handleEdit]
  )

  const handleRemove = (e, id) => {
    e.stopPropagation()
  }

  const handleOpenAssignItems = async (e, id) => {
    const data = await getAssignedItemsToCategory(id)

    if (data) {
      setCategoryId(id)
      setItems(data)
      setSelectedItems(data?.filter((i) => i.categoryId).map((el) => el.id))
      setAssignItemsOpen(true)
    }
  }

  const handleAssignItems = () => {
    assignToCategory({id: categoryId, data: selectedItems})
    setSelectedItems([])
    setAssignItemsOpen(false)
  }

  const cancelAssignItems = () => {
    setSelectedItems([])
    setAssignItemsOpen(false)
  }

  const handleSelectItem = (el) => {
    setSelectedItems((i) =>
      i.includes(el) ? i.filter((si) => si !== el) : [...i, el]
    )
  }

  const handleRevertList = () => {
    setCategories(categoriesUpdateList)
  }

  const handleSaveDrugList = () => {
    setOpenDrug(false)
    const listOfIds = categories.map((c) => c.id)
    updateCategories(listOfIds)
  }
  const handleSearchAssignItem = (query) => {
    console.log(query)
    setSearchQuery(query)
  }

  const filteredItems = useMemo(() => {
    if (!searchQuery) {
      return items
    }
    return items.filter((item) =>
      item?.title?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
  }, [items, searchQuery])
  return (
    <AppLayout
      title="Categories"
      headComponent={
        <div className="head_component">
          {!openDrug && (
            <SaveButton text="Edit list" onClick={() => setOpenDrug(true)} />
          )}

          {openDrug && (
            <CancelButton text="Revert" onClick={handleRevertList} />
          )}

          {openDrug && (
            <SaveButton text="Save changes" onClick={handleSaveDrugList} />
          )}
        </div>
      }
    >
      <DraggableTable
        columns={columns}
        data={categories}
        setData={setCategories}
        accessDrag={openDrug}
      />

      {items && (
        <AssignItemsModal
          open={assignItemsOpen}
          data={filteredItems}
          selected={selectedItems}
          onSelect={handleSelectItem}
          onCancel={cancelAssignItems}
          onSubmit={handleAssignItems}
          setSearch={handleSearchAssignItem}
          subTitle={
            "The following list contains Items that are assigned to this category or items without a category. Use the select option to include the ones you wish to add to this category and unselect certain items to remove them from it."
          }
        />
      )}
    </AppLayout>
  )
})

export default Categories
