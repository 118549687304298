import {observer} from "mobx-react-lite"
import {useFormik} from "formik"
import {useNavigate, useParams} from "react-router-dom"
import {useEffect, useState} from "react"
import {toast} from "react-toastify"

import AppLayout from "layouts/App/AppLayout"
import {ingredientsSchema} from "libs/schemas"
import InputField from "components/InputField"
import {useStores} from "store"
import CustomSelect from "components/Select"
import classes from "./index.module.scss"
import {IngredientsTypeEnums} from "libs/enums"



const IngredientsCreateEdit = observer(() => {
  const [item, setItem] = useState(null)
  const [needToValidate, setNeedToValidate] = useState(false)

  const {
    ingredientsStore: {
      getIngredientGroups,
      updateIngredient,
      addIngredient,
      ingredientGroupsUpdateList,
      getIngredients,
      getIngredient
    }
  } = useStores()

  const navigate = useNavigate()

  const {id} = useParams()

  useEffect(() => {
    const getIngredientData = async () => {
      const data = await getIngredient(id)
      if (data) {
        setItem(data)
      }
    }

    id && getIngredientData()
  }, [getIngredient, id])

  useEffect(() => {
    getIngredientGroups()
  }, [getIngredientGroups])

  const getGroupType = (name) => {
    const item = ingredientGroupsUpdateList.find((c) => c === name)
    if (!item) {
      return null
    }
    return {label: item, value: item}
  }

  const getType = (name) => {
    const item = IngredientsTypeEnums.find((c) => c.value === name)
    if (!item) {
      return null
    }
    return {label: item.label, value: item.value}
  }

  const {
    dirty,
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: item?.name || "",
      type: item?.type ? getType(item.type) : "",
      groupType: item?.groupType ? getGroupType(item.groupType) : "",
      price: item?.price / 100 || 0
    },
    enableReinitialize: true,
    validationSchema: ingredientsSchema,
    validateOnChange: needToValidate,
    validateOnBlur: needToValidate,
    onSubmit: async (values) => {
      setNeedToValidate(true)

      try {
        const data = {
          name: values.name,
          type: values.type?.value,
          groupType: values.groupType?.value,
          price: values.price * 100
        }

        if (id) {
          return updateIngredient({id, data}).then((res) => {
            if (res) {
              toast.success("Ingredient successfully changed!")
              navigate("/menu/ingredients")
            }
          })
        }

        return addIngredient({data}).then((res) => {
          if (res) {
            toast.success("Ingredient successfully added!")
            navigate("/menu/ingredients")
          }
        })
      } catch (err) {
        console.log(err.response)
      }
    }
  })

  const handleRevert = () => {
    resetForm()
  }

  const handleLinkBack = () => getIngredients({})

  return (
    <AppLayout
      title={id ? item?.name : "Add ingredient"}
      Tag="form"
      linkBack="/menu/ingredients"
      onLinkBack={handleLinkBack}
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          <button
            className={classes.revert}
            type="button"
            onClick={handleRevert}
          >
            Revert changes
          </button>

          <button
            disabled={!isValid || !dirty}
            className={classes.submit}
            type="submit"
            onClick={() => setNeedToValidate(true)}
          >
            {id ? "Save" : "Create"}
          </button>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <InputField
            label="Set title"
            value={values?.name}
            name="name"
            onChange={handleChange}
            errors={errors}
            placeholder="Set title"
          />

          <CustomSelect
            label="Group type"
            name="groupType"
            options={[
              ...ingredientGroupsUpdateList.map((c) => ({
                label: c,
                value: c
              }))
            ]}
            value={values?.groupType}
            placeholder="Select group type"
            setFieldValue={setFieldValue}
            errors={errors}
          />

          <InputField
            label="Price"
            value={values?.price}
            name="price"
            type="price"
            placeholder="Item price"
            onChange={handleChange}
            errors={errors}
            withIcon
          />

          <CustomSelect
            label="Type"
            name="type"
            options={IngredientsTypeEnums}
            value={values?.type}
            errors={errors}
            placeholder="Select the use type"
            setParam="id"
            setFieldValue={setFieldValue}
          />
        </div>
      </div>
    </AppLayout>
  )
})

export default IngredientsCreateEdit
