import {Navigate} from "react-router-dom"
import {toast} from "react-toastify"

const ProtectedRoute = ({token, role, accessRoles, children}) => {
  if (!token) {
    return <Navigate to="/login" replace />
  }

  if (accessRoles && !accessRoles.includes(role)) {
    toast.error(
      `Protected route. Only users with roles ${accessRoles.map(
        (r) => r
      )} can access!`
    )
    return <Navigate to="/" replace />
  }

  return children
}

export default ProtectedRoute
