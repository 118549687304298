import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler // 1. Import Filler plugin
} from "chart.js";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler // 1. Register Filler plugin
);
export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        }
    },
    tension: 0.4 // 2. Set the tension (curvature) of the line to your liking.  (You may want to lower this a smidge.)
};
const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const data = {
    labels,
    datasets: [
        {
            label: '$',
            data: [334, 2000, 1000, 4111, 44, 4000, 3000],
            borderWidth: 0,
            // backgroundColor: "rgb(237, 78, 21)",
            fill: true,
            backgroundColor: ({chart: {ctx, canvas}}) => {
                ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2 - 2, 0, Math.PI * 2, false);
                const bg = ctx.createLinearGradient(canvas.width / 2, 0, canvas.width / 2, canvas.height);
                bg.addColorStop(0, '#ED4E15');
                bg.addColorStop(1, '#FF9F2400');
                return bg;
            }
        },
        {
            label: '$',
            data: [4000, 3000, 854, 2000,1000, 2000, 5000],
            borderWidth: 0,
            fill: true,
            backgroundColor: ({chart: {ctx, canvas}}) => {
                ctx.arc(canvas.width / 2, canvas.height / 2, canvas.width / 2 - 2, 0, Math.PI * 2, false);
                const bg = ctx.createLinearGradient(canvas.width / 2, 0, canvas.width / 2, canvas.height);
                bg.addColorStop(0, '#FFDB00');
                bg.addColorStop(1, '#FF9F2400');
                return bg;
            }
        }
    ]
};
const ChartTotal = () => {
    return(
        <div className='chart_item_wrapper'>
            <Line  id='total' options={options} data={data} />
        </div>
    )
}

export default ChartTotal