import {observer} from "mobx-react-lite"
import {useFormik} from "formik"
import {useNavigate, useParams} from "react-router-dom"
import {useEffect, useState} from "react"
import {toast} from "react-toastify"

import AppLayout from "../../layouts/App/AppLayout"
import {modifierSetsSchema} from "../../libs/schemas"
import InputField from "../../components/InputField"
import {useStores} from "../../store"
import CustomSelect from "../../components/Select"
import {getModifiersList} from "../../store/modifiers/api"
import classes from "./index.module.scss"

const ModifierSetsCreateEdit = observer(() => {
  const [item, setItem] = useState(null)
  const [withoutSet, setWithoutSet] = useState([])
  const {
    modifiersStore: {getModifierSet, updateModifierSet, addModifierSet}
  } = useStores()

  const navigate = useNavigate()

  const {id} = useParams()

  useEffect(() => {
    const getItemData = async () => {
      const itemData = await getModifierSet(id)
      if (itemData) {
        setItem(itemData)
      }
    }

    id && getItemData()
  }, [getModifierSet, id])

  useEffect(() => {
    async function fetchData() {
      const withoutSetMod = await getModifiersList({set: "null"})
      setWithoutSet(withoutSetMod)
    }
    fetchData()
  }, [])

  const {
    dirty,
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      name: item?.name || "",
      type: item?.type || "",
      modifierIds:
        item?.list?.map((m) => ({...m, label: m.title, value: m.id})) || []
    },
    enableReinitialize: true,
    validationSchema: modifierSetsSchema,
    onSubmit: async (values) => {
      try {
        const data = {
          name: values.name,
          type: values.type,
          ids: values.modifierIds.map((i) => i.id)
        }

        if (id) {
          return updateModifierSet({id, data}).then((res) => {
            if (res) {
              toast.success("Modifier set successfully changed!")
              navigate("/menu/modifier-sets")
            }
          })
        }

        return addModifierSet({data}).then((res) => {
          if (res) {
            toast.success("Modifier set successfully added!")
            navigate("/menu/modifier-sets")
          }
        })
      } catch (err) {
        console.log(err.response)
      }
    }
  })

  const handleRevert = () => {
    resetForm()
  }

  return (
    <AppLayout
      title={id ? item?.name : "Add modifier set"}
      Tag="form"
      linkBack="/menu/modifier-sets"
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          <button
            className={classes.revert}
            type="button"
            onClick={handleRevert}
          >
            Revert changes
          </button>

          <button
            disabled={!isValid || !dirty}
            className={classes.submit}
            type="submit"
          >
            {id ? "Save changes" : "Create"}
          </button>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <InputField
            label="Set name"
            value={values?.name}
            name="name"
            onChange={handleChange}
            errors={errors}
            placeholder="Set name"
          />

          <InputField
            label="Set type"
            value={values?.type}
            name="type"
            onChange={handleChange}
            errors={errors}
            placeholder="Set type"
          />

          <CustomSelect
            isMulti
            label="Modifiers"
            name="modifierIds"
            placeholder="Choose modifiers"
            values={values?.modifierIds}
            setFieldValue={setFieldValue}
            options={[
              ...(item?.list?.map((m) => ({
                ...m,
                label: m.title,
                value: m.id
              })) || []),
              ...withoutSet.map((m) => ({
                ...m,
                label: m.title,
                value: m.id
              }))
            ]}
          />
        </div>
      </div>
    </AppLayout>
  )
})

export default ModifierSetsCreateEdit
