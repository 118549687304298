import {useRoutes, Navigate} from "react-router-dom"
import jwtDecode from "jwt-decode"

import Login from "./pages/Login"
import LoginPage from "./pages/LoginPage"
import ConfirmLogin from "./pages/ConfirmLogin"
import Dashboard from "./pages/Dashboard"
import Orders from "./pages/Orders"
import OrderDetails from "./pages/OrderDetails"
import Customers from "./pages/Customers"
import CustomersDetails from "./pages/CustomersDetails"
import EmployeeHours from "./pages/EmployeeHours"
import ItemsSold from "./pages/ItemsSold"
import Invitations from "./pages/Invitations"
import InvitationsCreateEdit from "./pages/InvitationsCreateEdit"
import Users from "./pages/Users"
import UsersCreateEdit from "./pages/UsersCreateEdit"
import Menu from "./pages/Menu"
import MenuCreateEdit from "./pages/MenuCreateEdit"
import TimeSheetManagement from "./pages/TimeSheetManagement"
import ShiftSchedule from "./pages/ShiftSchedule"
import Categories from "./pages/Categories"
import CategoriesCreateEdit from "./pages/CategoriesCreateEdit"
import Modifiers from "./pages/Modifiers"
import ModifierSets from "./pages/ModifierSets"
import ModifierSetsCreateEdit from "./pages/ModifierSetsCreateEdit"
import ModifiersCreateEdit from "./pages/ModifiersCreateEdit"
import NotFound from "./pages/NotFound"
import Coupons from "./pages/Coupons"
import CouponTemplates from "./pages/CouponTemplates"
import SpecialCoupons from "./pages/SpecialCoupons"
import ProtectedRoute from "./components/ProtectedRoute"
import {useStores} from "./store"
import PersonalCoupons from "./pages/PersonalCoupons"
import BirsdayGift from "./pages/BirthdayGift"
import FirstRegistration from "./pages/FirstRegistration"
import WheelOfFortune from "./pages/WheelOfFortune"
import RegularCoupons from "./pages/RegularCoupons"
import NotificationsSms from "./pages/NotificationsSms"
import NotificationsPush from "./pages/NotificationsPush"
import Ingredients from "./pages/Ingredients"
import IngredientsCreateEdit from "./pages/IngredientsCreateEdit"
import WorkingHours from "./pages/WorkingHours"

const RouterApp = () => {
  const {
    authStore: {accessToken}
  } = useStores()

  const token = accessToken || localStorage.getItem("token")
  let role = null

  if (token) {
    role = jwtDecode(token)?.role
  }

  let routes = useRoutes([
    {path: "/", element: <Login />},
    {path: "/login", element: <LoginPage />},
    {path: "/recover", element: <LoginPage />},
    {path: "/invitation", element: <LoginPage />},
    {path: "/confirm", element: <ConfirmLogin />},
    {path: "/dashboard", element: <Dashboard />},
    {path: "/customers", element: <Customers />},
    {path: "/customers/:id", element: <CustomersDetails />},

    {path: "/settings", element: <Dashboard />},
    {path: "/orders", element: <Orders />},
    {path: "/orders/:id", element: <OrderDetails />},

    {path: "/reports/items-sold", element: <ItemsSold />},
    {path: "/reports/employee-hours", element: <EmployeeHours />},

    {
      path: "/coupons/generator",
      element: <Navigate to="/coupons/generator/special/add" />
    },
    {
      path: "/notifications",
      element: <Navigate to="/notifications/sms" />
    },

    {path: "/admin/roles", element: <Dashboard />},

    //Protected
    {
      path: "/menu/items",
      element: (
        <ProtectedRoute token={token}>
          <Menu />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/items/add",
      element: (
        <ProtectedRoute token={token}>
          <MenuCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/items/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <MenuCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/admin/users",
      element: (
        <ProtectedRoute token={token}>
          <Users />
        </ProtectedRoute>
      )
    },
    {
      path: "/admin/users/add",
      element: (
        <ProtectedRoute token={token}>
          <UsersCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/admin/users/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <UsersCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/admin/invitations",
      element: (
        <ProtectedRoute token={token} accessRoles={["SUPER_ADMIN"]} role={role}>
          <Invitations />
        </ProtectedRoute>
      )
    },
    {
      path: "/admin/invitations/add",
      element: (
        <ProtectedRoute token={token}>
          <InvitationsCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/admin/invitations/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <InvitationsCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/categories",
      element: (
        <ProtectedRoute token={token}>
          <Categories />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/categories/add",
      element: (
        <ProtectedRoute token={token}>
          <CategoriesCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/categories/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <CategoriesCreateEdit />
        </ProtectedRoute>
      )
    },

    {
      path: "/menu/modifier-sets",
      element: (
        <ProtectedRoute token={token}>
          <ModifierSets />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/modifier-sets/add",
      element: (
        <ProtectedRoute token={token}>
          <ModifierSetsCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/modifier-sets/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <ModifierSetsCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/modifiers",
      element: (
        <ProtectedRoute token={token}>
          <Modifiers />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/modifiers/add",
      element: (
        <ProtectedRoute token={token}>
          <ModifiersCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/modifiers/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <ModifiersCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/ingredients",
      element: (
        <ProtectedRoute token={token}>
          <Ingredients />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/ingredients/add",
      element: (
        <ProtectedRoute token={token}>
          <IngredientsCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/menu/ingredients/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <IngredientsCreateEdit />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/all",
      element: (
        <ProtectedRoute token={token}>
          <Coupons />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/templates",
      element: (
        <ProtectedRoute token={token}>
          <CouponTemplates />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/generator/special/add",
      element: (
        <ProtectedRoute token={token}>
          <SpecialCoupons />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/generator/special/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <SpecialCoupons />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/generator/personal/add",
      element: (
        <ProtectedRoute token={token}>
          <PersonalCoupons />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/generator/personal/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <PersonalCoupons />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/generator/regular/add",
      element: (
        <ProtectedRoute token={token}>
          <RegularCoupons />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/generator/regular/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <RegularCoupons />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/generator/birthday/add",
      element: (
        <ProtectedRoute token={token}>
          <BirsdayGift />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/generator/birthday/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <BirsdayGift />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/wheel",
      element: (
        <ProtectedRoute token={token}>
          <WheelOfFortune />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/generator/registration/add",
      element: (
        <ProtectedRoute token={token}>
          <FirstRegistration />
        </ProtectedRoute>
      )
    },
    {
      path: "/coupons/generator/registration/update/:id",
      element: (
        <ProtectedRoute token={token}>
          <FirstRegistration />
        </ProtectedRoute>
      )
    },
    {
      path: "notifications/sms",
      element: (
        <ProtectedRoute token={token}>
          <NotificationsSms />
        </ProtectedRoute>
      )
    },
    {
      path: "notifications/push",
      element: (
        <ProtectedRoute token={token}>
          <NotificationsPush />
        </ProtectedRoute>
      )
    },
    {path: "/admin/timesheet", element: <TimeSheetManagement />},
    {path: "/admin/shift-schedule", element: <ShiftSchedule />},
    {path: "/admin/working-hours", element: <WorkingHours />},

    {path: "/*", element: <NotFound />}
  ])
  return routes
}

export default RouterApp
