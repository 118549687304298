import {useFormik} from "formik"
import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {toast} from "react-toastify"

import InputField from "../../components/InputField"
import CustomSelect from "../../components/Select"
import AppLayout from "../../layouts/App/AppLayout"
import {regularSchema} from "../../libs/schemas"
import {useStores} from "../../store"
import Radio from "../../components/Radio"
import copy from "../../assets/images/actions/copy.svg"
import send from "../../assets/images/actions/send.svg"
import classes from "./index.module.scss"

const RegularCoupons = () => {
  const [code, setCode] = useState("")
  const [needToValidate, setNeedToValidate] = useState(false)

  const {
    categoriesStore: {getCategories, categoriesUpdateList},
    couponStore: {addCoupon}
  } = useStores()

  useEffect(() => {
    getCategories()
  }, [getCategories])

  const {
    dirty,
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue
  } = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: regularSchema,
    validateOnChange: needToValidate,
    validateOnBlur: needToValidate,
    onSubmit: async (values) => {
      setNeedToValidate(true)
      try {
        let data = {
          description: values.description,
          lifespan: values.lifespan,
          discountType: values.discountType,
          recurrence: values.recurrence,
          categoryId: values.category?.value
        }

        if (values.value) {
          data.value = values.value
        }

        const res = await addCoupon({subtype: "regular", data})
        if (res) {
          setCode(res.code)
          toast.success("Regular coupon successfully added!")
        }
      } catch (err) {
        console.log(err.response)
      }
    }
  })

  const handleCopy = () => {
    navigator.clipboard.writeText(code)
    toast.success(`Code ${code} successfully copied!`)
  }

  return (
    <AppLayout
      title="Generator"
      Tag="form"
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          <button
            disabled={!isValid || !dirty}
            className={classes.submit}
            type="submit"
            onClick={() => setNeedToValidate(true)}
          >
            Submit
          </button>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.links}>
          <Link to={"/coupons/generator/special/add"}>Special</Link>
          <Link to={"/coupons/generator/personal/add"}>Personal</Link>
          <button>Regular</button>
          <Link to={"/coupons/generator/birthday/add"}>Birthday</Link>
          <Link to={"/coupons/generator/registration/add"}>Register</Link>
        </div>

        <div className={classes.content}>
          <div className={classes.leftPart}>
            <InputField
              label="Description"
              value={values?.description}
              name="description"
              onChange={handleChange}
              errors={errors}
              placeholder="Set promocode description"
            />

            <InputField
              label="Lifespan (days)"
              value={values?.lifespan}
              name="lifespan"
              type="number"
              onChange={handleChange}
              errors={errors}
              placeholder="Set lifespan"
            />

            {code && (
              <div className={classes.codeBlock}>
                <span className={classes.code}>{code}</span>
                <div className={classes.actions}>
                  <div className={classes.action} onClick={handleCopy}>
                    <span>Copy</span>
                    <img src={copy} alt="copy" />
                  </div>
                  <Link
                    to="/notifications/sms"
                    className={classes.action}
                    state={{code}}
                  >
                    <span>Send</span>
                    <img src={send} alt="send" />
                  </Link>
                </div>
              </div>
            )}
          </div>

          <div className={classes.rightPart}>
            <div className={classes.rightTopPart}>
              <Radio
                label="Discount type"
                values={[
                  {value: "amount", label: "Amount"},
                  {value: "percentage", label: "Percentage"},
                  {value: "item", label: "Item"}
                ]}
                errors={errors}
                value={values?.discountType}
                name="discountType"
                handleChange={handleChange}
              />

              <InputField
                label="Discount amount"
                value={values?.value}
                name="value"
                onChange={handleChange}
                errors={errors}
                disabled={
                  !["amount", "percentage"].includes(values?.discountType)
                }
                withIcon
                placeholder="Set discount amount"
                type={
                  values?.discountType === "amount"
                    ? "discountAbs"
                    : values?.discountType === "percentage"
                    ? "discountPer"
                    : null
                }
              />

              <CustomSelect
                label="Category"
                name="category"
                options={[
                  {value: undefined, label: "ALL"},
                  ...categoriesUpdateList.map((c) => ({
                    ...c,
                    label: c.description,
                    value: c.id
                  }))
                ]}
                errors={errors}
                values={values?.category}
                placeholder="Category selector"
                setParam="id"
                setFieldValue={setFieldValue}
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default RegularCoupons
