import AppLayout from "../../layouts/App/AppLayout";
import {useEffect, useState, useMemo} from "react";
import axios from "axios";
import Table from "../../components/Table";
import BtnExport from "../../components/BtnExport";
import './index.scss'
import moment from "moment";

const EmployeeHours = () => {
    const [data, setData] = useState([])
    const columns = useMemo(() => [
            {
                Header: " ",
                columns: [
                    {
                        Header: "Name",
                        accessor: "name",
                        Cell: ({cell: {value}}) => value ? value : "-"
                    },
                    {
                        Header: "Role",
                        accessor: "role",
                        Cell: ({cell: {value}}) => value ? value : "-"
                    },
                    {
                        Header: "Date",
                        accessor: "date",
                        Cell: ({cell: {value}}) => value ? value : "-"
                    },
                    {
                        Header: "Clock-In",
                        accessor: "clock_in",
                        Cell: ({cell: {value}}) => value ? value : "-"
                    },
                    {
                        Header: "Clock-out",
                        accessor: "clock_out",
                        Cell: ({cell: {value}}) => value ? value : "-"
                    },
                    {
                        Header: "Duration",
                        accessor: "duration",
                        Cell: ({cell: {value}}) => value ? value : "-"
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        disableSortBy: true,
                        Cell: ({row:{original}}) => {
                            return(
                                <div className='employee_status'>
                                    {original.status}
                                </div>
                            )
                        }
                    },
                ]
            },
        ],
        []
    );
    const get = async () => {
        const {data} = await axios.get('https://jsonplaceholder.typicode.com/users')
        const newData = data?.map(({name, id,}) => {
            let currDate = moment().format("l")
            const d = moment().format("LTS")
            const  arr = ["Manager", "Chef", "Delivery",];
            const  status = ["Working", "Vacation", 'Sick Leave'];
            return {
                name: name,
                role: arr[Math.floor(Math.random() * arr.length)],
                date: currDate,
                clock_in: d,
                clock_out: d,
                duration: d,
                status: status[Math.floor(Math.random() * status.length)]
            }
        })
        setData(newData)
    }
    useEffect(() => {
        get()
    }, [])
    return(
        <AppLayout title='Employee Hours'  >
            <Table
                columns={columns}
                data={data}
                isCheckedRow={false}
                exportButton={<BtnExport title='Excel Sheet' />}
            />
        </AppLayout>
    )
}

export default EmployeeHours