import classes from "./index.module.scss"

const CancelButton = ({onClick, text = "Cancel", type = "button"}) => {
  return (
    <button onClick={onClick} className={classes.container} type={type}>
      {text}
    </button>
  )
}

export default CancelButton
