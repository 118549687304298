import {makeAutoObservable} from "mobx"
import {
  getModifiersList,
  getModifierSetsList,
  getModifier,
  addToModifiersList,
  updateModifier,
  getModifierSet,
  updateModifierSet,
  addModifierSet,
  getItemsWithModSet,
  addIngredients
} from "./api"

export class ModifiersStore {
  data = []
  setsData = []
  isLoading = false

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }

  //modifiers

  async getModifiers({search, item, set}) {
    this.isLoading = true
    this.data = await getModifiersList({search, item, set})
    this.isLoading = false
  }

  get modifiersUpdateList() {
    return this.data
  }

  async getModifier(id) {
    this.isLoading = true
    const item = await getModifier(id)
    this.isLoading = false
    return item
  }

  async addModifier({data}) {
    this.isLoading = true
    const item = await addToModifiersList({data})
    if (item) {
      this.data = await getModifiersList({})
    }
    this.isLoading = false
    return item
  }

  async updateModifier({id, data}) {
    this.isLoading = true
    const item = await updateModifier({id, data})
    if (item) {
      this.data = await getModifiersList({})
    }
    this.isLoading = false
    return item
  }

  async getItemsWithModSet({id}) {
    this.isLoading = true
    const data = await getItemsWithModSet({id})
    this.isLoading = false
    return data.items
  }

  async addIngredients({id, data}) {
    this.isLoading = true
    const res = await addIngredients({id, data})
    this.isLoading = false
    return res
  }

  //modifier sets

  async getModifierSets() {
    this.isLoading = true
    this.setsData = await getModifierSetsList()
    this.isLoading = false
  }

  get modifierSetsUpdateList() {
    return this.setsData
  }

  async getModifierSet(id) {
    this.isLoading = true
    const item = await getModifierSet(id)
    this.isLoading = false
    return item
  }

  async addModifierSet({id, data}) {
    this.isLoading = true
    const item = await addModifierSet({data})
    if (item) {
      this.setsData = await getModifierSetsList()
    }
    this.isLoading = false
    return item
  }

  async updateModifierSet({id, data}) {
    this.isLoading = true
    const item = await updateModifierSet({id, data})
    if (item) {
      this.setsData = await getModifierSetsList()
    }
    this.isLoading = false
    return item
  }
}

export default ModifiersStore
