import {useRef, useState} from 'react'
import './index.scss';


const SMSCodeField = () => {
    const [valueInput, setValue] = useState({
        value: ''
    })
    const [focused, setFocused] = useState(false)
    const inputRef = useRef()
    const CODE_LENGTH = new Array(4).fill(0);
    const values = valueInput?.value?.split("");

    const handleClick = () => {
        inputRef.current.focus();
    };
    const handleFocus = () => {
        setFocused(true)
    };
    const handleBlur = () => {
        setFocused(false)
    };
    const handleChange = (e) => {
        const value = e.target.value;
            setValue(prevState => {
                if (prevState.value?.length >= CODE_LENGTH.length) setFocused(false);
                return {
                    value: (prevState.value + value).slice(0, CODE_LENGTH.length),
                };
            });

    };

    const handleKeyUp = (e) => {
        if (e.key === "Backspace") {
            setValue((state) => {
                return {
                    value: state.value.slice(0, state.value.length - 1),
                };
            });
        }
    };

    const selectedIndex =
        values?.length < CODE_LENGTH.length ? values?.length : CODE_LENGTH.length - 1;
    const hideInput = !(values?.length < CODE_LENGTH.length);
    return (
        <div>
            <div className="sms_wrap" onClick={handleClick}>
                <input
                    onKeyUp={handleKeyUp}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value=""
                    ref={inputRef}
                    className="input"
                    style={{
                        width: "54px",
                        top: "0px",
                        bottom: "0px",
                        left: `${selectedIndex * 64}px`,
                        opacity: hideInput ? 0 : 1,
                    }}
                />
                {CODE_LENGTH.map((v, index) => {
                    const selected = values?.length === index;
                    const filled =
                        values?.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;
                    return (
                        <div className="display" key={v+index}>
                            {values?.length ? values[index] : null}
                            {(selected || filled) && focused && <div className="shadows"/>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SMSCodeField