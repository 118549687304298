import './index.scss';
import {useState, useRef, useEffect} from "react";
import { v4 as uuid } from "uuid";
import {ReactComponent  as Arrow} from '../../assets/images/down-arrow.svg';


const DropDown = ({style, styleDrop, dropList}) => {
    const wrapperRef = useRef(null);
    const [dropOpen, setDropOpen] = useState(false)
    const [selected, setSelected] = useState(dropList.at(0))
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setDropOpen(false)
        }
    }

    useEffect(() => {

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    },[wrapperRef])

    return(
        <div ref={wrapperRef} className='drop_container' onClick={() => setDropOpen(!dropOpen)}>
            <div style={style} className={`drop_head ${dropOpen ? 'head_open' : ''}`}>
                {selected.value ? selected.name : ''}
                <Arrow />
            </div>
            {dropOpen &&
                <div className="drop_content" style={styleDrop}>
                    {dropList.map(({name, value}) => {
                        const s = selected.value === value
                        return(
                            <div key={uuid()} className={`drop_item ${s ? 'selected' : '' }`} onClick={() => setSelected({name: name, value: value})}>
                                {name}
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default DropDown