import AuthLayout from "../../layouts/Auth/AuthLayout";
import SMSCodeField from "../../components/SMSCodeField";
import React, { useRef, useState, useEffect } from "react";
import './index.scss';


const ConfirmLogin = () => {
    const [timerMinutes, setTimerMinutes] = useState("00");
    const [timerSeconds, setTimerSeconds] = useState("00");
    const [timeOut, setTimeOut] = useState(false)
    let interval = useRef();

    const startTimer = (countdownDate) => {
        const now = new Date().getTime();
        const distance = countdownDate - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        const seconds2 = Math.floor((distance % (1000 * 60)) / 1000)
        if (distance < 0) {
            setTimeOut(true)
            clearInterval(interval.current);
        } else {
            setTimerMinutes(minutes);
            setTimerSeconds(seconds);
        }
    };

    function saveInLocalStorage(time) {
        localStorage.setItem("timer", time);
    }

    function getTimeFromLocalStorage() {
        return localStorage.getItem("timer");
    }

    useEffect(() => {
        const localTimer = getTimeFromLocalStorage();

        if (localTimer) {
            interval.current = setInterval(() => {
                startTimer(+localTimer);
            }, 1000);
        } else {
            const countdownDate = new Date().getTime() + 180000;
            saveInLocalStorage(countdownDate);
            interval.current = setInterval(() => {
                startTimer(+countdownDate);
            }, 1000);
        }

        return () => clearInterval(interval.current);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return(
            <AuthLayout
                title='Enter Code'
                subTitle='Code has been send to mobile number '
                authDetail='9256841569'
            >
                    <form onSubmit={handleSubmit} className='confirm_form'>
                        <SMSCodeField />
                        {timeOut ?
                            <div className='time-item'>
                                Did not receive Code?
                                <span className="time">Resend Code</span>
                            </div>
                            :
                            <div className='time-item'>

                                Resend Code in
                                <span className="time">
                                    {timerMinutes}
                                        :
                                    {timerSeconds}
                            </span>
                            </div>
                        }

                        <input  type='submit' value='SUBMIT' className='admin_btn submit_confirm'/>
                    </form>
            </AuthLayout>
    )
}

export default ConfirmLogin