import {fetchAxios} from "../axios"

export const addToMenuList = async ({data}) => {
  try {
    const res = await fetchAxios({
      url: "/items",
      method: "POST",
      data,
      headers: {
        "Content-Type": "multipart/form-data; "
      }
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateMenuList = async ({categoryId, ids}) => {
  try {
    const res = await fetchAxios({
      url: "/items",
      method: "PUT",
      data: {categoryId, ids}
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getMenuList = async ({category, search}) => {
  try {
    const url =
      category && search
        ? `/items?search=${search}&category=${category}`
        : category
        ? `/items?category=${category}`
        : search
        ? `/items?search=${search}`
        : "/items"

    const res = await fetchAxios({
      url
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateItem = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/items/${id}`,
      method: "PUT",
      data,
      headers: {
        "Content-Type": "multipart/form-data; "
      }
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateItemVisibility = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/items/${id}`,
      method: "PATCH",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getItem = async (id) => {
  try {
    const res = await fetchAxios({
      url: `/items/${id}`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

//variations
export const addVariation = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/items/${id}/variations`,
      method: "POST",
      data,
      headers: {
        "Content-Type": "multipart/form-data; "
      }
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateVariation = async ({id, data, varId}) => {
  try {
    const res = await fetchAxios({
      url: `/items/${id}/variations/${varId}`,
      method: "PUT",
      data,
      headers: {
        "Content-Type": "multipart/form-data; "
      }
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const deleteVariation = async ({id, varId}) => {
  try {
    const res = await fetchAxios({
      url: `/items/${id}/variations/${varId}`,
      method: "DELETE"
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

//modifiers
export const addModifierSet = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/items/${id}/modifier-set`,
      method: "POST",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateModifierSet = async ({id, data, setId}) => {
  try {
    const res = await fetchAxios({
      url: `/items/${id}/modifier-set/${setId}`,
      method: "PUT",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const deleteModifierSet = async ({id, setId}) => {
  try {
    const res = await fetchAxios({
      url: `/items/${id}/modifier-set/${setId}`,
      method: "DELETE"
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const addIngredients = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/items/${id}/ingredients`,
      method: "POST",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}
