import {observer} from "mobx-react-lite"
import React, {useState} from "react"
import {toast} from "react-toastify"
import {useFormik} from "formik"
import {Link, useLocation} from "react-router-dom"

import AppLayout from "../../layouts/App/AppLayout"
import {notificationSmsSchema} from "../../libs/schemas"
import InputField from "../../components/InputField"
import {useStores} from "../../store"
import classes from "./index.module.scss"

const NotificationsSms = observer(() => {
  const [needToValidate, setNeedToValidate] = useState(false)

  const {
    notificationStore: {addSms}
  } = useStores()

  const {state} = useLocation()

  const {dirty, isValid, values, handleChange, handleSubmit, errors} =
    useFormik({
      initialValues: {
        code: state?.code || ""
      },
      enableReinitialize: true,
      validateOnChange: needToValidate,
      validateOnBlur: needToValidate,
      validationSchema: notificationSmsSchema,
      onSubmit: async (values) => {
        setNeedToValidate(true)

        try {
          let data = {
            phone: values.phone,
            message: values.message,
            code: values.code
          }

          const res = await addSms({data})

          if (res) {
            toast.success("Sms successfully sent!")
          }
        } catch (err) {
          console.log(err.response)
        }
      }
    })

  return (
    <AppLayout
      title="Notifications"
      Tag="form"
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          <button
            disabled={!isValid || !dirty}
            className={classes.submit}
            type="submit"
            onClick={() => setNeedToValidate(true)}
          >
            Submit
          </button>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.links}>
          <button>SMS</button>
          <Link to={"/notifications/push"} state={{code: state?.code}}>
            Push
          </Link>
        </div>

        <div className={classes.content}>
          <InputField
            label="Phone number"
            value={values?.phone}
            name="phone"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter phone number"
            type="number"
          />

          <InputField
            label="Coupon code"
            value={values?.code}
            name="code"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter coupon code"
          />

          <InputField
            label="SMS Text"
            value={values?.message}
            name="message"
            onChange={handleChange}
            errors={errors}
            placeholder="Enter Messasge Text"
            type="textarea"
          />
        </div>
      </div>
    </AppLayout>
  )
})

export default NotificationsSms
