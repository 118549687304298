import React from "react"
import {ReactComponent as Check} from "../../assets/images/check.svg"
import c from "./index.module.scss"

const Checkbox = ({id, label, onChange, name = "", selected}) => {
  return (
    <label
      id={`${label}-${id}`}
      className={`${c.selected_checkbox} ${selected ? c.checked : ""}`}
    >
      <div className={c.check_view}>
        <input
          id={`${label}-${id}`}
          value={id}
          name={name}
          onChange={onChange}
          type="checkbox"
          checked={selected}
        />
        <Check />
      </div>
      {label}
    </label>
  )
}

export default Checkbox
