import {makeAutoObservable, toJS} from "mobx"
import {
  deleteIngredient,
  getIngredientsList,
  addToIngredientsList,
  updateIngredient,
  getIngredientGroups,
  getIngredient, getIngredientFilters, getIngredientByFilters
} from "./api"

export class IngredientsStore {
  data = null
  groups = []
  isLoading = false
  filters = []
  updatedFilters = []
  pagination = null
  selectedModifiers = []
  currentPage = 1
  limit = 100

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }



  // Toggle value in selection
  setSelectedModifiers(value) {
    if (this.selectedModifiers.includes(value)) {
      this.selectedModifiers = this.selectedModifiers.filter(item => item !== value);
    } else {
      this.selectedModifiers.push(value);
    }
  }

  setNewPage(value){
    this.currentPage = value
  }
  setNewLimit(value){
    this.limit = value
  }

  setUpdatedFilters (value) {
    this.updatedFilters = value
  }





  get modifiersList() {
    return this.selectedModifiers
  }

  async getIngredients({search, item, modifier}) {
    this.isLoading = true
    this.data = await getIngredientsList({search, item, modifier})
    this.isLoading = false
  }

  async getFilters (type){
    const filters = await getIngredientFilters(type)
   this.filters =  filters
   this.updatedFilters =  filters
  }

  async getIngredientsWithFilters(data){
    this.isLoading = true
    const res = await  getIngredientByFilters(data)
    this.isLoading = false
    if(Array.isArray(res)){
      this.pagination = null
      return  this.data = res
    }
    this.data = res.items
    this.pagination = res.meta
    this.currentPage = res.meta.currentPage
    this.limit = res.meta.itemsPerPage
  }

  get ingredientsUpdateList() {
    return this.data
  }

  async removeIngredient(id) {
    this.isLoading = true
    const res = await deleteIngredient({id})
    this.isLoading = false
    return res
  }

  async getIngredient(id) {
    this.isLoading = true
    const item = await getIngredient(id)
    this.isLoading = false
    return item
  }

  async addIngredient({data}) {
    this.isLoading = true
    const item = await addToIngredientsList({data})
    if (item) {
      this.data = await getIngredientsList({})
    }
    this.isLoading = false
    return item
  }

  async updateIngredient({id, data}) {
    this.isLoading = true
    const item = await updateIngredient({id, data})
    if (item) {
      this.data = await getIngredientsList({})
    }
    this.isLoading = false
    return item
  }

  async getIngredientGroups() {
    this.isLoading = true
    this.groups = await getIngredientGroups()
    this.isLoading = false
  }

  get ingredientGroupsUpdateList() {
    return this.groups
  }
}

export default IngredientsStore
