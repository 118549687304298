import {observer} from "mobx-react-lite"
import {useFormik} from "formik"
import {useNavigate, useParams} from "react-router-dom"
import {useEffect, useRef, useState} from "react"
import {toast} from "react-toastify"

import AppLayout from "layouts/App/AppLayout"
import {modifiersSchema} from "libs/schemas"
import InputField from "components/InputField"
import {useStores} from "store"
import UploadInput from "components/UploadInput"
import CustomSelect from "components/Select"
import classes from "./index.module.scss"

const ModifierSetsCreateEdit = observer(() => {
  const [item, setItem] = useState(null)

  const {
    modifiersStore: {
      getModifierSets,
      getModifier,
      getModifiers,
      modifierSetsUpdateList,
      addModifier,
      updateModifier,
      addIngredients
    },
    ingredientsStore: {getIngredients, ingredientsUpdateList}
  } = useStores()

  const navigate = useNavigate()

  const {id} = useParams()
  const uploadingFileInputRefs = useRef([])

  useEffect(() => {
    const getModifierData = async () => {
      const modifierData = await getModifier(id)
      if (modifierData) {
        setItem(modifierData)
      }
    }

    id && getModifierData()
  }, [getModifier, id])


  useEffect(() => {
    getModifierSets()
  }, [getModifierSets])

  useEffect(() => {
    getIngredients({})
  }, [getIngredients])

  const getModifierSet = (id) => {
    const item = modifierSetsUpdateList.find((c) => c.id === id)
    if (!item) {
      return null
    }
    return {label: item.name, value: item.id}
  }

  const {
    dirty,
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      title: item?.title || "",
      description: item?.description || "",
      type: item?.type || "",
      price: item?.price / 100 || 0,
      modifierSet: item?.modifierId
        ? getModifierSet(item.modifierId)
        : undefined,
      addons:
        item?.ingredients
          .filter((ing) => ing.type === "addons")
          .map((i) => ({...i, label: i.name, value: i.id})) || [],
      removes:
        item?.ingredients
          .filter((ing) => ing.type === "removes")
          .map((i) => ({...i, label: i.name, value: i.id})) || [],
      icon: "",
      image: "",
      iconPreview: item?.preview || "",
      imagePreview: item?.image || ""
    },
    enableReinitialize: true,
    validationSchema: modifiersSchema,
    onSubmit: async (values) => {
      try {
        const textData = {
          title: values.title,
          description: values.description,
          type: values.type
        }

        const data = new FormData()

        for (const key in textData) {
          data.append(key, values[key])
        }

        if (values.price) {
          data.append("price", values.price * 100)
        }

        if (values.modifierSet?.value) {
          data.append("modifierId", values.modifierSet.value)
        }

        if (values.image) {
          data.append("image", values.image)
        }

        if (values.icon) {
          data.append("preview", values.icon)
        }

        if (id) {
          return updateModifier({id, data}).then((res) => {
            if (res) {
              toast.success("Modifier successfully changed!")

              if (!!values.addons?.length || !!values.removes?.length) {
                return addIngredients({
                  id: res.id,
                  data: {
                    ids: [
                      ...values.addons.map((i) => i.id),
                      ...values.removes.map((i) => i.id)
                    ]
                  }
                }).then((r) => {
                  if (r) {
                    return navigate("/menu/modifiers")
                  }
                })
              }

              navigate("/menu/modifiers")
            }
          })
        }

        return addModifier({data}).then((res) => {
          if (res) {
            toast.success("Modifier successfully added!")

            if (!!values.addons?.length || !!values.removes?.length) {
              return addIngredients({
                id: res.id,
                data: {
                  ids: [
                    ...values.addons.map((i) => i.id),
                    ...values.removes.map((i) => i.id)
                  ]
                }
              }).then((r) => {
                if (r) {
                  return navigate("/menu/modifiers")
                }
              })
            }

            navigate("/menu/modifiers")
          }
        })
      } catch (err) {
        console.log(err.response)
      }
    }
  })

  const fileInputHandler = (event, name, preview) => {
    if(name === 'delete'){
      setFieldValue(preview, '')
      return;
    }
    if (!event.currentTarget.files[0]) {
      return
    }
    setFieldValue(name, event.currentTarget.files[0])
    if (preview) {
      setFieldValue(preview, URL.createObjectURL(event.currentTarget.files[0]))
    }
    uploadingFileInputRefs.current = [
      ...uploadingFileInputRefs.current,
      event.currentTarget
    ]
  }

  const handleRevert = () => {
    resetForm()
  }

  const handleLinkBack = () => getModifiers({})

  return (
    <AppLayout
      title={id ? item?.title : "Add modifier"}
      Tag="form"
      linkBack="/menu/modifiers"
      onLinkBack={handleLinkBack}
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          <button
            className={classes.revert}
            type="button"
            onClick={handleRevert}
          >
            Revert changes
          </button>

          <button
            disabled={!isValid || !dirty}
            className={classes.submit}
            type="submit"
          >
            {id ? "Save" : "Create"}
          </button>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.leftPart}>
            <InputField
              label="Set title"
              value={values?.title}
              name="title"
              onChange={handleChange}
              errors={errors}
              placeholder="Set title"
            />

            <InputField
              label="Description"
              value={values?.description}
              name="description"
              type="textarea"
              onChange={handleChange}
              errors={errors}
              placeholder="Modifier description"
            />

            <div className={classes.imageBlock}>
              <UploadInput
                label="Preview"
                name="icon"
                preview="iconPreview"
                imageSrc={values.iconPreview}
                errors={errors}
                fileInputHandler={fileInputHandler}
                size="middle"
              />

              <UploadInput
                label="Image"
                name="image"
                preview="imagePreview"
                imageSrc={values.imagePreview}
                errors={errors}
                fileInputHandler={fileInputHandler}
                size="middle"
              />
            </div>

            <CustomSelect
              label="Modifier set"
              name="modifierSet"
              options={[
                {value: undefined, label: "-"},
                ...modifierSetsUpdateList.map((c) => ({
                  ...c,
                  label: c.name,
                  value: c.id
                }))
              ]}
              value={values?.modifierSet}
              placeholder="Modifier set selector"
              setParam="id"
              setFieldValue={setFieldValue}
            />

            <InputField
              label="Type"
              value={values?.type}
              name="type"
              onChange={handleChange}
              errors={errors}
              placeholder="Modifier type"
            />

            <InputField
              label="Price"
              value={values?.price}
              name="price"
              type="price"
              placeholder="Item price"
              onChange={handleChange}
              errors={errors}
              withIcon
            />
          </div>
          {ingredientsUpdateList &&
            <div className={classes.rightPart}>
              <span className={classes.ingredientsTitle}>Addons&Removes</span>

              <CustomSelect
                isMulti
                label="Addons ingredients"
                name="addons"
                placeholder="Choose addons"
                values={values?.addons}
                setFieldValue={setFieldValue}
                options={[
                  ...ingredientsUpdateList
                    ?.filter((i) => i.type === "addons" || i.type === "addons_puzzle_pizza")
                    .map((c) => ({
                      ...c,
                      label: c.name,
                      value: c.id
                    }))
                ]}
              />

              <CustomSelect
                isMulti
                label="Removes ingredients"
                name="removes"
                placeholder="Choose removes"
                values={values?.removes}
                setFieldValue={setFieldValue}
                options={[
                  ...ingredientsUpdateList
                    ?.filter((i) => i.type === "removes")
                    .map((c) => ({
                      ...c,
                      label: c.name,
                      value: c.id
                    }))
                ]}
              />
            </div>
          }

        </div>
      </div>
    </AppLayout>
  )
})

export default ModifierSetsCreateEdit
