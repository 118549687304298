import {makeAutoObservable} from "mobx"
import {
  fetchForgotPassword,
  fetchGetRecover,
  fetchLogin,
  fetchPostRecover
} from "./api"

export class AuthStore {
  isLoading = false
  error = null
  accessToken = null

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }

  setAuth({token}) {
    this.accessToken = token
  }

  async login({email, password}) {
    this.isLoading = true
    const data = await fetchLogin({email, password})
    this.isLoading = false
    this.accessToken = data.accessToken
    return data
  }

  async forgotPassword({email, resent}) {
    this.isLoading = true
    const data = await fetchForgotPassword({email, resent})
    this.isLoading = false
    return data
  }

  async getRecover({token}) {
    this.isLoading = true
    const data = await fetchGetRecover({token})
    this.isLoading = false
    return data
  }

  async postRecover({token, password}) {
    this.isLoading = true
    const data = await fetchPostRecover({token, password})
    this.isLoading = false
    return data
  }
}

export default AuthStore
