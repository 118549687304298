import AppLayout from "../../layouts/App/AppLayout";
import './index.scss'
import StatsListItem from "../../components/DashboardPage/StatsListItem";
import ChartTotal from "../../components/DashboardPage/ChartTotal";
import DropDown from "../../components/DropDown";
import ChartCustomer from "../../components/DashboardPage/ChartCustomer";
import ChartRatingFeedback from "../../components/DashboardPage/ChartRating&Feedback";
import {useStores} from "../../store";
import {DropEnums} from "../../libs/enums";
import { observer } from "mobx-react-lite"

const Dashboard = observer(() => {
    const {barStore} = useStores()

    return (
        <AppLayout title='Dashboard'>
            <section className="dashboard_content-inner">
                <ul className="dashboard_stats-list">
                    <StatsListItem 
                         title='Total Orders'
                        count='300'
                        percent='8'
                    />
                    <StatsListItem
                         title='Total Orders'
                        count='300'
                        percent='8'
                    />
                    <StatsListItem 
                         title='Total Orders'
                        count='300'
                        percent='8'
                    />
                    <StatsListItem 
                         title='Total Orders'
                        count='300'
                        percent='8'
                    />
                </ul>
                <ul className="dashboard_charts-list">
                    <li className='chart_item'>
                        <div className='chart_item_head'>
                            <h3 className='chart_title'>Total</h3>
                            <div className="chart_action action_total">
                                <DropDown dropList={DropEnums}/>
                            </div>
                        </div>
                        <ChartTotal/>
                    </li>
                    {/*<li className='chart_item'>*/}
                    {/*    <div className='chart_item_head'>*/}
                    {/*        <h3 className='chart_title'>*/}
                    {/*            Ratings & Feedback*/}
                    {/*        </h3>*/}
                    {/*    </div>*/}
                    {/*    <ChartRatingFeedback bars={[*/}
                    {/*        {*/}
                    {/*            value: 123_456_789,*/}
                    {/*            valueLabel: "12.35",*/}
                    {/*            barLabel: "Food",*/}
                    {/*            color: 'rgba(255, 219, 0, 1)'*/}
                    {/*        },*/}
                    {/*        {*/}
                    {/*            value: 234_567_891,*/}
                    {/*            valueLabel: "23.57",*/}
                    {/*            barLabel: "Service",*/}
                    {/*            color: 'rgba(237, 78, 21, 1)'*/}
                    {/*        },*/}
                    {/*        {*/}
                    {/*            value: 345_678_912,*/}
                    {/*            valueLabel: "34.57",*/}
                    {/*            barLabel: "Overall reviews",*/}
                    {/*            color: 'rgba(229, 0, 28, 1)'*/}
                    {/*        }*/}
                    {/*    ]}/>*/}
                    {/*</li>*/}
                    {/*<li className='chart_item'>*/}
                    {/*    <div className='chart_item_head'>*/}
                    {/*        <h3 className='chart_title'>*/}
                    {/*            Customer Flow*/}
                    {/*        </h3>*/}
                    {/*        <div className="chart_action action_customer">*/}
                    {/*            <DropDown dropList={DropEnums}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <ChartCustomer/>*/}
                    {/*</li>*/}
                    {/*<li className='chart_item'>*/}
                    {/*    <div className='chart_item_head'>*/}
                    {/*        <h3 className='chart_title'>*/}
                    {/*            Visit Hours*/}
                    {/*        </h3>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                </ul>
            </section>
        </AppLayout>
    )
})

export default Dashboard