import {Doughnut, Bar} from 'react-chartjs-2';
import {Chart, registerables} from 'chart.js'

Chart.register(
    ...registerables // 1. Register Filler plugin
);


const ChartRatingFeedback = ({bars}) => {

    const doughnutChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        layout: {},
        animation: {
            duration: 300
        },
        tooltips: {
            enabled: false
        }
    };
    const doughnutChartData = {
        labels: ['Food', 'Service', 'Overall reviews',],
        datasets: [
            {
                label: '#',
                data: [12, 19, 3],
                backgroundColor: [
                    'rgba(255, 219, 0, 1)',
                    'rgba(237, 78, 21, 1)',
                    'rgba(229, 0, 28, 1)',

                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 0,
            },
        ],
    };
    const barLabels = bars.map((bar) => bar.barLabel);
    const dataset = bars.map((bar) => ({
        value: bar.value,
        valueLabel: bar.valueLabel
    }));
    const barColors = bars.map(
        // If the bar is not given a color, we use one of the colors we generate.
        (bar) => bar.color
    );
    const horizontalBarChartData = {
        labels: barLabels,
        datasets: [
            {
                data: dataset,
                backgroundColor: barColors,
                barPercentage: 0.4,
                categoryPercentage: 1
            }
        ]
    }

    const horizontalBarChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                text: 'tiel'
            },
            legend: { display: false },
        },
        scales: {
            x: { display: false },
            y: {
                grid: { display: false },
            }
        },
        indexAxis: "y",
        datalabels: {
            display: true,
            anchor: "end",
            // formatter: (x: SimpleChartDataType) => x.valueLabel,
            align: "end",
            offset: 8,

        }
    }
    return (
        <div className='chart_item_wrapper rating'>
            <div className="rating_inner_row">
                <div className="row_chart">
                    <Doughnut
                        data={doughnutChartData}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className="row_line_chart">
                    <Bar
                        data={horizontalBarChartData}
                        options={horizontalBarChartOptions}
                    />
                </div>
            </div>

        </div>
    )
}

export default ChartRatingFeedback