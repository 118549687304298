import './index.scss'
import Logo from '../../assets/images/logo.png'
import Image from '../../assets/images/auth/Rectangle.png'
import Pencil from '../../assets/images/auth/pencil.svg'
import {useNavigate } from "react-router-dom";
const AuthLayout = ({children, title, subTitle, authDetail = null}) => {

    const navigate = useNavigate()
    return(
        <div className='auth_layout'>
            <div className="layout_image"
                style={{
                    backgroundImage: `url(${Image})`
                }}
            >
            </div>
            <div className="layout_content">
                <div className="layout_logo">
                    <img src={Logo} alt=""/>
                </div>
                <div className="content_title">
                    {title}
                </div>
                <div className="content_sub_title">
                    {subTitle}
                    {authDetail &&
                        <div className='content_detail'>
                            {authDetail}
                            <img onClick={() => navigate('/')} src={Pencil} alt=""/>
                        </div>
                    }
                </div>
                {children}
            </div>
        </div>
    )
}

export default AuthLayout