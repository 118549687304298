import {observer} from "mobx-react-lite"
import React, {useCallback, useEffect, useState} from "react"
import {useNavigate, useLocation} from "react-router-dom"
import {toast} from "react-toastify"

import AppLayout from "../../layouts/App/AppLayout"
import SearchInput from "../../components/Search"
import {ReactComponent as Pencil} from "../../assets/images/actions/pencil.svg"
import {ReactComponent as Trash} from "../../assets/images/actions/trash.svg"
import greenCircle from "../../assets/images/greenCircle.svg"
import grayCircle from "../../assets/images/grayCircle.svg"
import yellowCircle from "../../assets/images/yellowCircle.svg"
import {useStores} from "../../store"
import Tabs from "../../components/Tabs"
import {DraggableTable} from "../../components/DraggableTable"
import {CancelButton, SaveButton} from "../../components/Buttons"
import emptyImage from "../../assets/images/emptyImage.png"
import emptyErrorImage from "../../assets/images/emptyErrorImage.png"
import classes from "./index.module.scss"

const Menu = observer(() => {
  const [openDrug, setOpenDrug] = useState(false)
  const [isFirst, setIsFirst] = useState(true)
  const [menu, setMenu] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [loadedForCategory, setLoadedForCategory] = useState(false)

  const {
    categoriesStore: {getCategories, categoriesUpdateList},
    menuStore: {getMenu, menuUpdateList, updateMenu, isLoading}
  } = useStores()

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isFirst) {
      return
    }
    setIsFirst(false)
    if (!categoriesUpdateList?.length) {
      getCategories()
    }
  }, [categoriesUpdateList, getCategories, isFirst])

  useEffect(() => {
    if (!isFirst) {
      return
    }
    setIsFirst(false)
    const category = location.state?.categoryId
    if (category && !loadedForCategory) {
      setLoadedForCategory(true)
      getMenu({category})
    } else if (!menuUpdateList?.length && !searchValue && !isLoading) {
      getMenu({})
    }
  }, [
    isFirst,
    getMenu,
    searchValue,
    menuUpdateList,
    location.state?.categoryId,
    loadedForCategory,
    isLoading
  ])

  useEffect(() => {
    setMenu(menuUpdateList)
  }, [menuUpdateList])

  const handleEdit = useCallback(
    (e, id) => {
      e.stopPropagation()
      navigate(`/menu/items/update/${id}`)
    },
    [navigate]
  )

  const handleRevertList = () => {
    setMenu(menuUpdateList)
  }

  const handleSaveDrugList = () => {
    setOpenDrug(false)
    const ids = menu.map((c) => c.id)
    const category = location.state?.categoryId
    const categoryId = categoriesUpdateList.find((c) => c.id === category).id
    updateMenu({categoryId, ids}).then((res) => {
      if (res) {
        toast.success("Successfully updated!")
      }
    })
  }

  const handleChangeTab = (name) => {
    getMenu({category: name})
    setSearchValue("")
    setOpenDrug(false)
  }

  const handleSearch = useCallback(
    (search) => {
      const category = location.state?.categoryId
      getMenu({category, search})
    },
    [getMenu, location.state?.categoryId]
  )

  const handleOpenDragList = () => {
    setOpenDrug(true)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Item",
        accessor: "title",
        width: 250,
        enableSorting: false,
        disableSortBy: true,
        Cell: ({row: {original}}) => {
          return (
            <div className={classes.nameBlock}>
              {original.image ? (
                <img
                  src={original.image}
                  alt={original.title}
                  onError={({currentTarget}) => {
                    if (currentTarget.src !== emptyErrorImage) {
                      currentTarget.onError = null // prevents looping
                      currentTarget.src = emptyErrorImage
                    }
                  }}
                />
              ) : (
                <img src={emptyImage} alt="Default Image" />
              )}
              {original.title}
            </div>
          )
        }
      },
      {
        Header: "Category",
        accessor: "category",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({cell: {value}}) => (value ? value.name : "-")
      },
      {
        Header: "Price",
        accessor: "price",
        enableSorting: false,
        disableSortBy: true,
        Cell: ({cell: {value}}) => (value ? `$${(value / 100).toFixed(2)}` : "")
      },
      {
        Header: "Visibility",
        accessor: "visibility",
        enableSorting: false,
        disableSortBy: true,
        Cell: ({cell: {value}}) => {
          return (
            <div className={classes.availability}>
              <img
                src={
                  value === "active"
                    ? greenCircle
                    : value === "hidden"
                    ? grayCircle
                    : yellowCircle
                }
                alt={value}
              />
              {value}
            </div>
          )
        }
      },
      {
        Header: " ",
        accessor: "action",
        width: 80,
        enableSorting: false,
        disableSortBy: true,
        flex: "end",
        className: "action_wrapper_td",
        Cell: ({row: {original}}) => {
          return (
            <div className={classes.actionsRow}>
              <div
                onClick={(e) => handleEdit(e, original.id)}
                className={`${classes.action} ${classes.actionChange}`}
              >
                <Pencil />
                {/*navigate(`/admin/users/${original.id}`)*/}
              </div>
              {/*
              <div
                onClick={(e) => handleRemove(e, original.id)}
                className={`${classes.action} ${classes.actionDelete}`}
              >
                <Trash />
              </div>
              */}
            </div>
          )
        }
      }
    ],
    [handleEdit]
  )

  const handleRemove = (e, id) => {
    e.stopPropagation()
  }

  return (
    <AppLayout
      title="Items"
      headComponent={
        <div className="head_component">
          <SearchInput
            onSearch={handleSearch}
            setValue={setSearchValue}
            value={searchValue}
          />
          {!openDrug && location.state?.categoryId && menu.length > 1 && (
            <SaveButton
              text="Edit list"
              onClick={handleOpenDragList}
              withBorder
            />
          )}

          {openDrug && (
            <CancelButton text="Revert" onClick={handleRevertList} />
          )}

          {openDrug && (
            <SaveButton text="Save changes" onClick={handleSaveDrugList} />
          )}
          <SaveButton
            onClick={() => navigate("/menu/items/add")}
            className="admin_btn"
            text="Add new"
          />
        </div>
      }
    >
      <section className={classes.section}>
        {!!categoriesUpdateList?.length ? (
          <Tabs
            activeTable={location.state?.categoryName}
            onChange={handleChangeTab}
          >
            {["all", ...categoriesUpdateList].map((c) => {
              if (c === "all") {
                return (
                  <div label="All items" key={c}>
                    <DraggableTable
                      columns={columns}
                      data={menu}
                      setData={setMenu}
                      withFiltersList
                    />
                  </div>
                )
              }
              return (
                <div label={c.description} name={c.name} id={c.id} key={c.name}>
                  <DraggableTable
                    columns={columns}
                    data={menu}
                    accessDrag={openDrug}
                    setData={setMenu}
                    withFiltersList
                  />
                </div>
              )
            })}
          </Tabs>
        ) : (
          <></>
        )}
      </section>
    </AppLayout>
  )
})

export default Menu
