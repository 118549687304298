import {makeAutoObservable} from "mobx"
import {fetchSettingsList, updateWorkingHours} from "store/kitchens/api"


export class KitchensSettingsStore {
  data = {}
  isLoading = false

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }

  async getSettingsList() {
    this.isLoading = true
    this.data = await fetchSettingsList()
    this.isLoading = false
  }
  async updateHours(data) {
    this.isLoading = true
    this.data = await updateWorkingHours(data)
    this.isLoading = false
  }

}


export default  KitchensSettingsStore