import {configure, spy} from "mobx"
import "./_clear.css"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import ErrorBoundary from "./pages/ErrorBoundary"
import "react-datepicker/dist/react-datepicker.css"
import "react-quill/dist/quill.snow.css"

configure({
  // reactionScheduler: (f) => {
  //     console.log('reaction')
  //     // setTimeout(f)
  // }
})

spy((ev) => {
  if (ev.type.includes("action")) {
    // console.log(ev)
  }
})

const root = ReactDOM.createRoot(document.getElementById("admin"))

root.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
