import {fetchAxios} from "../axios"

export const fetchLogin = async ({email, password}) => {
  try {
    const response = await fetchAxios({
      url: "/auth/login",
      method: "POST",
      data: {
        email,
        password
      }
    })

    if (response.status === 201) {
      return response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}

export const fetchForgotPassword = async ({email, resent}) => {
  try {
    const response = await fetchAxios({
      url: `/auth/forgot-password?resent=${resent}`,
      method: "POST",
      data: {
        email
      }
    })

    if (response.status === 200) {
      return response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}

export const fetchGetRecover = async ({token}) => {
  try {
    const response = await fetchAxios({
      url: `/auth/recover?token=${token}`
    })

    if (response.status === 200) {
      return response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}

export const fetchPostRecover = async ({token, password}) => {
  try {
    const response = await fetchAxios({
      url: `/auth/recover?token=${token}`,
      method: "POST",
      data: {
        password
      }
    })

    if (response.status === 200) {
      return response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}
