import {makeAutoObservable} from "mobx"
import {
  fetchInvitationsList,
  fetchGetInvitation,
  fetchPostInvitation,
  fetchInvitations,
  fetchDeleteInvitation
} from "./api"

export class InvitationsStore {
  data = []
  isLoading = false
  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }

  async getInvitationsList() {
    this.isLoading = true
    this.data = await fetchInvitationsList()
    this.isLoading = false
  }

  async deleteInvitation(id) {
    this.isLoading = true
    const item = await fetchDeleteInvitation({id})
    if (item) {
      this.data = await fetchInvitationsList()
    }
    this.isLoading = false
    return item
  }

  get invitationsUpdateList() {
    return this.data
  }

  async addInvitations({data}) {
    this.isLoading = true
    const res = await fetchInvitations({data})

    if (res) {
      this.data = await fetchInvitationsList()
    }

    this.isLoading = false
    return res
  }

  async getInvitation({code}) {
    this.isLoading = true
    const res = await fetchGetInvitation({code})
    this.isLoading = false
    return res
  }

  async postInvitation({code, password}) {
    this.isLoading = true
    const res = await fetchPostInvitation({code, password})
    this.isLoading = false
    return res
  }
}

export default InvitationsStore
