import {fetchAxios} from "store/axios"
import {toJS} from "mobx"
import {toast} from "react-toastify"


export const fetchSettingsList = async () => {
  try {
    const response = await fetchAxios({
      url: `/kitchens/32d2c902-e2d4-471a-b280-37b2fd1dd855/settings`,
      method: "GET",
    })

    if (response.status === 200) {
      return response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}

export const updateWorkingHours = async (data) => {
  try {
    const response = await fetchAxios({
      url: `/kitchens/32d2c902-e2d4-471a-b280-37b2fd1dd855/settings`,
      method: "PATCH",
      data
    })
    if (response.status === 200) {
      toast.success('Successfully updated')
      return  response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}