import './index.scss'
import {useOutsideClick} from "../../hooks/useOutsideClick";
import CLose from '../../assets/images/actions/icons_close.png'
import {ReactComponent as Pencil} from "../../assets/images/actions/pencil.svg";
import {useStores} from "../../store";
import CustomTimePicker from "../CustomTimePicker";
import {useState} from "react";
import CheckboxSwitcher from "../CheckboxSwitcher";

const CalendarModal = () => {
    const {shiftStore: {toggleModal, setModalData, modalData}} = useStores()
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('')
    const [breakTime, setBreakTime] = useState('')

    const outsideClick = () => {
        toggleModal()
    }

    const handleCloseModal = () => {
        toggleModal()
        setModalData({})
    }

    const ref = useOutsideClick(outsideClick)

    return(
        <div className='calendar_overlay'>
            <div  ref={ref} className="modal_wrapper">
               <div className="modal_head">
                    <h4>
                        {modalData.isCreate ? "Add Schedule" : "Update Schedule"}
                    </h4>
                   <div className="modal_close"
                    onClick={handleCloseModal}
                   >
                       <img src={CLose} alt=""/>
                   </div>
               </div>

                <div className="date_row">
                    <span>
                        {modalData?.isCreate ? modalData?.dateStr : modalData?.event?.startStr}
                    </span>
                    <Pencil />
                </div>
                <div className="row_fields">
                    <CustomTimePicker
                        labelEnd='End Time'
                        labelStart='Start Time'
                        endDate={endTime}
                        startDate={startTime}
                        setStartDate={setStartTime}
                        setEndDate={setEndTime}
                        range={true}
                    />
                        <CustomTimePicker
                            label='Break Time'
                            startDate={breakTime}
                            setStartDate={setBreakTime}
                        />
                    <div className='checkbox_wrapper'>
                        <CheckboxSwitcher label='Accept Extra Hours' />
                    </div>
                </div>
                <div className="modal_action">
                    <input value='SAVE' type="submit" className='submit_modal'/>
                </div>
            </div>
        </div>
    )
}

export default CalendarModal