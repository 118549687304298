import React from "react"
import {DragHandle} from "./DragHandle"

import classes from "./index.module.scss"

export const StaticTableRow = ({row}) => {
  return (
    <tr {...row.getRowProps()} className={classes.styledStaticTableRow}>
      {row.cells.map((cell, i) => {
        const widthClass = `th${[cell.column.width]}`

        if (i === 0) {
          return (
            <td
              {...cell.getCellProps()}
              className={`${classes.styledStaticData} ${classes[widthClass]}`}
            >
              <DragHandle />
              <span>{cell.render("Cell")}</span>
            </td>
          )
        }
        return (
          <td
            {...cell.getCellProps()}
            className={`${classes.styledStaticData} ${classes[widthClass]}`}
          >
            {cell.render("Cell")}
          </td>
        )
      })}
    </tr>
  )
}
