import {fetchAxios} from "../axios"

export const fetchInvitationsList = async () => {
  try {
    const response = await fetchAxios({
      url: `/invitations`
    })

    if (response.status === 200) {
      return response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}

export const fetchDeleteInvitation = async ({id}) => {
  try {
    const response = await fetchAxios({
      url: `/invitations/${id}`,
      method: "DELETE"
    })

    if (response.status === 200) {
      return response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}

export const fetchInvitations = async ({data}) => {
  try {
    const response = await fetchAxios({
      url: `/invitations`,
      method: "POST",
      data
    })

    if (response.status === 200) {
      return response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}

export const fetchGetInvitation = async ({code}) => {
  try {
    const response = await fetchAxios({
      url: `/invitation?code=${code}`
    })

    if (response.status === 200) {
      return response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}

export const fetchPostInvitation = async ({code, password}) => {
  try {
    const response = await fetchAxios({
      url: `/invitation?code=${code}`,
      method: "POST",
      data: {
        password
      }
    })

    if (response.status === 200) {
      return response.data
    }

    return response
  } catch (e) {
    console.log(e)
  }
}
