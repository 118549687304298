import {observer} from "mobx-react-lite"
import {useFormik} from "formik"
import {useNavigate, useParams} from "react-router-dom"
import React, {useEffect, useRef, useState} from "react"
import {toast} from "react-toastify"

import AppLayout from "../../layouts/App/AppLayout"
import {menuSchema} from "../../libs/schemas"
import {useStores} from "../../store"
import CustomSelect from "../../components/Select"
import {visibilityValues, isPureValues} from "../../constants"
import {CancelButton} from "../../components/Buttons"
import GeneralInfo from "./steps/generalInfo"
import AddVariations from "./steps/addVariations"
import AddModifiersSets from "./steps/addModifiersSets"
import VariationsModal from "./modals/variationsModal"
import ModifiersSetsModal from "./modals/modifiersSetsModal"
import StepsLine from "./stepsLine"
import AddAddons from "./steps/addAddons"
import classes from "./index.module.scss"

const defaultModifiersSet = {
  modifierId: "",
  title: "",
  min: 0,
  max: 0,
  multiselect: false,
  required: false
}

const MenuCreateEdit = observer(() => {
  const [item, setItem] = useState(null)

  const [step, setStep] = useState("General info") //General info, Add variations, Add modifiers, Addons, Set visibility

  const [isVariationModalOpen, setIsVariationModalOpen] = useState(false)
  const [variationToUpdate, setVariationToUpdate] = useState(null)

  const [isModifiersModalOpen, setIsModifiersModalOpen] = useState(false)
  const [modifierSetToUpdate, setModifierSetToUpdate] =
    useState(defaultModifiersSet)

  const {
    menuStore: {
      getItem,
      updateItem,
      addItem,
      getMenu,
      addVariation,
      updateVariation,
      deleteVariation,
      addModifierSet,
      updateModifierSet,
      deleteModifierSet,
      updateItemVisibility,
      addIngredients
    },
    modifiersStore: {getModifierSets, modifierSetsUpdateList},
    ingredientsStore: {getIngredients, ingredientsUpdateList}
  } = useStores()

  const {
    categoriesStore: {getCategories, categoriesUpdateList}
  } = useStores()

  const navigate = useNavigate()

  const {id} = useParams()
  const uploadingFileInputRefs = useRef([])
  const ref = useRef(null)

  useEffect(() => {
    const getItemData = async () => {
      const itemData = await getItem(id)
      if (itemData) {
        setItem(itemData)
      }
    }

    id && getItemData()
  }, [getItem, id])

  useEffect(() => {
    getCategories()
  }, [getCategories])

  useEffect(() => {
    getModifierSets()
  }, [])

  useEffect(() => {
    getIngredients({})
  }, [])

  const getCategory = (id) => {
    const item = categoriesUpdateList.find((c) => c.id === id)
    if (!item) {
      return null
    }
    return {label: item.name, value: item.id, name: item.name}
  }

  const {
    dirty,
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    resetForm,
    submitForm
  } = useFormik({
    initialValues: {
      title: item?.title || "",
      description: item?.description || "",
      icon: "",
      image: "",
      stubImage: "",
      price: item?.price / 100 || 0,
      modifiers: item?.itemModifiers || [],
      ingredients:
        item?.ingredients
          .filter((ing) => ing.type === "addons")
          .map((i) => ({...i, label: i.name, value: i.id})) || [],
      variations: item?.variations || [],
      iconPreview: item?.icon || "",
      imagePreview: item?.image || "",
      stubImagePreview: item?.stubImage || "",
      visibility: item?.visibility
        ? visibilityValues.find((v) => {
            return v.value === item.visibility
          })
        : null,
      isPureItem: isPureValues.find(
        (v) => v.value === item?.isPureItem || v.value === false
      ),
      estimated_time_minutes: item?.estimatedTime
        ? Math.floor(item.estimatedTime / 60)
        : 0,
      estimated_time_seconds: item?.estimatedTime
        ? (item.estimatedTime % 60).toFixed(0)
        : 0,
      category: item?.categoryId ? getCategory(item.categoryId) : undefined
    },

    // ...categoriesUpdateList.map((c) => ({
    //   ...c,
    //   label: c.name,
    //   value: c.id
    // }))
    enableReinitialize: true,
    validationSchema: menuSchema,
    onSubmit: async (values) => {
      try {
        if (!dirty) {
          return
        }

        const textData = {
          title: values.title,
          description: values.description
        }

        const data = new FormData()

        for (const key in textData) {
          data.append(key, values[key])
        }

        if (values.isPureItem.value) {
          data.append("price", values.price * 100)
        }

        if (values.category?.value) {
          data.append("categoryId", values.category?.value)
        }

        if (values.image) {
          data.append("image", values.image)
        }

        if (values.stubImage) {
          data.append("stubImage", values.stubImage)
        }

        if (values.icon) {
          data.append("icon", values.icon)
        }

        data.append("isPureItem", values.isPureItem.value)

        if (
          Number(values.estimated_time_minutes) * 60 +
            Number(values.estimated_time_seconds) >
          86400
        ) {
          toast.error("Estimated time can`t be more than 24hours!")
          return "error"
        }

        data.append(
          "estimatedTime",
          Number(values.estimated_time_minutes) * 60 +
            Number(values.estimated_time_seconds)
        )

        if (id) {
          return updateItem({id, data}).then((res) => {
            if (res) {
              toast.success("Item general info successfully changed!")
              setItem(res)
            }
          })
        }

        if (item?.id) {
          return updateItem({id: item.id, data}).then((res) => {
            if (res) {
              toast.success("Item general info successfully changed!")
              setItem(res)
            }
          })
        }

        return addItem({data}).then((res) => {
          if (res) {
            toast.success("Item general info successfully added!")
            setItem(res)
          }
        })
      } catch (err) {
        console.log(err.response)
      }
    }
  })

  const handleBack = () => {
    if (step === "General info") {
      return navigate("/menu/items")
    }
    if (step === "Add variations") {
      return setStep("General info")
    }
    if (step === "Add modifiers") {
      return setStep("Add variations")
    }
    if (step === "Addons") {
      return setStep("Add modifiers")
    }
    if (values.isPureItem.value) {
      return setStep("General info")
    }
    if (values.category?.name === "pizza") {
      return setStep("Addons")
    }
    return setStep("Add modifiers")
  }

  const handleNextStep = async () => {
    if (step === "General info") {
      const res = await submitForm()

      if (res === "error") {
        return
      }

      if (values.isPureItem.value) {
        return setStep("Set visibility")
      }
      return setStep("Add variations")
    }
    if (step === "Add variations") {
      return setStep("Add modifiers")
    }
    if (step === "Add modifiers") {
      if (values.category?.name === "pizza") {
        return setStep("Addons")
      }
      return setStep("Set visibility")
    }
    if (step === "Addons") {
      if (!dirty) {
        return setStep("Set visibility")
      }

      const res = await addIngredients({
        id: item.id,
        data: {ids: values.ingredients.map((i) => i.id)}
      })
      if (res) {
        toast.success("Item ingredients list successfully changed!")

        return setStep("Set visibility")
      }
      return
    }

    const res = await updateItemVisibility({
      id: item.id,
      data: {visibility: values.visibility.value}
    })

    if (res) {
      toast.success("Item visibility successfully changed!")
      navigate("/menu/items")
    }
  }

  const fileInputHandler = (event, name, preview) => {
    if(name === 'delete'){
      setFieldValue(preview, '')
      return;
    }
    if (!event.currentTarget.files[0]) {
      return
    }
    setFieldValue(name, event.currentTarget.files[0])
    if (preview) {
      setFieldValue(preview, URL.createObjectURL(event.currentTarget.files[0]))
    }
    uploadingFileInputRefs.current = [
      ...uploadingFileInputRefs.current,
      event.currentTarget
    ]
  }

  const handleRevert = () => {
    resetForm()
  }

  const handleLinkBack = () => getMenu({})

  //variations

  const handleVariationEdit = (data) => {
    setVariationToUpdate({
      ...data,
      imagePreview: data.image,
      price: data.price ? (data.price / 100).toFixed(2) : 0
    })
    setIsVariationModalOpen(true)
  }

  const handleVariationRemove = async (id) => {
    const res = await deleteVariation({id: item.id, varId: id})

    if (res.affected) {
      toast.success("Variation removed successfully!")

      const itemData = await getItem(item.id)
      if (itemData) {
        setItem(itemData)
      }
    }
  }

  const variationImageInputHandler = (event, name, preview) => {
    if(name === 'delete'){
      setVariationToUpdate((prev) => ({
        ...prev,
        [preview]: ''
      }))
      return;
    }
    const file = event.currentTarget.files[0]
    if (!file) {
      return
    }

    setVariationToUpdate((prev) => ({
      ...prev,
      image: file
    }))

    if (preview) {
      setVariationToUpdate((prev) => ({
        ...prev,
        [preview]: URL.createObjectURL(file)
      }))
    }
    uploadingFileInputRefs.current = [
      ...uploadingFileInputRefs.current,
      event.currentTarget
    ]
  }

  const setVariationFieldValue = (name, el) => {
    setVariationToUpdate((prev) => ({...prev, [name]: el.value}))
  }

  const handleCancelVariation = () => {
    setIsVariationModalOpen(false)
    setVariationToUpdate(null)
  }

  const handleSaveVariation = async () => {
    if (!variationToUpdate) {
      return toast.error("Please enter Title and Visibility!")
    }
    if (!variationToUpdate.name) {
      return toast.error("Please enter Title!")
    }
    if (!variationToUpdate.visibility) {
      return toast.error("Please enter Visibility!")
    }
    if (!variationToUpdate.id) {
      const res = await addVariation({
        id: item.id,
        data: {
          ...variationToUpdate,
          size: variationToUpdate.size || 0,
          price: parseInt(variationToUpdate.price * 100)
        }
      })
      if (res.id) {
        toast.success("Variation added successfully!")
        setVariationToUpdate(null)
        setIsVariationModalOpen(false)
        const itemData = await getItem(item.id)
        if (itemData) {
          setItem(itemData)
        }
      }
    } else {
      const res = await updateVariation({
        id: item.id,
        data: {
          ...variationToUpdate,
          size: variationToUpdate.size || 0,
          price: parseInt(variationToUpdate.price * 100)
        },
        varId: variationToUpdate.id
      })
      if (res.id) {
        toast.success("Variation changed successfully!")
        setVariationToUpdate(null)
        setIsVariationModalOpen(false)
        const itemData = await getItem(item.id)
        if (itemData) {
          setItem(itemData)
        }
      }
    }
  }

  const handleChangeVariation = (e) => {
    setVariationToUpdate((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  //modifiers sets

  const handleModSetEdit = (data) => {
    setModifierSetToUpdate({
      id: data.id,
      title: data.title,
      max: data.max || 0,
      min: data.min || 0,
      modifierId: data.modifierId,
      multiselect: data.multiselect,
      required: data.required
    })
    setIsModifiersModalOpen(true)
  }

  const handleModSetRemove = async (id) => {
    const res = await deleteModifierSet({id: item.id, setId: id})

    if (res.affected) {
      toast.success("Modifier set removed successfully!")

      const itemData = await getItem(item.id)
      if (itemData) {
        setItem(itemData)
      }
    }
  }

  const setModSetFieldValue = (name, el) => {
    setModifierSetToUpdate((prev) => ({...prev, [name]: el.value}))
  }

  const handleCancelModSet = () => {
    setIsModifiersModalOpen(false)
    setModifierSetToUpdate(defaultModifiersSet)
  }

  const handleSaveModSet = async () => {
    if (!modifierSetToUpdate.modifierId && !modifierSetToUpdate.title) {
      return toast.error("Please enter Title and choose Modifiers set!")
    }
    if (!modifierSetToUpdate.modifierId) {
      return toast.error("Please choose Modifiers set!")
    }
    if (!modifierSetToUpdate.title) {
      return toast.error("Please enter Title!")
    }
    if (!modifierSetToUpdate.id) {
      const res = await addModifierSet({
        id: item.id,
        data: {...modifierSetToUpdate}
      })
      if (res.id) {
        toast.success("Modifiers set added successfully!")
        setModifierSetToUpdate(defaultModifiersSet)
        setIsModifiersModalOpen(false)
        const itemData = await getItem(item.id)
        if (itemData) {
          setItem(itemData)
        }
      }
    } else {
      const res = await updateModifierSet({
        id: item.id,
        data: modifierSetToUpdate,
        setId: modifierSetToUpdate.modifierId
      })

      if (res.id) {
        toast.success("Modifiers set changed successfully!")
        setModifierSetToUpdate(defaultModifiersSet)
        setIsModifiersModalOpen(false)
        const itemData = await getItem(item.id)
        if (itemData) {
          setItem(itemData)
        }
      }
    }
  }

  const handleChangeModifiersSet = (e) => {
    setModifierSetToUpdate((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.name === "max" || e.target.name === "min"
          ? parseInt(e.target.value)
          : e.target.value
    }))
  }

  const handleChangeModifiersSetCheckbox = (name) => {
    setModifierSetToUpdate((prev) => ({
      ...prev,
      [name]: prev?.[name] ? !prev[name] : true
    }))
  }

  return (
    <AppLayout
      title={`${id ? item?.title : "Add item"} - ${step}`}
      Tag="form"
      linkBack="/menu/items"
      onLinkBack={handleLinkBack}
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          {id && (step === "General info" || step === "Set visibility") && (
            <CancelButton
              className={classes.revert}
              type="button"
              onClick={handleRevert}
              text="Revert changes"
            />
          )}

          <CancelButton
            className={classes.revert}
            text={step === "General info" ? "Cancel" : "Back"}
            type="button"
            onClick={handleBack}
          />

          <button
            disabled={
              id || item?.id
                ? !isValid
                : !isValid || (step === "General info" && !dirty)
            }
            className={classes.submit}
            type="button"
            onClick={handleNextStep}
          >
            {step === "Set visibility" ? "Save changes" : "Next step"}
          </button>
        </div>
      }
    >
      <StepsLine
        currentStep={step}
        quantity={
          values.isPureItem.value
            ? 2
            : values.category?.name === "pizza"
            ? 5
            : 4
        }
      />

      <div
        className={classes.container}
        ref={ref}
        style={{
          height: `calc(100vh - 
        ${ref.current?.getBoundingClientRect().top}px - 24px)`
        }}
      >
        <div className={classes.content}>
          {step === "General info" && (
            <GeneralInfo
              errors={errors}
              values={values}
              categoriesUpdateList={categoriesUpdateList}
              setFieldValue={setFieldValue}
              fileInputHandler={fileInputHandler}
              handleChange={handleChange}
            />
          )}
          {step === "Add variations" && (
            <AddVariations
              setIsVariationModalOpen={setIsVariationModalOpen}
              values={values}
              handleVariationEdit={handleVariationEdit}
              handleVariationRemove={handleVariationRemove}
            />
          )}

          {step === "Add modifiers" && (
            <AddModifiersSets
              values={values}
              setIsModifiersModalOpen={setIsModifiersModalOpen}
              handleModSetEdit={handleModSetEdit}
              handleModSetRemove={handleModSetRemove}
            />
          )}

          {step === "Addons" && (
            <AddAddons
              values={values}
              setFieldValue={setFieldValue}
              addonsList={ingredientsUpdateList}
            />
          )}

          {step === "Set visibility" && (
            <CustomSelect
              label="Visibility*"
              name="visibility"
              options={visibilityValues}
              value={values.visibility}
              placeholder="Coming Soon / Active /  Hidden"
              size="md"
              setFieldValue={setFieldValue}
              afterText="This item will be unavailable for purchase and will not be displayed on all of your sites."
            />
          )}

          {isVariationModalOpen && (
            <VariationsModal
              variationImageInputHandler={variationImageInputHandler}
              errors={errors}
              handleChangeVariation={handleChangeVariation}
              handleSaveVariation={handleSaveVariation}
              handleCancelVariation={handleCancelVariation}
              isVariationModalOpen={isVariationModalOpen}
              setVariationFieldValue={setVariationFieldValue}
              variationToUpdate={variationToUpdate}
            />
          )}

          {isModifiersModalOpen && (
            <ModifiersSetsModal
              handleChangeModifiersSetCheckbox={
                handleChangeModifiersSetCheckbox
              }
              errors={errors}
              handleChangeModifiersSet={handleChangeModifiersSet}
              handleCancelModSet={handleCancelModSet}
              handleSaveModSet={handleSaveModSet}
              isModifiersModalOpen={isModifiersModalOpen}
              modifierSetsUpdateList={modifierSetsUpdateList}
              modifierSetToUpdate={modifierSetToUpdate}
              setModSetFieldValue={setModSetFieldValue}
            />
          )}
        </div>
      </div>
    </AppLayout>
  )
})

export default MenuCreateEdit
