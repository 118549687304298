import React from "react"
import ReactQuill from "react-quill"

import InputField from "../../../components/InputField"
import CustomSelect from "../../../components/Select"
import {isPureValues} from "../../../constants"
import UploadInput from "../../../components/UploadInput"
import classes from "../index.module.scss"

const GeneralInfo = ({
  values,
  errors,
  handleChange,
  categoriesUpdateList,
  setFieldValue,
  fileInputHandler
}) => {
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block", "image"],

    [{list: "ordered"}, {list: "bullet"}],
    [{script: "sub"}, {script: "super"}], // superscript/subscript
    [{indent: "-1"}, {indent: "+1"}], // outdent/indent

    [{size: []}], // custom dropdown
    [{header: [1, 2, 3, 4, 5, 6, false]}],

    [{color: []}, {background: []}], // dropdown with defaults from theme
    [{align: []}],

    ["clean"]
  ]

  return (
    <>
      <div className={classes.topBlock}>
        <div className={classes.topBlockInputs}>
          <InputField
            label="Item name*"
            value={values?.title}
            name="title"
            onChange={handleChange}
            errors={errors}
            placeholder="Item name"
          />

          <ReactQuill
            className={classes.textEditor}
            theme="snow"
            value={values?.description}
            onChange={(v) => setFieldValue("description", v)}
            modules={{
              toolbar: toolbarOptions
            }}
            placeholder="Item description"
          />

          <CustomSelect
            label="Category"
            name="category"
            options={[
              {value: undefined, label: "-"},
              ...categoriesUpdateList.map((c) => ({
                ...c,
                label: c.description,
                value: c.id
              }))
            ]}
            value={values?.category}
            placeholder="Category selector"
            setParam="id"
            setFieldValue={setFieldValue}
          />
          <CustomSelect
            label="Is pure item*"
            name="isPureItem"
            options={isPureValues}
            value={values.isPureItem}
            placeholder="Enabled/Disabled"
            setFieldValue={setFieldValue}
          />
        </div>

        <UploadInput
          label="Icon link"
          name="icon"
          preview="iconPreview"
          imageSrc={values.iconPreview}
          errors={errors}
          fileInputHandler={fileInputHandler}
          size="middle"
        />

        <UploadInput
          label="Image link"
          name="image"
          preview="imagePreview"
          imageSrc={values.imagePreview}
          errors={errors}
          fileInputHandler={fileInputHandler}
          size="middle"
        />

        <UploadInput
          label="Stub image link"
          name="stubImage"
          preview="stubImagePreview"
          imageSrc={values.stubImagePreview}
          errors={errors}
          fileInputHandler={fileInputHandler}
          size="middle"
        />
      </div>

      {values.isPureItem.value && (
        <InputField
          label="Price*"
          value={values?.price}
          name="price"
          type="price"
          placeholder="Item price"
          onChange={handleChange}
          errors={errors}
          withIcon
          size="sm"
        />
      )}
      <label className={classes.estimatedTime}>
        Estimated cooking time
        <div>
          <input
            type="number"
            name="estimated_time_minutes"
            value={values?.estimated_time_minutes}
            onChange={handleChange}
          />
          <span className={classes.afterText}>minutes</span>
          <input
            type="number"
            name="estimated_time_seconds"
            value={values?.estimated_time_seconds}
            onChange={handleChange}
          />
          <span className={classes.afterText1}>seconds</span>
        </div>
      </label>
    </>
  )
}

export default GeneralInfo
