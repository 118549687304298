import "./index.scss"
import LOGO from "../../assets/images/logo.png"
import Location from "../../assets/images/location.svg"
import Arrow from "../../assets/images/down-arrow.svg"

const Header = () => {
  return (
    <header className="app_header">
      <div className="app_location">
        <img src={Location} alt="" className="location_ico" />
        767 5th Ave, New York
        <img className="arrow_ico" src={Arrow} alt="" />
      </div>
    </header>
  )
}

export default Header
