import {makeAutoObservable} from "mobx"
import {getUsersList, getUser, updateUser, deleteUser} from "./api"

export class UsersStore {
  data = []
  isLoading = false

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }

  async getUsers() {
    this.isLoading = true
    this.data = await getUsersList()
    this.isLoading = false
  }

  async getUser(id) {
    this.isLoading = true
    const item = await getUser(id)
    this.isLoading = false
    return item
  }

  async updateUser({id, data}) {
    this.isLoading = true
    const item = await updateUser({id, data})
    if (item) {
      this.data = await getUsersList()
    }
    this.isLoading = false
    return item
  }

  async deleteUser(id) {
    this.isLoading = true
    const item = await deleteUser({id})
    if (item) {
      this.data = await getUsersList()
    }
    this.isLoading = false
    return item
  }

  get usersUpdateList() {
    return this.data
  }
}

export default UsersStore
