import {useMemo} from "react"
import {debounce} from "lodash"

import {ReactComponent as SearchIcon} from "../../assets/images/search.svg"
import classes from "./index.module.scss"

const Search = ({
  value,
  setValue,
  onSearch = () => null,
  placeholder = "Search",
  size = "md"
}) => {
  const debounceFn = useMemo(
    () =>
      debounce((q) => {
        onSearch(q)
      }, 500),
    [onSearch]
  )

  const handleChange = (e) => {
    setValue(e.target.value)
    debounceFn(e.target.value)
  }

  return (
    <div className={`${classes.container} ${classes[size]}`}>
      <SearchIcon className={classes.searchIcon} />

      <input value={value} onChange={handleChange} placeholder={placeholder} />
    </div>
  )
}

export default Search
