import {observer} from "mobx-react-lite"
import React, {useCallback, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import {toast} from "react-toastify"

import AppLayout from "../../layouts/App/AppLayout"
import {ReactComponent as Pencil} from "../../assets/images/actions/pencil.svg"
import {ReactComponent as Trash} from "../../assets/images/actions/trash.svg"
import {useStores} from "../../store"
import {DraggableTable} from "../../components/DraggableTable"
import greenCircle from "../../assets/images/greenCircle.svg"
import redCircle from "../../assets/images/redCircle.svg"
import classes from "./index.module.scss"

const Templates = observer(() => {
  const {
    couponStore: {
      getCouponTemplates,
      deleteCouponTemplate,
      couponTemplatesUpdateList
    }
  } = useStores()

  const [coupons, setCoupons] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getCouponTemplates({})
  }, [getCouponTemplates])

  useEffect(() => {
    setCoupons(couponTemplatesUpdateList)
  }, [couponTemplatesUpdateList])

  const handleEdit = useCallback(
    (e, id, subtype) => {
      e.stopPropagation()
      if (subtype === "birthday") {
        return navigate(`/coupons/generator/birthday/update/${id}`)
      }
      if (subtype === "special") {
        return navigate(`/coupons/generator/special/update/${id}`)
      }
      if (subtype === "register") {
        return navigate(`/coupons/generator/registration/update/${id}`)
      }
    },
    [navigate]
  )

  const handleRemove = useCallback(
    async (id) => {
      const res = await deleteCouponTemplate(id)
      if (res) {
        toast.success("Coupon template successfully deleted!")
        getCouponTemplates({})
      }
    },
    [deleteCouponTemplate, getCouponTemplates]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "Description",
        accessor: "description",
        disableSortBy: true,
        width: 250,
        enableSorting: false,
        Cell: ({cell: {value}}) => <span>{value ? value : "-"}</span>
      },
      {
        Header: "Creation date",
        accessor: "createdAt",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({cell: {value}}) => (
          <span>{value ? value.slice(0, 10) : "-"}</span>
        )
      },
      {
        Header: "Status",
        accessor: "active",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({cell: {value}}) => {
          return (
            <div className={classes.status}>
              <img src={value ? greenCircle : redCircle} alt={value} />
              {value ? "Active" : "Inactive"}
            </div>
          )
        }
      },
      {
        Header: "Recurrence",
        accessor: "recurrence",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({cell: {value}}) => <span>{value ? value : "-"}</span>
      },
      {
        Header: "Category",
        accessor: "category",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({cell: {value}}) => (
          <span>{value ? value.description : "-"}</span>
        )
      },
      {
        Header: "Discount",
        accessor: "discount",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({row: {original}}) => (
          <span>
            {original.discountType === "item"
              ? original.discountType
              : original.discountType === "percentage"
              ? `${original.value}%`
              : `$${original.value}`}
          </span>
        )
      },
      {
        Header: " ",
        accessor: "action",
        width: 80,
        flex: "end",
        enableSorting: false,
        disableSortBy: true,
        className: "action_wrapper_td",
        Cell: ({row: {original}}) => {
          return (
            <div className={classes.actionsRow}>
              <div
                onClick={(e) => handleEdit(e, original.id, original.subtype)}
                className={`${classes.action} ${classes.actionChange}`}
              >
                <Pencil />
              </div>
              <div
                onClick={(e) => handleRemove(original.id)}
                className={`${classes.action} ${classes.actionDelete}`}
              >
                <Trash />
              </div>
            </div>
          )
        }
      }
    ],
    []
  )

  return (
    <AppLayout title="Templates">
      <DraggableTable columns={columns} data={coupons} setData={setCoupons} />
    </AppLayout>
  )
})

export default Templates
