import AppLayout from "../../layouts/App/AppLayout"
import "./index.scss"
import Table from "../../components/Table"
import React, {useEffect, useState} from "react"
import axios from "axios"
import SearchInput from "../../components/Search"

const Customers = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Customer Name",
            accessor: "customerName",
            Cell: ({row: {original}}) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px"
                  }}
                >
                  <img
                    src={original.customerImage}
                    alt={original.customerName}
                    style={{
                      width: "41px",
                      height: "41px",
                      borderRadius: "50%"
                    }}
                  />
                  {original.customerName}
                </div>
              )
            }
          },
          {
            Header: "Email",
            accessor: "email",
            Cell: ({cell: {value}}) => (value ? value : "-")
          },
          {
            Header: "Phone",
            accessor: "phone",
            Cell: ({cell: {value}}) => (value ? value : "-")
          },
          {
            Header: "DOB",
            accessor: "dob",
            Cell: ({cell: {value}}) => (value ? value : "-")
          },
          {
            Header: "Location",
            accessor: "location",
            Cell: ({cell: {value}}) => (value ? value : "-")
          }
        ]
      }
    ],
    []
  )
  const [data, setData] = useState([])

  const get = async () => {
    const {data} = await axios.get("https://jsonplaceholder.typicode.com/users")
    const newData = data?.map(({name, id, email, phone, address}) => {
      return {
        customerName: name,
        email: email,
        phone: phone,
        dob: "27/06/92",
        location: address.city,
        customerImage:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsUmE6FMTWUnXwdX_vkq9XMe_6AIFuuBY_lQ&usqp=CAU"
      }
    })
    setData(newData)
  }
  useEffect(() => {
    get()
  }, [])
  return (
    <AppLayout
      title="Customers"
      headComponent={
        <div>
          <SearchInput />
        </div>
      }
    >
      <Table columns={columns} data={data} isCheckedRow={false} />
    </AppLayout>
  )
}

export default Customers
