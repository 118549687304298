import {observer} from "mobx-react-lite"
import React, {useCallback, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"

import AppLayout from "../../layouts/App/AppLayout"
import {ReactComponent as Pencil} from "../../assets/images/actions/pencil.svg"
import {ReactComponent as Trash} from "../../assets/images/actions/trash.svg"
import {useStores} from "../../store"
import {DraggableTable} from "../../components/DraggableTable"
import classes from "./index.module.scss"

const Modifiers = observer(() => {
  const {
    modifiersStore: {getModifierSets, modifierSetsUpdateList, isLoading}
  } = useStores()

  const [isFirst, setIsFirst] = useState(true)
  const [modifiers, setModifiers] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (!isFirst) {
      return
    }
    setIsFirst(false)

    if (!isLoading && !modifierSetsUpdateList.length) {
      getModifierSets()
    }
  }, [getModifierSets, isFirst, isLoading, modifierSetsUpdateList])

  useEffect(() => {
    setModifiers(modifierSetsUpdateList)
  }, [modifierSetsUpdateList])

  const handleEdit = useCallback(
    (e, id) => {
      e.stopPropagation()
      navigate(`/menu/modifier-sets/update/${id}`)
    },
    [navigate]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        enableSorting: false,
        disableSortBy: true,
        width: 250,
        Cell: ({cell: {value}}) => (value ? value : "-")
      },
      {
        Header: "Type",
        accessor: "type",
        disableSortBy: true,
        enableSorting: false,
        width: 150,
        Cell: ({cell: {value}}) => (value ? value : "-")
      },
      {
        Header: "Count of modifiers",
        accessor: ({list}) => `${list.length}`,
        disableSortBy: true,
        enableSorting: false,
        width: 150,
        Cell: ({cell: {value}}) => (value ? value : "-")
      },
      {
        Header: " ",
        accessor: "action",
        width: 80,
        flex: "end",
        enableSorting: false,
        disableSortBy: true,
        className: "action_wrapper_td",
        Cell: ({row: {original}}) => {
          return (
            <div className={classes.actionsRow}>
              <div
                onClick={(e) => handleEdit(e, original.id)}
                className={`${classes.action} ${classes.actionChange}`}
              >
                <Pencil />
                {/*navigate(`/admin/users/${original.id}`)*/}
              </div>
              {/*<div*/}
              {/*  onClick={(e) => handleRemove(e, original.id)}*/}
              {/*  className={`${classes.action} ${classes.actionDelete}`}*/}
              {/*>*/}
              {/*  <Trash />*/}
              {/*</div>*/}
            </div>
          )
        }
      }
    ],
    [handleEdit]
  )

  const handleRemove = (e, id) => {
    e.stopPropagation()
  }

  return (
    <AppLayout
      title="Modifier sets"
      headComponent={
        <div className="head_component">
          <button
            onClick={() => navigate("/menu/modifier-sets/add")}
            className="admin_btn"
          >
            Add new
          </button>
        </div>
      }
    >
      <DraggableTable
        columns={columns}
        data={modifiers}
        setData={setModifiers}
      />
    </AppLayout>
  )
})

export default Modifiers
