import {useFormik} from "formik"
import React, {useEffect, useState} from "react"
import {toast} from "react-toastify"

import InputField from "../../components/InputField"
import AppLayout from "../../layouts/App/AppLayout"
import {wheelSchema} from "../../libs/schemas"
import {useStores} from "../../store"
import classes from "./index.module.scss"

const WheelOfFortune = () => {
  const [item, setItem] = useState(null)

  const {
    couponStore: {getWheelOfFortune, updateWheelOfFortune}
  } = useStores()

  useEffect(() => {
    const getData = async () => {
      const data = await getWheelOfFortune()

      if (data) {
        setItem(data)
      }
    }

    getData()
  }, [getWheelOfFortune])

  const {
    dirty,
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      sections: item?.sections || [],
      lifespan: item?.lifespan || []
    },
    enableReinitialize: true,
    validationSchema: wheelSchema,
    onSubmit: async (values) => {
      try {
        const data = {
          sections: values.sections,
          lifespan: values.lifespan
        }

        const res = await updateWheelOfFortune({data})
        if (res) {
          toast.success("Wheel of fortune successfully changed!")
        }
      } catch (err) {
        console.log(err.response)
      }
    }
  })

  const inputCount = 20

  const getSectionLabel = (number) => {
    if (number === 1) {
      return `${number}st section (%)`
    }

    if (number === 2) {
      return `${number}nd section (%)`
    }

    if (number === 3) {
      return `${number}rd section (%)`
    }

    return `${number}th section (%)`
  }

  const inputs = Array.from({length: inputCount}, (_, index) => (
    <InputField
      label={getSectionLabel(index + 1)}
      value={values?.sections[`${index}`] || 0}
      name={`sections[${index}]`}
      onChange={handleChange}
      type="number"
      errors={errors}
      key={index + 1}
      placeholder="Set %"
    />
  ))

  return (
    <AppLayout
      title="Wheel of Fortune"
      Tag="form"
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          <button
            disabled={!isValid || !dirty}
            className={classes.submit}
            type="submit"
          >
            Save settings
          </button>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.discountBlock}>
            <span className={classes.discountsTitle}>
              Set discount for each section:
            </span>

            <div className={classes.discounts}>{inputs}</div>
            {errors.sections && (
              <span className={classes.errorText}>
                Each section is required
              </span>
            )}
          </div>

          <InputField
            className={classes.lifespan}
            label="Lifespan (days)"
            value={values?.lifespan}
            name="lifespan"
            type="number"
            onChange={handleChange}
            errors={errors}
            placeholder="Set lifespan"
          />
        </div>
      </div>
    </AppLayout>
  )
}

export default WheelOfFortune
