import React, {useEffect} from "react"
import AppLayout from "layouts/App/AppLayout"
import CustomTimePicker from "components/CustomTimePicker"
import {observer} from "mobx-react-lite"
import {useStores} from "store"
import {toJS} from "mobx"
import { Formik, Form } from 'formik';
import s from './index.module.scss'

const WorkingHours = observer(() => {
  const {
    kitchensStore: {
      getSettingsList,
      updateHours,
      data
    }
  } = useStores()
  useEffect(() => {
    getSettingsList()
  }, [])

  const transformedData = Object.keys(data?.workingHours || {}).map(day => ({
    day: day,
    start: data.workingHours[day].start,
    end: data.workingHours[day].end
  })).filter(entry => entry.day !== 'timeZone');

  const formatDate = (date) => {
    if (!date) return { day: '', time: '' };

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day = dayNames[date.getDay()];
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const time = `${hours}:${minutes}`;

    return { day, time };
  };
  const timeStringToDate = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const now = new Date();
    now.setHours(hours);
    now.setMinutes(minutes);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
  };
  return   (
    <>
      <Formik
        initialValues={transformedData}
        enableReinitialize
        onSubmit={async (values) => {
          const result = values.reduce((acc, item) => {
            acc[item.day] = {
              start: item.start,
              end: item.end
            };
            return acc;
          }, {});
          await updateHours({workingHours:{...result, timeZone: "America/Los_Angeles"}})
        }}
      >
        {({values, setFieldValue, dirty }) => {
          const handleChangeDate = (type, date, day, i) => {
            setFieldValue(`${i}.${type}`, formatDate(date)?.time)
          }
          return (
            <Form>
              <AppLayout
                title='Working Hours'
                headComponent={
                <div className="head_component">
                  <button
                    className="admin_btn"
                    type='submit'
                    disabled={!dirty}
                  >
                    Save Settings
                  </button>
                </div>
              }
              >
                <div className={s.hours_wrapper}>
                  <div className={s.wrapper_inner}>
                    {values?.map((day, index) => {
                      const isWeekend = day.day === 'Sunday' || day.day === 'Saturday'
                      return (
                        <div key={day.day} className={s.day_row}>
                          <div className={s.day_label}>
                            <div className={`${s.day_name} ${isWeekend ? s.weekend : ''}`}>
                              {day.day.slice(0, 3)}
                            </div>
                          </div>
                          <div className={s.day_row}>
                            <div className={s.time_field}>
                              <CustomTimePicker
                                startDate={timeStringToDate(day.start)}
                                setStartDate={(date) => handleChangeDate('start', date, day.day, index)}
                                label='Opens at' placeholder='Select Time'  showTimeSelect showTimeSelectOnly/>
                            </div>
                            <div className={s.time_field}>
                              <CustomTimePicker
                                startDate={timeStringToDate(day.end)}
                                setStartDate={(date) => handleChangeDate('end', date, day.day, index)}
                                label='Closes at' placeholder='Select Time'  showTimeSelect showTimeSelectOnly/>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </AppLayout>

            </Form>
          )
        }}
      </Formik>
    </>

  )
})

export default WorkingHours