import {useFormik} from "formik"
import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {toast} from "react-toastify"

import InputField from "../../components/InputField"
import CustomSelect from "../../components/Select"
import AppLayout from "../../layouts/App/AppLayout"
import {personalSchema} from "../../libs/schemas"
import {useStores} from "../../store"
import Radio from "../../components/Radio"
import classes from "./index.module.scss"

const PersonalCoupons = () => {
  const [needToValidate, setNeedToValidate] = useState(false)

  const {
    categoriesStore: {getCategories, categoriesUpdateList},
    usersStore: {getUsers, usersUpdateList},
    couponStore: {addCoupon}
  } = useStores()

  useEffect(() => {
    getCategories()
  }, [getCategories])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const {
    dirty,
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue
  } = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema: personalSchema,
    validateOnChange: needToValidate,
    validateOnBlur: needToValidate,
    onSubmit: async (values) => {
      setNeedToValidate(true)
      try {
        let data = {
          description: values.description,
          lifespan: values.lifespan,
          userId: values.user?.value,
          pushTitle: values.pushTitle,
          pushMessage: values.pushMessage,
          discountType: values.discountType,
          categoryId: values.category?.value,
          fullDescription: getPreview()
        }

        if (values.value) {
          data.value = values.value
        }

        const res = await addCoupon({subtype: "gift", data})

        if (res) {
          toast.success("Personal coupon successfully added!")
        }
      } catch (err) {
        console.log(err.response)
      }
    }
  })

  const getPreview = () => {
    if (!values || !values.discountType) {
      return ""
    }
    if (values.discountType === "amount") {
      return values.category?.value
        ? `${values.description} (${values.value}$ - ${values.category.description})`
        : `${values.description} (${values.value}$ on all order)`
    }
    if (values.discountType === "percentage") {
      return values.category?.value
        ? `${values.description} (${values.value}% - ${values.category.description})`
        : `${values.description} (${values.value}% on all order)`
    }
    if (!values.category?.value) {
      return ""
    }
    return `${values.description} (FREE - ${values.category.description})`
  }
  return (
    <AppLayout
      title="Generator"
      Tag="form"
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          <button
            disabled={!isValid || !dirty}
            className={classes.submit}
            type="submit"
            onClick={() => setNeedToValidate(true)}
          >
            Submit
          </button>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.links}>
          <Link to={"/coupons/generator/special/add"}>Special</Link>
          <button>Personal</button>
          <Link to={"/coupons/generator/regular/add"}>Regular</Link>
          <Link to={"/coupons/generator/birthday/add"}>Birthday</Link>
          <Link to={"/coupons/generator/registration/add"}>Register</Link>
        </div>

        <div className={classes.content}>
          <div className={classes.leftPart}>
            <InputField
              label="Description"
              value={values?.description}
              name="description"
              onChange={handleChange}
              errors={errors}
              placeholder="Set promocode description"
            />

            <InputField
              label="Lifespan (days)"
              value={values?.lifespan}
              name="lifespan"
              type="number"
              onChange={handleChange}
              errors={errors}
              placeholder="Set lifespan"
            />

            <CustomSelect
              label="User"
              name="user"
              options={[
                {value: undefined, label: "-"},
                ...usersUpdateList.map((c) => ({
                  ...c,
                  label: `${c.firstname || ""} ${c.lastname || ""}, ${
                    c.phone || ""
                  }`,
                  value: c.id
                }))
              ]}
              errors={errors}
              values={values?.user}
              placeholder="Username or phone number"
              setFieldValue={setFieldValue}
            />

            <InputField
              label="Push title"
              value={values?.pushTitle}
              name="pushTitle"
              onChange={handleChange}
              errors={errors}
              placeholder="Enter Push Title"
            />

            <InputField
              label="Push body"
              value={values?.pushMessage}
              name="pushMessage"
              type="textarea"
              onChange={handleChange}
              errors={errors}
              placeholder="Enter Push Text"
            />
          </div>

          <div className={classes.rightPart}>
            <div className={classes.rightTopPart}>
              <Radio
                label="Discount type"
                values={[
                  {value: "amount", label: "Amount"},
                  {value: "percentage", label: "Percentage"},
                  {value: "item", label: "Item"}
                ]}
                errors={errors}
                value={values?.discountType}
                name="discountType"
                handleChange={handleChange}
              />

              <InputField
                label="Discount amount"
                value={values?.value}
                name="value"
                onChange={handleChange}
                errors={errors}
                disabled={
                  !["amount", "percentage"].includes(values?.discountType)
                }
                withIcon
                placeholder="Set discount amount"
                type={
                  values?.discountType === "amount"
                    ? "discountAbs"
                    : values?.discountType === "percentage"
                    ? "discountPer"
                    : null
                }
              />

              <CustomSelect
                label="Category"
                name="category"
                options={[
                  {value: undefined, label: "ALL"},
                  ...categoriesUpdateList.map((c) => ({
                    ...c,
                    label: c.description,
                    value: c.id
                  }))
                ]}
                values={values?.category}
                errors={errors}
                placeholder="Category selector"
                setParam="id"
                setFieldValue={setFieldValue}
              />
            </div>

            <InputField
              label="Full description preview"
              value={getPreview()}
              name="preview"
              type="textarea"
              onChange={() => null}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default PersonalCoupons
