import './index.scss'

const CheckboxSwitcher = ({label}) => {
    return(
        <div>
            <input type='checkbox' className='ios8-switch ' id='checkbox-2' />
            <label htmlFor='checkbox-2'>{label}</label>
        </div>
    )
}

export default CheckboxSwitcher