import {observer} from "mobx-react-lite"
import React, {useCallback, useEffect, useState} from "react"
import {toast} from "react-toastify"

import AppLayout from "../../layouts/App/AppLayout"
import {ReactComponent as Trash} from "../../assets/images/actions/trash.svg"
import {useStores} from "../../store"
import {DraggableTable} from "../../components/DraggableTable"
import SearchInput from "../../components/Search"
import CustomSelect from "../../components/Select"
import classes from "./index.module.scss"

const types = [
  {value: null, label: "All"},
  {value: "register", label: "First registration"},
  {value: "birthday", label: "Birthday gift"},
  {value: "wheel", label: "Wheel of Fortune"},
  {value: "special", label: "Special"},
  {value: "gift", label: "Personal"},
  {value: "regular", label: "Regular"}
]

const Coupons = observer(() => {
  const {
    couponStore: {getAllCoupons, couponsUpdateList, deleteCoupon}
  } = useStores()

  const [coupons, setCoupons] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [type, setType] = useState(null)

  useEffect(() => {
    getAllCoupons({})
  }, [getAllCoupons])

  useEffect(() => {
    setCoupons(couponsUpdateList)
  }, [couponsUpdateList])

  const handleRemove = useCallback(
    async (id) => {
      const res = await deleteCoupon(id)
      if (res) {
        toast.success("Coupon successfully deleted!")
        getAllCoupons({})
      }
    },
    [deleteCoupon, getAllCoupons]
  )

  const handleSearch = useCallback(
    (search) => {
      if (type) {
        return getAllCoupons({search, type: type.value})
      }
      getAllCoupons({search})
    },
    [getAllCoupons, type]
  )

  useEffect(() => {
    if (searchValue && type) {
      getAllCoupons({search: searchValue, type: type.value})
    } else if (type) {
      getAllCoupons({type: type.value})
    }
  }, [getAllCoupons, type])

  const handleChangeFilter = (name, value) => {
    return setType(value)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "subtype",
        enableSorting: false,
        disableSortBy: true,
        Cell: ({cell: {value}}) => (
          <span>
            {value === "wheel"
              ? "Wheel of Fortune"
              : value === "special"
              ? "Special"
              : value === "birthday"
              ? "Birthday gift"
              : value === "regular"
              ? "Regular"
              : value === "gift"
              ? "Personal"
              : value === "register"
              ? "First registration"
              : "-"}
          </span>
        )
      },
      {
        Header: "Description",
        accessor: "description",
        disableSortBy: true,
        width: 250,
        enableSorting: false,
        Cell: ({cell: {value}}) => <span>{value ? value : "-"}</span>
      },
      {
        Header: "Coupon code",
        accessor: "code",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({cell: {value}}) => <span>{value ? value : "-"}</span>
      },
      {
        Header: "Value",
        accessor: "value",
        disableSortBy: true,
        enableSorting: false,
        Cell: ({cell: {value}}) => <span>{value ? value : "Various"}</span>
      },
      {
        Header: " ",
        accessor: "action",
        width: 80,
        flex: "end",
        enableSorting: false,
        disableSortBy: true,
        className: "action_wrapper_td",
        Cell: ({row: {original}}) => {
          return (
            <div className={classes.actionsRow}>
              <div
                onClick={() => handleRemove(original.id)}
                className={`${classes.action} ${classes.actionDelete}`}
              >
                <Trash />
              </div>
            </div>
          )
        }
      }
    ],
    []
  )

  return (
    <AppLayout
      title="All coupons"
      headComponent={
        <div className="head_component">
          <SearchInput
            onSearch={handleSearch}
            setValue={setSearchValue}
            value={searchValue}
          />

          <div className={classes.filterBlock}>
            <span>Filter by type</span>
            <CustomSelect
              size="s"
              placeholder="Select type"
              value={type}
              options={types}
              name="type"
              setFieldValue={handleChangeFilter}
              above
            />
          </div>
        </div>
      }
    >
      <DraggableTable columns={columns} data={coupons} setData={setCoupons} />
    </AppLayout>
  )
})

export default Coupons
