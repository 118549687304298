import React from "react"

import Popup from "reactjs-popup"
import more from "../../../assets/images/more.svg"
import {SaveButton} from "../../../components/Buttons"
import classes from "../index.module.scss"

const AddModifiersSets = ({
  values,
  handleModSetEdit,
  handleModSetRemove,
  setIsModifiersModalOpen
}) => (
  <div className={classes.tableOverlay}>
    <span className={classes.tableName}>Assign modifiers set</span>

    {values.modifiers.map((m) => (
      <div className={classes.textInRow} key={m.id}>
        <div className={classes.textInColumn}>
          <span className={classes.tableHeadText}>{m.modifier.name}</span>
          <span className={classes.tableText}>
            {m.modifier.list
              .filter((i, idx) => idx < 5)
              .map((m) => m.title)
              .join(", ")}
            ,{" "}
            {m.modifier.list.length > 5 && (
              <span className={classes.moreModifiersValue}>
                + {m.modifier.list.length - 5} more
              </span>
            )}
          </span>
        </div>
        <Popup
          trigger={
            <button type="button" className={classes.modifiersButton}>
              <img src={more} alt="more" />
            </button>
          }
          position={["top center", "bottom right", "bottom left"]}
          closeOnDocumentClick
        >
          {(close) => (
            <div className={classes.tooltipActions}>
              <button
                onClick={() => {
                  handleModSetEdit(m)
                  close()
                }}
              >
                Customize
              </button>
              <hr className={classes.hr} />
              <button
                onClick={() => {
                  handleModSetRemove(m.modifierId)
                  close()
                }}
              >
                Remove from item
              </button>
            </div>
          )}
        </Popup>
      </div>
    ))}

    <SaveButton text="Add new" onClick={() => setIsModifiersModalOpen(true)} />
  </div>
)

export default AddModifiersSets
