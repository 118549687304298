import {fetchAxios} from "../axios"

export const getAllCouponsList = async ({search, type}) => {
  try {
    let url = "/coupons"

    if (search || type) {
      url += "?"
    }

    if (search) {
      url += `search=${search}`

      if (type) {
        url += `&subtype=${type}`
      }
    } else if (type) {
      url += `subtype=${type}`
    }

    const res = await fetchAxios({
      url
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getTemplatesList = async ({search}) => {
  try {
    const res = await fetchAxios({
      url: search ? `/coupons-templates?search=${search}` : "/coupons-templates"
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const deleteCouponById = async (id) => {
  try {
    const res = await fetchAxios({
      url: `/coupons/${id}`,
      method: "DELETE"
    })

    return res.data
  } catch (e) {}
}

export const deleteCouponTemplateById = async (id) => {
  try {
    const res = await fetchAxios({
      url: `/coupons-templates/${id}`,
      method: "DELETE"
    })

    return res.data
  } catch (e) {}
}

export const getCouponTemplateById = async (id) => {
  try {
    const res = await fetchAxios({
      url: `/coupons-templates/${id}`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateCouponTemplateById = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/coupons-templates/${id}`,
      method: "PUT",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getCouponByType = async (subtype) => {
  try {
    const res = await fetchAxios({
      url: `/coupons-generator?subtype=${subtype}`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const createCoupon = async ({subtype, data}) => {
  try {
    const res = await fetchAxios({
      url: `/coupons-generator?subtype=${subtype}`,
      method: "POST",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getWheel = async () => {
  try {
    const res = await fetchAxios({
      url: `/wheel-of-fortune`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateWheel = async ({data}) => {
  try {
    const res = await fetchAxios({
      url: `/wheel-of-fortune`,
      method: "PUT",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}
