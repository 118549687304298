import './index.scss'
import AppLayout from "../../layouts/App/AppLayout";

const OrderStatus = ({status}) => {
    return(
        <div className='order_status'>
            {status}
        </div>
    )
}

const OrderDetails = () => {
    return (
        <AppLayout
            title='Orders details'
            isBack
            linkBack={'/orders'}
            headComponent={<OrderStatus status='Ready' />}
        >
            <div className='order_details-content'>
                <ul className='details_row'>
                    <li className='row_item'>
                        <h2>Customer Name</h2>
                        <span>Amanda Collins</span>
                    </li>
                    <li className='row_item'>
                        <h2>Order ID</h2>
                        <span>#1701</span>
                    </li>
                    <li className='row_item'>
                        <h2>Delivery Assistance</h2>
                        <span>Johnny Cook</span>
                    </li>
                    <li className='row_item'>
                        <h2>Payment Method</h2>
                        <span>Mastercard</span>
                    </li>
                    <li className='row_item'>
                        <h2>Coupon Used</h2>
                        <span>mycoupon</span>
                    </li>
                </ul>
                <div className="order_details">
                    <table className='bill_table'>
                        <thead>
                        <tr>
                            <th>
                                Bill Details
                            </th>
                            <th>
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className='head_tr'>
                            <td>Product Name</td>
                            <td className='w-200-px'>Qty</td>
                            <td className='w-200-px'>Price</td>
                        </tr>
                        <tr className='border_bottom p-15'>
                            <td>
                                Peppy Pizza <span>($30.00 x 1)</span>
                            </td>
                            <td>
                                1
                            </td>
                            <td>
                                $20.00
                            </td>
                        </tr>
                        <tr className='border_bottom p-15'>
                            <td>
                                Sandwich <span> ($10.00 x 1)</span>
                            </td>
                            <td>2</td>
                            <td>
                                $10.00
                            </td>
                        </tr>
                        <tr className='tr_total'>
                            <td>Sub Total:</td>
                            <td/>
                            <td>$30.00</td>
                        </tr>
                        <tr className='tr_total'>
                            <td>Discount:</td>
                            <td/>
                            <td>$0</td>
                        </tr>
                        <tr className='tr_total'>
                            <td>Total:</td>
                            <td/>
                            <td>$30.00</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="address_details">
                    <div className="address_content">
                        <div className="address_head">
                            BILLING ADDRESS
                        </div>
                        <div className="address_description">
                            <div className="name">
                                Johnny Cook
                            </div>
                            <p>1623 E Updahl Ct, Harrison, Id, 83833</p>
                            <p>Abc@Gmail.Com</p>
                            <p>8193-198472</p>
                        </div>
                    </div>
                    <div className="address_content">
                        <div className="address_head">
                            Delivery ADDRESS
                        </div>
                        <div className="address_description">
                            <div className="name">
                                Johnny Cook
                            </div>
                            <p>1623 E Updahl Ct, Harrison, Id, 83833</p>
                            <p>Abc@Gmail.Com</p>
                            <p>8193-198472</p>
                        </div>
                    </div>
                </div>
                <div className="order_details">
                    <table className='status_table'>
                        <thead>
                        <tr>
                            <th>
                                Order Status
                            </th>
                            <th>
                            </th>
                            <th>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>January 17, 2020 - 18:00</td>
                            <td className='dot_td'>
                                <div className="dot"/>
                            </td>
                            <td>Order Accepted</td>
                        </tr>
                        <tr>
                            <td>January 17, 2020 - 18:00</td>
                            <td className='dot_td'>
                                <div className="dot"/>
                            </td>
                            <td>Order Accepted</td>
                        </tr>
                        <tr>
                            <td>January 17, 2020 - 18:00</td>
                            <td className='dot_td'>
                                <div className="dot"/>
                            </td>
                            <td>Order Accepted</td>
                        </tr>
                        <tr>
                            <td>January 17, 2020 - 18:00</td>
                            <td className='dot_td'>
                                <div className="dot"/>
                            </td>
                            <td>Order Accepted</td>
                        </tr>
                        <tr>
                            <td>January 17, 2020 - 18:00</td>
                            <td className='dot_td'>
                                <div className="dot"/>
                            </td>
                            <td>Order Accepted</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </AppLayout>
    )
}

export default OrderDetails