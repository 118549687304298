import React from "react"
import {useSortable} from "@dnd-kit/sortable"
import {CSS} from "@dnd-kit/utilities"

import {DragHandle} from "./DragHandle"
import classes from "./index.module.scss"

export const DraggableTableRow = ({row, accessDrag}) => {
  const {attributes, listeners, transform, transition, setNodeRef, isDragging} =
    useSortable({
      id: row.original.id
    })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition
  }
  return (
    <tr
      ref={setNodeRef}
      style={style}
      {...row.getRowProps()}
      className={classes.tableRow}
    >
      {isDragging ? (
        <td colSpan={row.cells.length} className={classes.draggingRow}>
          &nbsp;
        </td>
      ) : (
        row.cells.map((cell, i) => {
          const widthClass = `th${[cell.column.width]}`
          const flexClass = `flex-${[cell.column.flex]}`

          if (i === 0 && accessDrag) {
            return (
              <td
                {...cell.getCellProps()}
                className={`${classes.tableData} ${classes[widthClass]} ${classes[flexClass]}`}
              >
                <DragHandle {...attributes} {...listeners} />
                <span>{cell.render("Cell")}</span>
              </td>
            )
          }
          return (
            <td
              {...cell.getCellProps()}
              className={`${classes.tableData} ${classes[widthClass]} ${classes[flexClass]}`}
            >
              {cell.render("Cell")}
            </td>
          )
        })
      )}
    </tr>
  )
}
