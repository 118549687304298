import {Link} from "react-router-dom"

import logo from "../../assets/images/logo.png"
import classes from "./index.module.scss"

const NotFound = () => {
  return (
    <div className={classes.container}>
      <img src={logo} alt="logo" />
      <h2>404 - Page not found</h2>
      <Link to={"/menu/items"}>Go to items page</Link>
    </div>
  )
}

export default NotFound
