import Select, {components} from "react-select"
import makeAnimated from "react-select/animated"
import checkRed from "../../assets/images/checkRed.svg"

import classes from "./index.module.scss"
import error from "../../assets/images/error.svg"

const {Option} = components

const animatedComponents = makeAnimated({
  IndicatorSeparator: () => null,
  Option: (props) => {
    if (props.isMulti) {
      return <Option {...props} />
    }

    return (
      <Option {...props}>
        {props.isSelected && (
          <img
            src={checkRed}
            alt=""
            style={{
              position: "absolute",
              left: "16px",
              top: "50%",
              transform: "translateY(-50%)"
            }}
          />
        )}
        <span>{props.data.label}</span>
      </Option>
    )
  }
})

const customStyles = {
  singleValue: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "#DCDCDC" : "#5D5B5B"
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "#DCDCDC" : "#9B9B9B"
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.selectProps.menuIsOpen
      ? "0 0 0 1px #5D5B5B"
      : "0 0 0 1px #DCDCDC",
    border: "none",
    transition: "all .3s",
    borderRadius: "10px",
    fontWeight: "500",
    fontSize: "14px",
    color: "#9B9B9B",
    fontFamily: "Rubik, sans-serif",
    minHeight: "44px",
    ":hover": {
      boxShadow: state.selectProps.menuIsOpen
        ? "0 0 0 1px #5D5B5B"
        : "0 0 0 1px #9B9B9B"
    },
    ":focus": {
      boxShadow: "0 0 0 1px #5D5B5B"
    }
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? "#DCDCDC" : "#E5001C",
    transition: "all .3s",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
    ":hover": {
      color: "#E5001C"
    }
  }),
  menu: (styles, state) => ({
    ...styles,
    zIndex: '3'
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: "unset",
    color: "#5D5B5B",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    paddingLeft: state.isMulti ? "8px" : "48px",
    position: "relative",
    cursor: "pointer",
    ":hover": {
      color: "#E5001C"
    },
    ":active": {
      color: "#E5001C"
    }
  })
}

const CustomSelect = ({
  options,
  values = [],
  errors,
  value,
  isMulti = false,
  label,
  placeholder = "",
  name,
  size = "full",
  afterText,
  setFieldValue,
  disabled,
  above,
                        isClerable,
                        menuPlacement = 'bottom'
}) => {
  return (
    <div
      className={`${classes.container} ${classes[size]} ${
        errors?.[name]?.value || errors?.[name] ? classes.field_error : ""
      } ${above ? classes.above : ""}`}
    >
      {label}
      <Select
        isClearable={isClerable}
        // menuIsOpen={true}
        closeMenuOnSelect={!isMulti}
        options={options}
        placeholder={placeholder}
        components={animatedComponents}
        value={isMulti ? values : value}
        isMulti={isMulti}
        styles={customStyles}
        menuPlacement={menuPlacement}
        isDisabled={disabled}
        onChange={(el) =>
          isMulti ? setFieldValue(name, [...el]) : setFieldValue(name, el)
        }
      />
      {afterText ? <span className={classes.afterText}>{afterText}</span> : ""}
      {(errors?.[name]?.value || errors?.[name]) && (
        <div className={classes.field_error_text}>
          <img src={error} alt=" " />
          {errors?.[name]?.value || errors?.[name]}
        </div>
      )}
    </div>
  )
}

export default CustomSelect
