import {fetchAxios} from "../axios"

export const getUsersList = async () => {
  try {
    const res = await fetchAxios({
      url: "/users"
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getUser = async (id) => {
  try {
    const res = await fetchAxios({
      url: `/members/${id}`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateUser = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/members/${id}`,
      method: "PUT",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const deleteUser = async ({id}) => {
  try {
    const res = await fetchAxios({
      url: `/members/${id}`,
      method: "DELETE"
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}
