import {observer} from "mobx-react-lite"
import React, {useCallback, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"

import AppLayout from "../../layouts/App/AppLayout"
import {ReactComponent as Pencil} from "../../assets/images/actions/pencil.svg"
import {useStores} from "../../store"
import {DraggableTable} from "../../components/DraggableTable"
import SearchInput from "../../components/Search"
import CustomSelect from "../../components/Select"
import emptyErrorImage from "../../assets/images/emptyErrorImage.png"
import emptyImage from "../../assets/images/emptyImage.png"
import classes from "./index.module.scss"

const Modifiers = observer(() => {
  const {
    modifiersStore: {
      getModifiers,
      modifiersUpdateList,
      getModifierSets,
      modifierSetsUpdateList,
      getItemsWithModSet,
      isLoading
    },
    menuStore: {getMenu, menuUpdateList}
  } = useStores()

  const [isFirst, setIsFirst] = useState(true)
  const [modifiers, setModifiers] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [set, setSet] = useState(null)
  const [item, setItem] = useState(null)
  const [itemsWithSets, setItemsWithSets] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    if (!isFirst) {
      return
    }
    setIsFirst(false)

    if (!isLoading && !searchValue) {
      getModifiers({})
    }
  }, [getModifiers, isFirst, isLoading, searchValue])

  useEffect(() => {
    getModifierSets()
  }, [getModifierSets])

  useEffect(() => {
    getMenu({})
  }, [getMenu])

  useEffect(() => {
    setModifiers(modifiersUpdateList)
  }, [modifiersUpdateList])

  const handleEdit = useCallback(
    (e, id) => {
      e.stopPropagation()
      navigate(`/menu/modifiers/update/${id}`)
    },
    [navigate]
  )

  const handleSearch = useCallback(
    (search) => {
      if (item && set) {
        return getModifiers({search, item: item.id, set: set.id})
      }
      if (item) {
        return getModifiers({search, item: item.id})
      }
      if (set) {
        return getModifiers({search, set: set.id})
      }
      getModifiers({search})
    },
    [getModifiers, item, set]
  )

  useEffect(() => {
    if (searchValue && item && set) {
      getModifiers({search: searchValue, item: item.id, set: set.id})
    } else if (searchValue && item) {
      getModifiers({search: searchValue, item: item.id})
    } else if (searchValue && set) {
      getModifiers({search: searchValue, set: set.id})
    } else if (item && set) {
      getModifiers({item: item.id, set: set.id})
    } else if (set) {
      getModifiers({set: set.id})
    } else if (item) {
      getModifiers({item: item.id})
    }
  }, [getModifiers, item, set])

  const handleChangeFilter = (name, value) => {
    if (name === "set") {
      setItem(null)
      return setSet(value)
    }
    return setItem(value)
  }

  useEffect(() => {
    const handleSetItems = async () => {
      const data = await getItemsWithModSet(set)

      setItemsWithSets(data)
    }

    set?.value ? handleSetItems() : setItemsWithSets(null)
  }, [getItemsWithModSet, set])

  const getItems = () => {
    if (set?.value) {
      return itemsWithSets?.length
        ? [
            ...itemsWithSets.map((item) => ({
              ...item,
              label: item.title,
              value: item.id
            }))
          ]
        : []
    }
    return [
      ...menuUpdateList
        .map((item) => ({
          ...item,
          label: item.title,
          value: item.id
        }))
        .filter((i) => (set ? i : i))
    ]
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        enableSorting: false,
        disableSortBy: true,
        Cell: ({row: {original}}) => {
          return (
            <div className={classes.nameBlock}>
              {original.image ? (
                <img
                  src={original.image}
                  alt={original.title}
                  onError={({currentTarget}) => {
                    if (currentTarget.src !== emptyErrorImage) {
                      currentTarget.onError = null // prevents looping
                      currentTarget.src = emptyErrorImage
                    }
                  }}
                />
              ) : (
                <img src={emptyImage} alt="Default Image" />
              )}
              {original.title}
            </div>
          )
        }
      },
      {
        Header: "Description",
        accessor: "description",
        disableSortBy: true,
        enableSorting: false,
        width: 250,
        Cell: ({cell: {value}}) => (
          <span className={classes.description}>{value ? value : "-"}</span>
        )
      },
      {
        Header: "Price",
        accessor: "price",
        disableSortBy: true,
        enableSorting: false,
        width: 100,
        Cell: ({cell: {value}}) =>
          value ? `$${(value / 100).toFixed(2)}` : "-"
      },
      {
        Header: " ",
        accessor: "action",
        width: 80,
        flex: "end",
        enableSorting: false,
        disableSortBy: true,
        className: "action_wrapper_td",
        Cell: ({row: {original}}) => {
          return (
            <div className={classes.actionsRow}>
              <div
                onClick={(e) => handleEdit(e, original.id)}
                className={`${classes.action} ${classes.actionChange}`}
              >
                <Pencil />
              </div>
            </div>
          )
        }
      }
    ],
    [handleEdit]
  )

  return (
    <AppLayout
      title="Modifiers"
      headComponent={
        <div className="head_component">
          <SearchInput
            Tag="form"
            onSearch={handleSearch}
            setValue={setSearchValue}
            value={searchValue}
          />

          <div className={classes.filterBlock}>
            <span>Filter by set</span>
            <CustomSelect
              size="s"
              placeholder="Select modifiers set"
              value={set}
              options={[
                {value: undefined, label: "-"},
                ...modifierSetsUpdateList.map((s) => ({
                  ...s,
                  label: s.name,
                  value: s.id
                }))
              ]}
              name="set"
              setFieldValue={handleChangeFilter}
              above
            />
          </div>

          <div className={classes.filterBlock}>
            <span>Filter by item</span>
            <CustomSelect
              size="s"
              placeholder="Select item"
              value={item}
              options={[{value: undefined, label: "-"}, ...getItems()]}
              name="item"
              setFieldValue={handleChangeFilter}
              above
            />
          </div>

          <button
            onClick={() => navigate("/menu/modifiers/add")}
            className="admin_btn"
          >
            Add new
          </button>
        </div>
      }
    >
      <DraggableTable
        columns={columns}
        data={modifiers}
        setData={setModifiers}
      />
    </AppLayout>
  )
})

export default Modifiers
