import React, {useEffect, useRef, useState} from "react"
import {observer} from "mobx-react-lite"
import {useFormik} from "formik"
import {useNavigate, useParams} from "react-router-dom"
import {toast} from "react-toastify"

import AppLayout from "../../layouts/App/AppLayout"
import {categoriesSchema} from "../../libs/schemas"
import InputField from "../../components/InputField"
import {useStores} from "../../store"
import UploadInput from "../../components/UploadInput"
import CustomSelect from "../../components/Select"
import {visibilityValues} from "../../constants"
import classes from "./index.module.scss"

const CategoriesCreateEdit = observer(() => {
  const [item, setItem] = useState(null)
  const {
    categoriesStore: {getCategory, updateCategory}
  } = useStores()

  const navigate = useNavigate()

  const {id} = useParams()
  const uploadingFileInputRefs = useRef([])

  useEffect(() => {
    const getItemData = async () => {
      const itemData = await getCategory(id)
      if (itemData) {
        setItem(itemData)
      }
    }

    getItemData()
  }, [getCategory, id])

  const {
    dirty,
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: {
      description: item?.description || "",
      subtitle: item?.subtitle || "",
      fullDescription: item?.fullDescription || "",
      image: "",
      imagePreview: item?.image || "",
      visibility: item?.visibility
        ? visibilityValues.find((v) => v.value === item.visibility)
        : null
    },
    enableReinitialize: true,
    validationSchema: categoriesSchema,
    onSubmit: async (values) => {
      try {
        const textData = {
          description: values.description,
          subtitle: values.subtitle,
          fullDescription: values.fullDescription
        }

        const data = new FormData()

        for (const key in textData) {
          data.append(key, values[key])
        }

        if (values.image) {
          data.append("image", values.image)
        }

        data.append("visibility", values.visibility.value)

        return updateCategory({id, data}).then((res) => {
          if (res) {
            toast.success("Category successfully changed!")
            navigate("/menu/categories")
          }
        })
      } catch (err) {
        console.log(err.response)
      }
    }
  })

  const fileInputHandler = (event, name, preview) => {
    if(name === 'delete'){
      setFieldValue(preview, '')
      return;
    }
    setFieldValue(name, event.currentTarget.files[0])
    if (preview) {
      setFieldValue(preview, URL.createObjectURL(event.currentTarget.files[0]))
    }
    uploadingFileInputRefs.current = [
      ...uploadingFileInputRefs.current,
      event.currentTarget
    ]
  }

  const handleRevert = () => {
    resetForm()
  }

  return (
    <AppLayout
      title={id ? item?.description : "Add item"}
      Tag="form"
      linkBack="/menu/categories"
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          <button
            className={classes.revert}
            type="button"
            onClick={handleRevert}
          >
            Revert changes
          </button>

          <button
            disabled={!isValid || !dirty}
            className={classes.submit}
            type="submit"
          >
            {id ? "Save changes" : "Create"}
          </button>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <UploadInput
            label="Category image"
            name="image"
            preview="imagePreview"
            imageSrc={values?.imagePreview}
            errors={errors}
            fileInputHandler={fileInputHandler}
          />

          <InputField
            label="Description"
            value={values?.description}
            name="description"
            onChange={handleChange}
            errors={errors}
            placeholder="Category description"
          />

          <InputField
            label="Subtitle"
            value={values?.subtitle}
            name="subtitle"
            onChange={handleChange}
            errors={errors}
            placeholder="Subtitle"
          />

          <InputField
            label="Full description"
            value={values?.fullDescription}
            name="fullDescription"
            type="textarea"
            onChange={handleChange}
            errors={errors}
            placeholder="Category full description"
          />

          <CustomSelect
            label="Visibility*"
            name="visibility"
            options={visibilityValues}
            value={values?.visibility}
            placeholder="Coming Soon / Active /  Hidden"
            size="md"
            setFieldValue={setFieldValue}
            afterText="This item will be unavailable for purchase and will not be displayed on all of your sites."
          />
        </div>
      </div>
    </AppLayout>
  )
})

export default CategoriesCreateEdit
