import React, {useEffect, useRef, useState} from "react"
import Tab from "./Tab"
import {useLocation, useNavigate} from "react-router-dom"
import "./index.scss"

const Tabs = ({children, onChange}) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label)

  const ref = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.state?.categoryName) {
      setActiveTab(location.state.categoryName)
    }
  }, [location.state?.categoryName])

  const handleScroll = (event) => {
    const list = ref.current
    const scrollAmount = event.deltaY * 2
    list.scrollTo({
      top: 0,
      left: list.scrollLeft + scrollAmount,
      behavior: "smooth"
    })
  }

  const onClickTabItem = (tab, name, id) => {
    navigate(location.pathname, {
      state: {categoryName: tab, categoryNameBack: name, categoryId: id}
    })
    onChange(id)
  }

  return (
    <div className="tabs">
      <ol className="tab-list" onWheel={handleScroll} ref={ref}>
        {children.map((child) => {
          const {label, name, id} = child.props

          return (
            <Tab
              activeTab={activeTab}
              key={label}
              label={label}
              onClick={() => onClickTabItem(label, name, id)}
            />
          )
        })}
      </ol>
      <div className="tab-content">
        {children.map((child) => {
          if (child.props.label !== activeTab) return undefined
          return child.props.children
        })}
      </div>
    </div>
  )
}

export default Tabs
