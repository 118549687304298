import React from "react"

import UploadInput from "../../../components/UploadInput"
import InputField from "../../../components/InputField"
import CustomSelect from "../../../components/Select"
import {visibilityValues} from "../../../constants"
import {CancelButton, SaveButton} from "../../../components/Buttons"
import CustomModal from "../../../components/Modal"
import classes from "../index.module.scss"

const VariationsModal = ({
  isVariationModalOpen,
  variationToUpdate,
  errors,
  variationImageInputHandler,
  handleChangeVariation,
  setVariationFieldValue,
  handleCancelVariation,
  handleSaveVariation
}) => (
  <CustomModal open={isVariationModalOpen}>
    <h3>{variationToUpdate?.id ? variationToUpdate?.name : "Add variation"}</h3>

    <UploadInput
      label="Image"
      name="image"
      preview="imagePreview"
      errors={errors}
      imageSrc={variationToUpdate?.imagePreview}
      fileInputHandler={variationImageInputHandler}
      size="middle"
    />

    <InputField
      label="Title*"
      value={variationToUpdate?.name}
      name="name"
      onChange={handleChangeVariation}
      errors={errors}
      placeholder="Variation title"
    />

    <InputField
      label="Size"
      value={variationToUpdate?.size}
      name="size"
      type="number"
      onChange={handleChangeVariation}
      errors={errors}
      placeholder="Variation size"
    />

    <InputField
      label="Price"
      value={variationToUpdate?.price}
      name="price"
      type="price"
      withIcon
      onChange={handleChangeVariation}
      errors={errors}
      placeholder="Variation price"
    />

    <CustomSelect
      label="Visibility*"
      name="visibility"
      options={visibilityValues}
      value={
        variationToUpdate?.visibility
          ? visibilityValues.find((v) => {
              return v.value === variationToUpdate.visibility
            })
          : null
      }
      placeholder="Coming Soon / Active /  Hidden"
      size="md"
      setFieldValue={setVariationFieldValue}
      afterText="This item will be unavailable for purchase and will not be displayed on all of your sites."
    />

    <div className={classes.actionButtons}>
      <CancelButton type="button" onClick={handleCancelVariation} />

      <SaveButton
        type="button"
        onClick={handleSaveVariation}
        text={variationToUpdate?.id ? "Save changes" : "Add"}
      />
    </div>
  </CustomModal>
)

export default VariationsModal
