import {observer} from "mobx-react"
import jwtDecode from "jwt-decode"

import LOGO from "../../assets/images/logo.png"
import {RouteEnums} from "../../libs/routeEnums"
import Link from "../Link"
import {useStores} from "../../store"
import classes from "./index.module.scss"

const Sidebar = observer(() => {
  const {
    barStore: {showBar, toggleBar, activeBlocks, setActiveBlocks},
    authStore: {setAuth, accessToken}
  } = useStores()

  const handleLogout = () => {
    setAuth({token: null})
    localStorage.removeItem("token")
    window.location.reload(true)
  }

  const token = accessToken || localStorage.getItem("token")

  let role = null

  if (token) {
    role = jwtDecode(token)?.role
  }

  return (
    <aside className={`app_bar ${showBar ? "" : "w-0"}`}>
      <div className="btn_action_bar" onClick={() => toggleBar()}></div>

      <img className="app_logo" src={LOGO} alt="" />

      <ul className="bar_column">
        {RouteEnums.map(
          ({link, name, Icon, SubIcon, subLinks, accessRoles}) => {
            if (accessRoles && !accessRoles.includes(role)) {
              return <></>
            }
            return (
              <Link
                key={name}
                role={role}
                subLinks={subLinks}
                link={link}
                name={name}
                Icon={Icon}
                SubIcon={SubIcon}
                setActiveBlocks={setActiveBlocks}
                activeBlocks={activeBlocks}
              />
            )
          }
        )}
      </ul>

      <button type="button" onClick={handleLogout} className={classes.logout}>
        Logout
      </button>
    </aside>
  )
})

export default Sidebar
