import error from "../../assets/images/error.svg"
import classes from "./index.module.scss"

const Radio = ({values = [], value, label, name, errors, handleChange}) => {
  return (
    <div className={classes.container}>
      <span className={classes.mainLabel}>{label}</span>

      <div className={classes.block}>
        {values.map((v) => {
          return (
            <label className={classes.label} key={v.value}>
              <input
                type="radio"
                name={name}
                value={v.value}
                checked={v.value.toString() === value?.toString()}
                onChange={handleChange}
              />
              <span>{v.label}</span>
            </label>
          )
        })}
      </div>
      {errors?.[name] && (
        <div className={classes.error}>
          <img src={error} alt=" " />
          {errors[name]}
        </div>
      )}
    </div>
  )
}

export default Radio
