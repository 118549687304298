import {useFormik} from "formik"
import React, {useEffect, useState} from "react"
import {Link, useParams} from "react-router-dom"
import {toast} from "react-toastify"

import InputField from "../../components/InputField"
import CustomSelect from "../../components/Select"
import AppLayout from "../../layouts/App/AppLayout"
import {birthdaySchema} from "../../libs/schemas"
import {useStores} from "../../store"
import Radio from "../../components/Radio"
import classes from "./index.module.scss"

const BirthdayGift = () => {
  const [item, setItem] = useState(null)
  const [needToValidate, setNeedToValidate] = useState(false)

  const {
    categoriesStore: {getCategories, categoriesUpdateList},
    couponStore: {getCouponTemplate, updateCouponTemplate, getCoupon, addCoupon}
  } = useStores()

  const {id} = useParams()

  useEffect(() => {
    const getData = async () => {
      const itemData = await getCoupon("birthday")

      if (itemData) {
        setItem(itemData)
      }
    }
    const getItemData = async () => {
      const itemData = await getCouponTemplate(id)

      if (itemData) {
        setItem(itemData)
      }
    }

    id ? getItemData() : getData()
  }, [getCoupon, getCouponTemplate, id])

  useEffect(() => {
    getCategories()
  }, [getCategories])

  const {
    dirty,
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue
  } = useFormik({
    initialValues: {
      active: item?.active || false,
      description: item?.description || "",
      lifespan: item?.lifespan || 0,
      discountType: item?.discountType || "",
      value: item?.value || "",
      category: item?.category
        ? {
            ...item.category,
            value: item.category.id,
            label: item.category.description
          }
        : null
    },
    enableReinitialize: true,
    validationSchema: birthdaySchema,
    validateOnChange: needToValidate,
    validateOnBlur: needToValidate,
    onSubmit: async (values) => {
      setNeedToValidate(true)

      try {
        let data = {
          active:
            values.active === "true" || values.active === true ? true : false,
          description: values.description,
          lifespan: values.lifespan,
          discountType: values.discountType,
          categoryId: values.category?.value,
          fullDescription: getPreview()
        }

        if (values.value) {
          data.value = values.value
        }

        if (id) {
          const res = await updateCouponTemplate({id, data})
          if (res) {
            toast.success("Birthday template successfully changed!")
          }
        } else {
          const res = await addCoupon({subtype: "birthday", data})
          if (res) {
            toast.success("Birthday template successfully added!")
          }
        }
      } catch (err) {
        console.log(err.response)
      }
    }
  })

  const getPreview = () => {
    if (!values || !values.discountType) {
      return ""
    }
    if (values.discountType === "amount") {
      return values.category?.value
        ? `${values.description} (${values.value}$ - ${values.category.description})`
        : `${values.description} (${values.value}$ on all order)`
    }
    if (values.discountType === "percentage") {
      return values.category?.value
        ? `${values.description} (${values.value}% - ${values.category.description})`
        : `${values.description} (${values.value}% on all order)`
    }
    if (!values.category?.value) {
      return ""
    }
    return `${values.description} (FREE - ${values.category.description})`
  }

  return (
    <AppLayout
      title="Generator"
      Tag="form"
      submitForm={handleSubmit}
      headComponent={
        <div className={classes.actions}>
          <button
            disabled={!isValid || !dirty}
            className={classes.submit}
            type="submit"
            onClick={() => setNeedToValidate(true)}
          >
            Submit
          </button>
        </div>
      }
    >
      <div className={classes.container}>
        <div className={classes.links}>
          <Link to={"/coupons/generator/special/add"}>Special</Link>
          <Link to={"/coupons/generator/personal/add"}>Personal</Link>
          <Link to={"/coupons/generator/regular/add"}>Regular</Link>
          <button>Birthday</button>
          <Link to={"/coupons/generator/registration/add"}>Register</Link>
        </div>

        <div className={classes.content}>
          <div className={classes.leftPart}>
            <Radio
              label="Active"
              values={[
                {value: true, label: "On"},
                {value: false, label: "Off"}
              ]}
              value={values?.active}
              name="active"
              handleChange={handleChange}
            />

            <InputField
              label="Description"
              value={values?.description}
              name="description"
              onChange={handleChange}
              errors={errors}
              placeholder="Set promocode description"
            />

            <InputField
              label="Lifespan (days)"
              value={values?.lifespan}
              name="lifespan"
              type="number"
              onChange={handleChange}
              errors={errors}
              placeholder="Set lifespan"
            />
          </div>

          <div className={classes.rightPart}>
            <div className={classes.rightTopPart}>
              <Radio
                label="Discount type"
                values={[
                  {value: "amount", label: "Amount"},
                  {value: "percentage", label: "Percentage"},
                  {value: "item", label: "Item"}
                ]}
                errors={errors}
                value={values?.discountType}
                name="discountType"
                handleChange={handleChange}
              />

              <InputField
                label="Discount amount"
                value={values?.value}
                name="value"
                onChange={handleChange}
                errors={errors}
                disabled={
                  !["amount", "percentage"].includes(values?.discountType)
                }
                withIcon
                placeholder="Set discount amount"
                type={
                  values?.discountType === "amount"
                    ? "discountAbs"
                    : values?.discountType === "percentage"
                    ? "discountPer"
                    : null
                }
              />

              <CustomSelect
                label="Category"
                name="category"
                errors={errors}
                options={[
                  {value: undefined, label: "ALL"},
                  ...categoriesUpdateList.map((c) => ({
                    ...c,
                    label: c.description,
                    value: c.id
                  }))
                ]}
                value={values?.category}
                placeholder="Category selector"
                setParam="id"
                setFieldValue={setFieldValue}
              />
            </div>

            <InputField
              label="Full description preview"
              value={getPreview()}
              name="preview"
              type="textarea"
              onChange={() => null}
              errors={errors}
            />
          </div>
        </div>
      </div>
    </AppLayout>
  )
}

export default BirthdayGift
