import classes from "./index.module.scss"

const SaveButton = ({
  onClick,
  type = "button",
  text = "Save changes",
  withBorder,
  disabled
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`${classes.container} ${
        withBorder ? classes.withBorder : classes.withoutBorder
      }`}
      disabled={disabled}
    >
      {text}
    </button>
  )
}

export default SaveButton
