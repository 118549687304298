import React, {useEffect, useState} from "react"
import AppLayout from "../../layouts/App/AppLayout"
import "./index.scss"
import {observer} from "mobx-react-lite"
import {useStores} from "../../store"
import CustomDatePicker from "../../components/CustomDatePicker"
import Table from "../../components/Table"
import UsersListWithSearch from "../../components/UsersListWithSearch"
import moment from "moment"

const TimeSheetManagement = observer(() => {
  const {
    usersStore: {getUsers, usersUpdateList, isLoading}
  } = useStores()
  const [selected, setSelected] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  useEffect(() => {
    getUsers()
  }, [])
  const columns = React.useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Date",
            accessor: "date",
            Cell: ({cell: {value}}) => (value ? value : "-")
          },
          {
            Header: "Clock-In",
            accessor: "clockIn",
            Cell: ({cell: {value}}) => (value ? value : "-")
          },
          {
            Header: "Clock-Out",
            accessor: "clockOut",
            Cell: ({cell: {value}}) => (value ? value : "-")
          },
          {
            Header: "Total Duration",
            accessor: "totalDuration",
            Cell: ({cell: {value}}) => (value ? value : "-")
          }
        ]
      }
    ],
    []
  )

  const data = usersUpdateList.slice(0, 20).map(({}) => {
    const d = moment().format("LTS")
    const c = moment().format("LT")
    let currDate = moment().format("l")

    const dataNew = {
      date: currDate,
      clockIn: d,
      clockOut: d,
      totalDuration: c
    }
    return dataNew
  })
  return (
    <AppLayout title="Timesheet">
      <div className="timesheet_wrapper">
        <div className="timesheet_inner_row">
          <UsersListWithSearch usersList={usersUpdateList.slice(0, 10)} />
          <div className="row_employees_details">
            <div className="list_details">
              <div className="detail_item">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQsUmE6FMTWUnXwdX_vkq9XMe_6AIFuuBY_lQ&usqp=CAU"
                  alt=""
                />
                <div className="item_content user_details">
                  <h3>Johnny Cook</h3>
                  <p>(072) 452-3365 | johny@email.com | 27/06/1997</p>
                </div>
              </div>
              <div className="detail_item">
                <div className="item_content">
                  <h3>27</h3>
                  <p>Total Hours Worked</p>
                </div>
              </div>
              <div className="detail_item">
                <div className="item_content">
                  <h3>Value</h3>
                  <p>Label</p>
                </div>
              </div>
            </div>
            <div className="row_action">
              <div className="date_pick_action">
                <CustomDatePicker
                  range={true}
                  setEndDate={setEndDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
                />
              </div>
            </div>
            <div className="table_sheets">
              <Table
                columns={columns}
                // trFunction={handleDetailsUser}
                data={data}
                onPageCount={8}
                isCheckedRow={false}
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  )
})

export default TimeSheetManagement
