import {fetchAxios} from "../axios"

//modifiers

export const getModifiersList = async ({search, item, set}) => {
  try {
    let url = "/modifiers"

    if (search || item || set) {
      url += "?"
    }

    if (search) {
      url += `search=${search}`

      if (item) {
        url += `&item=${item}`
      }

      if (set) {
        url += `&set=${set}`
      }
    } else if (item) {
      url += `item=${item}`

      if (set) {
        url += `&set=${set}`
      }
    } else if (set) {
      url += `set=${set}`
    }

    const res = await fetchAxios({
      url
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const addToModifiersList = async ({data}) => {
  try {
    const res = await fetchAxios({
      url: "/modifiers",
      method: "POST",
      data,
      headers: {
        "Content-Type": "multipart/form-data; "
      }
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateModifier = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/modifiers/${id}`,
      method: "PUT",
      data,
      headers: {
        "Content-Type": "multipart/form-data; "
      }
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getModifier = async (id) => {
  try {
    const res = await fetchAxios({
      url: `/modifiers/${id}`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getItemsWithModSet = async ({id}) => {
  try {
    const res = await fetchAxios({
      url: `/modifier-sets/${id}/items`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const addIngredients = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/modifiers/${id}/ingredients`,
      method: "POST",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

//modifier sets

export const getModifierSetsList = async () => {
  try {
    const res = await fetchAxios({
      url: "/modifier-sets"
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getModifierSet = async (id) => {
  try {
    const res = await fetchAxios({
      url: `/modifier-sets/${id}`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const addModifierSet = async ({data}) => {
  try {
    const res = await fetchAxios({
      url: "/modifier-sets",
      method: "POST",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateModifierSet = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/modifier-sets/${id}`,
      method: "PUT",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}
