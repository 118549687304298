import AuthLayout from "../../layouts/Auth/AuthLayout"
import "./index.scss"
import Arrow from "../../assets/images/auth/arrow-right-thin.svg"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import {useEffect, useState} from "react"
import {observer} from "mobx-react-lite"
import {useNavigate} from "react-router-dom"

const Login = observer(() => {
  const [phone, setPhone] = useState("")
  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const handleChangePhone = (value) => {
    setPhone(value)
  }

  useEffect(() => {
    navigate("/menu/items")
  }, [navigate])

  return (
    <AuthLayout
      title="Welcome Back"
      subTitle="Enter your phone number to continue"
    >
      <form onSubmit={handleSubmit} className="submit_form-login">
        <div className="login_form_field">
          <PhoneInput
            onlyCountries={["us"]}
            areaCodes={{us: ["300"]}}
            enableAreaCodes={true}
            country={"us"}
            inputExtraProps={{
              name: "phone",
              required: true,
              autoFocus: true
            }}
            value={phone}
            onChange={handleChangePhone}
          />
        </div>
        <button
          onClick={() => navigate("/dashboard")}
          type="submit"
          className="admin_btn login_btn"
        >
          SEND CODE
          <img src={Arrow} alt="" />
        </button>
      </form>
    </AuthLayout>
  )
})

export default Login
