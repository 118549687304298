import React from "react"

import pencil from "../../../assets/images/actions/pencil.svg"
import trash from "../../../assets/images/actions/trash.svg"
import {SaveButton} from "../../../components/Buttons"
import emptyImage from "../../../assets/images/emptyImage.png"
import classes from "../index.module.scss"

const AddVariations = ({
  values,
  handleVariationRemove,
  handleVariationEdit,
  setIsVariationModalOpen
}) => (
  <div className={classes.tableOverlay}>
    <span className={classes.tableName}>Variations (at least 1required)</span>

    <hr className={classes.hr} />

    <div className={classes.variationsTable}>
      <div className={classes.variationsTableHeadLine}>
        <span
          className={`${classes.tableHeadText} ${classes.variationsTableTitle}`}
        >
          Title
        </span>
        <span className={classes.tableHeadText}>Size</span>
        <span className={classes.tableHeadText}>Price</span>
        <span className={classes.tableHeadText}>Visibility</span>
        <span></span>
      </div>

      {values.variations?.map((v) => (
        <div className={classes.variationsTableLine} key={v.id}>
          <div className={classes.variationTitleImage}>
            {v.image ? (
              <img src={v.image} alt={v.name} />
            ) : (
              <img src={emptyImage} alt="Default Image" />
            )}
            <span className={classes.tableText}>{v.name}</span>
          </div>
          <span className={classes.tableText}>{v.size}</span>
          <span className={classes.tableText}>
            ${v.price ? (v.price / 100).toFixed(2) : ""}
          </span>
          <span className={classes.tableText}>{v.visibility}</span>
          <div className={classes.actionsRow}>
            <div
              onClick={() => handleVariationEdit(v)}
              className={`${classes.action} ${classes.actionChange}`}
            >
              <img src={pencil} alt="edit" />
            </div>
            <div
              onClick={() => handleVariationRemove(v.id)}
              className={`${classes.action} ${classes.actionDelete}`}
            >
              <img src={trash} alt="remove" />
            </div>
          </div>
        </div>
      ))}
    </div>

    <SaveButton
      text="Add new"
      type="button"
      onClick={() => setIsVariationModalOpen(true)}
    />
  </div>
)

export default AddVariations
