import {fetchAxios} from "../axios"
import {toJS} from "mobx"

export const getIngredientsList = async ({search, item, modifier}) => {
  try {
    let url = "/ingredients?page=0&limit=0"

    if (search) {
      url += `&search=${search}`
    }
    if (item) {
      url += `&item=${item}`
    }

    if (modifier) {
      url += `&modifier=${modifier}`
    }

    const res = await fetchAxios({
      url
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getIngredientGroups = async () => {
  try {
    const res = await fetchAxios({
      url: "/ingredient-groups"
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getIngredientFilters = async (type) => {
  let url = '/filter-options'
  if(type){
    url = `/filter-options?type=${type}`
  }
  try {
    const res = await fetchAxios({
      url: url,
    })
    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getIngredientByFilters = async (data) => {
  const { page, limit } = data
  try {
    const res = await  fetchAxios({
      url: `/ingredients-search?page=${page}&limit=${limit}`,
      method: "POST",
      data
    })
    return res?.data
  } catch (e) {
    console.log(e)
  }
}

export const addToIngredientsList = async ({data}) => {
  try {
    const res = await fetchAxios({
      url: "/ingredients",
      method: "POST",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const updateIngredient = async ({id, data}) => {
  try {
    const res = await fetchAxios({
      url: `/ingredients/${id}`,
      method: "PUT",
      data
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const getIngredient = async (id) => {
  try {
    const res = await fetchAxios({
      url: `/ingredients/${id}`
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}

export const deleteIngredient = async ({id}) => {
  try {
    const res = await fetchAxios({
      url: `/ingredients/${id}`,
      method: "DELETE"
    })

    return res.data
  } catch (e) {
    console.log(e)
  }
}
