import CustomSelect from "../../../components/Select"
import classes from "../index.module.scss"

const AddAddons = ({values, addonsList, setFieldValue}) => {
  return (
    <div className={classes.addonsContainer}>
      <span className={classes.addonsTitle}>Addons</span>

      <CustomSelect
        isMulti
        label="Ingredients"
        name="ingredients"
        placeholder="Choose addons"
        values={values?.ingredients}
        setFieldValue={setFieldValue}
        options={[
          ...addonsList.map((c) => ({
            ...c,
            label: c.name,
            value: c.id
          }))
        ]}
      />
    </div>
  )
}

export default AddAddons
