import {NavLink} from "react-router-dom"
import React, {createRef, useEffect, useState} from "react"

const Link = ({
  link,
  role,
  name,
  Icon,
  SubIcon,
  subLinks,
  activeBlocks,
  setActiveBlocks
}) => {
  const arrLength = subLinks?.length

  const [elRefs, setElRefs] = useState([])

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    )
  }, [arrLength])

  const handleShowSub = (e) => {
    e.preventDefault()
    setActiveBlocks(name)
  }

  return (
    <li className={activeBlocks.includes(name) ? "activeBlock" : ""}>
      <NavLink
        to={link}
        onClick={(e) => (subLinks ? handleShowSub(e) : null)}
        className={({isActive}) => (isActive ? "active" : "")}
      >
        <div>
          <Icon />
          {name}
        </div>
        {subLinks && <SubIcon />}
      </NavLink>
      {subLinks && (
        <div className={`sub_links`}>
          {subLinks.map(({name, link, accessRoles}, i) => {
            if (accessRoles && !accessRoles.includes(role)) {
              return <React.Fragment key={name}></React.Fragment>
            }
            return (
              <NavLink
                ref={elRefs[i]}
                key={name}
                to={link}
                className={({isActive}) =>
                  [isActive ? "active" : "", "sub_link"].join(" ")
                }
              >
                {name}
              </NavLink>
            )
          })}
        </div>
      )}
    </li>
  )
}

export default Link
