import React, {useMemo, useState} from "react"
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core"
import {restrictToVerticalAxis} from "@dnd-kit/modifiers"
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable"
import {useTable} from "react-table"

import {DraggableTableRow} from "./DraggableTableRow"
import {StaticTableRow} from "./StaticTableRow"
import classes from "./index.module.scss"
import PaginationTable from "components/Table/PaginationTable"

export function DraggableTable({
  columns,
  data,
  setData,
  accessDrag,
  withFiltersList,
  className,
                                 pageSize,
                                 currentPage,
                                 totalCount,
                                 gotoPage,
                                 previousPage,
                                 nextPage,
                                 totalPageCount,
                                 showPagination = false,
                                 limit,
                                 changeLimit
}) {
  const [activeId, setActiveId] = useState()
  const items = useMemo(() => data?.map(({id}) => id), [data])
  // Use the state and functions returned from useTable to build your UI
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable({
      columns,
      data
    })
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  function handleDragStart(event) {
    setActiveId(event.active.id)
  }

  function handleDragEnd(event) {
    const {active, over} = event
    if (active.id !== over.id) {
      setData((data) => {
        const oldIndex = items.indexOf(active.id)
        const newIndex = items.indexOf(over.id)
        return arrayMove(data, oldIndex, newIndex)
      })
    }

    setActiveId(null)
  }

  function handleDragCancel() {
    setActiveId(null)
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null
    }
    const row = rows.find(({original}) => original.id === activeId)
    prepareRow(row)
    return row
  }, [activeId, rows, prepareRow])

  // Render the UI for your table
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <div className={`${classes.draggableTableWrapper} ${
        withFiltersList ? classes.withFiltersList : classes.withoutFiltersList
      }`}>
        <div
          className={`${classes.draggableTableOverlay} ${
            className ? className : ""
          }`}
        >
          <table {...getTableProps()} className={classes.draggableTable}>
            <thead className={classes.thead}>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={classes.headTr}
              >
                {headerGroup.headers.map((column) => {
                  const widthClass = `th${[column.width]}`
                  const flexClass = `flex-${[column.flex]}`

                  return (
                    <th
                      {...column.getHeaderProps()}
                      className={`${classes.th} ${classes[widthClass]} ${classes[flexClass]}`}
                    >
                      {column.render("Header")}
                    </th>
                  )
                })}
              </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {rows.map((row, i) => {
                prepareRow(row)
                return (
                  <DraggableTableRow
                    accessDrag={accessDrag}
                    key={row.original.id}
                    row={row}
                  />
                )
              })}
            </SortableContext>
            </tbody>
          </table>

        </div>
        {showPagination ?
          <PaginationTable
            className="pagination-bar"
            currentPage={currentPage}
            pageSize={pageSize}
            totalCount={totalCount}
            gotoPage={gotoPage}
            previousPage={previousPage}
            nextPage={nextPage}
            totalPageCount={totalPageCount}
            limit={limit}
            changeLimit={changeLimit}
          /> : null
        }
      </div>

      <DragOverlay>
        {activeId && (
          <table style={{width: "100%"}}>
            <tbody>
              <StaticTableRow row={selectedRow} />
            </tbody>
          </table>
        )}
      </DragOverlay>
    </DndContext>
  )
}
