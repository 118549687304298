import {makeAutoObservable} from "mobx"
import {
  addToMenuList,
  getItem,
  getMenuList,
  updateItem,
  updateMenuList,
  addVariation,
  updateVariation,
  deleteVariation,
  addModifierSet,
  updateModifierSet,
  deleteModifierSet,
  updateItemVisibility,
  addIngredients
} from "./api"

export class MenuStore {
  data = []
  isLoading = false

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true})
  }

  async getItem(id) {
    this.isLoading = true
    const item = await getItem(id)
    this.isLoading = false
    return item
  }

  async updateItem({id, data}) {
    this.isLoading = true
    const item = await updateItem({id, data})
    if (item) {
      this.data = await getMenuList({})
    }
    this.isLoading = false
    return item
  }

  async addItem({data}) {
    this.isLoading = true
    const item = await addToMenuList({data})
    if (item) {
      this.data = await getMenuList({})
    }
    this.isLoading = false
    return item
  }

  async getMenu({category = null, search = ""}) {
    this.isLoading = true
    this.data = await getMenuList({category, search})
    this.isLoading = false
  }

  async updateMenu({categoryId, ids}) {
    this.isLoading = true
    const res = await updateMenuList({categoryId, ids})
    this.isLoading = false
    this.data = res
    return res
  }

  async updateItemVisibility({id, data}) {
    this.isLoading = true
    const item = await updateItemVisibility({id, data})
    if (item) {
      this.data = await getMenuList({})
    }
    this.isLoading = false
    return item
  }

  get menuUpdateList() {
    return this.data
  }

  //variations
  async addVariation({id, data}) {
    this.isLoading = true
    const res = await addVariation({id, data})
    this.isLoading = false
    return res
  }

  async updateVariation({id, data, varId}) {
    this.isLoading = true
    const res = await updateVariation({id, data, varId})
    this.isLoading = false
    return res
  }

  async deleteVariation({id, varId}) {
    this.isLoading = true
    const res = await deleteVariation({id, varId})
    this.isLoading = false
    return res
  }

  //modifiers
  async addModifierSet({id, data}) {
    this.isLoading = true
    const res = await addModifierSet({id, data})
    this.isLoading = false
    return res
  }

  async updateModifierSet({id, data, setId}) {
    this.isLoading = true
    const res = await updateModifierSet({id, data, setId})
    this.isLoading = false
    return res
  }

  async deleteModifierSet({id, setId}) {
    this.isLoading = true
    const res = await deleteModifierSet({id, setId})
    this.isLoading = false
    return res
  }

  //ingredients
  async addIngredients({id, data}) {
    this.isLoading = true
    const res = await addIngredients({id, data})
    this.isLoading = false
    return res
  }
}

export default MenuStore
