import {Bar} from 'react-chartjs-2';
import {Chart, registerables} from 'chart.js'

Chart.register(
    ...registerables // 1. Register Filler plugin
);


const ChartCustomer = () => {

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            animateScale: true
        },
        scales: {
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value, index, ticks) {
                        return '$' + value;
                    }
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        return  datasets.map(({data, label, backgroundColor}, i) => ({
                            text: `${label} $${data.reduce((a,b) => a + b)}`,
                            fillStyle: backgroundColor,
                        }))
                    },
                    color: "#9B9B9B",  // not 'fontColor:' anymore
                    // fontSize: 18  // not 'fontSize:' anymore
                    font: {
                        size: 16 // 'size' now within object 'font {}'
                    },
                    sePointStyle: true,
                    boxWidth: 18,
                    boxHeight: 18,
                    borderRadius: 4
                },

            },
        },
    };
    const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    const data = {
        labels,
        datasets: [
            {
                label: 'In Restaurant',
                data: [334, 2000, 1000, 4111, 44, 4000, 3000],
                backgroundColor: '#FFA600',
            },
            {
                label: 'Online Order',
                data: [334, 2000, 1000, 4111, 44, 4000, 3000],
                backgroundColor: '#E5001C',
            },
        ],
    };

    return (
        <div className="chart_item_wrapper">
            <Bar options={options} data={data}/>
        </div>
    )
}

export default ChartCustomer