import {forwardRef, useRef, useState} from "react"
import DatePicker from "react-datepicker";
import './index.scss'; /// module.scss not work for date picker
// import Clock from "assets/images/clock.svg"
import { ReactComponent as Chevron } from "assets/images/chevron-down.svg";
import { ReactComponent as Clock } from "assets/images/clock.svg";


const CustomTimePicker = ({label ,labelEnd, labelStart, range = false, setEndDate, setStartDate, startDate, endDate, className = '', showTimeSelect, showTimeSelectOnly, placeholder}) => {
    const inputRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false)
    const ExampleCustomInput = forwardRef((props, ref) => {
        return (
          <div onClick={props.onClick} className={`time_picker ${className} ${isOpen ? 'opened' : ''}`}>
              <Clock />
              <input type="text" {...props} ref={ref} readOnly/>
              <Chevron className='schevron_svg' />
          </div>
        )
    });
    const handleCalendarOpen = () => setIsOpen(true);

    const handleCalendarClose = () => setIsOpen(false);
    return<>

        {range ?
            <>
                <div className='time_wrapper'>
                    <h6 className='time_label'>{labelStart}</h6>
                    <DatePicker
                        selected={startDate}
                        showTimeSelect
                        showTimeSelectOnly
                        selectsStart
                        onChange={(date) => setStartDate(date)}
                        timeIntervals={5}
                        timeCaption="Start Time"
                        dateFormat="h:mm aa"
                        customInput={<ExampleCustomInput  ref={inputRef}/>}
                    />
                </div>
               <div className='time_wrapper'>
                   <h6 className='time_label'>{labelEnd}</h6>
                   <DatePicker
                       selected={endDate}
                       onChange={(date) => setEndDate(date)}
                       selectsEnd
                       showTimeSelect
                       showTimeSelectOnly
                       timeIntervals={5}
                       timeCaption="End Time"
                       dateFormat="h:mm aa"
                       customInput={<ExampleCustomInput  ref={inputRef}/>}
                   />
               </div>
            </>
            :
            <div className='time_wrapper'>
                <h6 className='time_label'>{label}</h6>
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    showTimeSelect={showTimeSelect}
                    showTimeSelectOnly={showTimeSelectOnly}
                    timeIntervals={10}
                    timeCaption=''
                    dateFormat="h:mm aa"
                    customInput={<ExampleCustomInput  ref={inputRef}/>}
                    popperClassName='picker_popper'
                    onCalendarClose={handleCalendarClose}
                    onCalendarOpen={handleCalendarOpen}
                    placeholderText={placeholder}
                />
            </div>
        }

    </>
}

export default CustomTimePicker