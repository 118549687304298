import {BrowserRouter as Router} from "react-router-dom"
import {useEffect, useState} from "react"
import {observer} from "mobx-react"
import {ToastContainer} from "react-toastify"

import {useStores} from "store"
import RouterApp from "./router"
import Index from "./components/Spinner"
import "react-toastify/dist/ReactToastify.css"

const App = observer(() => {
  const [isLoading, setIsLoading] = useState(true)

  const {
    authStore: {isLoading: isLoadingAuth, setAuth, accessToken},
    categoriesStore: {isLoading: isLoadingCategories},
    menuStore: {isLoading: isLoadingMenu},
    modifiersStore: {isLoading: isLoadingModifiers},
    usersStore: {isLoading: isLoadingUsers},
    invitationsStore: {isLoading: isLoadingInvitations},
    couponStore: {isLoading: isLoadingCoupons},
    notificationStore: {isLoading: isLoadingNotification},
    ingredientsStore: {isLoading: isLoadingIngredients},
    kitchensStore: {isLoading: isLoadingKitchens}
  } = useStores()

  useEffect(() => {
    if (
      isLoadingMenu ||
      isLoadingCategories ||
      isLoadingAuth ||
      isLoadingModifiers ||
      isLoadingUsers ||
      isLoadingInvitations ||
      isLoadingCoupons ||
      isLoadingNotification ||
      isLoadingKitchens ||
      isLoadingIngredients
    ) {
      setIsLoading(true)
    } else setIsLoading(false)
  }, [
    isLoadingAuth,
    isLoadingUsers,
    isLoadingCategories,
    isLoadingMenu,
    isLoadingModifiers,
    isLoadingInvitations,
    isLoadingCoupons,
    isLoadingNotification,
    isLoadingIngredients,
    isLoadingKitchens
  ])

  const token = localStorage.getItem("token")

  useEffect(() => {
    if (token && !accessToken) {
      setAuth({token})
    }
  }, [accessToken, setAuth, token])

  return (
    <Router>
      <RouterApp />
      {isLoading && <Index />}
      <ToastContainer />
    </Router>
  )
})

export default App
